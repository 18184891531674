import { LoginResponse } from '../api'
import { setClientID } from '../storage/clientIdStorage'
import { setEmail } from '../storage/emailStorage'

export type IdentityProvider = 'google' | 'ms' | 'otto'

export const getToken = () => window.localStorage.getItem('access_token') || ''

export const setToken = (token: string) =>
  window.localStorage.setItem('access_token', token)

export const getRefreshToken = () =>
  window.localStorage.getItem('refresh_token') || ''

export const setRefreshToken = (token: string) =>
  window.localStorage.setItem('refresh_token', token)

export const getSource = () =>
  window.localStorage.getItem('source') as IdentityProvider

export const setSource = (idp: IdentityProvider) =>
  window.localStorage.setItem('source', idp)

export const getUserId = () => {
  const id = window.localStorage.getItem('userId')
  return !id ? 0 : parseInt(id)
}

export const setUserid = (id: number) =>
  window.localStorage.setItem('userId', id.toString())

export const setAuthStorage = (
  data: LoginResponse,
  email: string,
  source: IdentityProvider
) => {
  setEmail(email)
  setSource(source)
  setClientID(data.company.id)
  setToken(data.tokens.accessToken)
  setRefreshToken(data.tokens.refreshToken)
}
