import { useContext, useRef } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import GradeIcon from '@mui/icons-material/Grade'
import HideImageIcon from '@mui/icons-material/HideImage'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import { DataSet } from 'vis-data'
import { useKpiHighlightsQueries } from '../../../api'
import { enUS } from '../../../constants'
import { useDriveTrialContext, useTimelineContext } from '../../../details'
import { MediaSyncContext } from '../../../details/types'
import { commonStyle } from '../../Menus/types'
import {
  TimelineGroupMenu,
  TimelineMenuController,
} from '../TimelineGroupMenu/TimelineGroupMenu'
import { applyNonLinearTransformation } from '../TimelineHighlightMode/timelineHighlight'
import { hideShow } from '../TimelineTags/handlers'
import { NewGroupData, TimelineItemData } from '../types'
import { onGroupAdd, onGroupSelect, removeSelected } from '../utils'

interface TimelineTrackMenuProps {
  anchorEl: HTMLElement | null
  handleClose: () => void
  groupId: number
}

export function TimelineTrackMenu({
  anchorEl,
  handleClose,
  groupId,
}: TimelineTrackMenuProps) {
  const timelineContext = useTimelineContext()
  const {
    selectedGroup,
    setSelectedGroup,
    items,
    hidden,
    setHidden,
    timelineSettings,
  } = timelineContext
  const {
    highlightLength: timelineSettingsHighlightLength,
    sortBy: timelineSettingsSortBy,
  } = timelineSettings
  const { highlightMode, setHighlightMode } = useDriveTrialContext()
  const kpis = useKpiHighlightsQueries()
  const isMenuAvailable = kpis.every((x) => x.isFetched)
  const open = Boolean(anchorEl)
  const timelineModalRef = useRef<TimelineMenuController>()
  const mediaSyncCtx = useContext(MediaSyncContext)

  const handleSelect = () => {
    onGroupSelect(groupId, selectedGroup, setSelectedGroup)
    handleClose()
  }

  const handleShowHide = () => {
    hideShow(timelineContext, groupId)
    const element = document.getElementById(`group-tags-visibility-${groupId}`)
    handleClose()
    if (hidden.includes(groupId)) {
      setHidden(hidden.filter((item) => item !== groupId))
      if (element) {
        element.style.display = 'none'
      }
    } else {
      setHidden([...hidden, groupId])
      if (element) {
        element.style.display = 'flex'
      }
    }
  }

  // const handleGroupAdd = () => {
  //   if (timelineModalRef.current) {
  //     timelineModalRef.current.open(groupId)
  //     handleClose()
  //   }
  // }

  // const handleRemoveGroup = () => {
  //   onGroupRemove(
  //     groupId,
  //     timelineContext,
  //     timelineContext.selectedGroup,
  //     timelineContext.setSelectedGroup
  //   )
  //   handleClose()
  // }

  const changeTimelineMode = (
    items: DataSet<TimelineItemData, 'id'>,
    id: number
  ) =>
    applyNonLinearTransformation(
      items,
      id,
      timelineSettingsHighlightLength,
      timelineSettingsSortBy
    )

  const applyHighlightMode = () => {
    setHighlightMode({
      id: groupId,
      items: changeTimelineMode(items, groupId),
      sortBy: timelineSettingsSortBy,
    })
    removeSelected()
    handleClose()
  }

  const exitHighlightMode = () => {
    setHighlightMode({
      id: -1,
      items: new DataSet([]),
      sortBy: undefined,
    })
    mediaSyncCtx.isPlaying = false
    handleClose()
  }

  const hideGroup = () => {
    timelineContext.groups.update({ id: groupId, visible: false })
    handleClose()
  }

  return (
    <>
      <Menu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-testid='selectDeselectTrack' onClick={handleSelect}>
          <ListItemIcon sx={commonStyle}>
            <ViewTimelineIcon fontSize='small' />
            <div>
              {enUS.GROUP_SELECT}/{enUS.GROUP_UNSELECT} track
            </div>
          </ListItemIcon>
        </MenuItem>
        <MenuItem data-testid='showHideTags' onClick={handleShowHide}>
          <ListItemIcon sx={commonStyle}>
            <VisibilityIcon fontSize='small' />
            <div>{enUS.GROUP_SHOW_HIDE}</div>
          </ListItemIcon>
        </MenuItem>
        {/* <MenuItem onClick={handleGroupAdd} disabled>
          <ListItemIcon sx={commonStyle}>
            <AddIcon fontSize='small' />
            <div>{enUS.GROUP_ADD}</div>
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleRemoveGroup} disabled>
          <ListItemIcon sx={commonStyle}>
            <RemoveCircleIcon fontSize='small' />
            <div>{enUS.GROUP_REMOVE}</div>
          </ListItemIcon>
        </MenuItem> */}
        {highlightMode.id !== groupId ? (
          <MenuItem
            data-testid='enterHighlight'
            onClick={applyHighlightMode}
            disabled={
              !items
                .get()
                .filter((item) => item.group === groupId)
                .some((item) => item.isItem)
            }
          >
            <ListItemIcon sx={commonStyle}>
              <GradeIcon fontSize='small' />
              <div>{enUS.HIGHLIGHT_MODE}</div>
            </ListItemIcon>
          </MenuItem>
        ) : (
          <MenuItem
            data-testid='exitHighlight'
            onClick={exitHighlightMode}
            disabled={!isMenuAvailable}
          >
            <ListItemIcon sx={commonStyle}>
              <ClearIcon fontSize='small' />
              <div>{enUS.HIGHLIGHT_MODE_EXIT}</div>
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem
          data-testid='hideGroup'
          onClick={hideGroup}
          disabled={!isMenuAvailable}
        >
          <ListItemIcon sx={commonStyle}>
            <HideImageIcon fontSize='small' />
            <div>{enUS.HIDE_GROUP}</div>
          </ListItemIcon>
        </MenuItem>
      </Menu>

      <TimelineGroupMenu
        ref={timelineModalRef}
        relativeGroupId={groupId}
        onOK={(data: NewGroupData) =>
          onGroupAdd(data, timelineContext, groupId)
        }
      />
    </>
  )
}
