import { useCallback, useContext } from 'react'
import { useSnackbar } from 'notistack'
import { IdType } from 'otto-vis-timeline/types'
import { useLocation, useParams } from 'react-router-dom'
import { addNotationToGroup, addTagToGroup } from './handlers'
import {
  useCreateTagMutation,
  useEditTagMutation,
  useRemoveTagMutation,
} from '../../../api'
import { enUS } from '../../../constants'
import {
  HighlightMode,
  useDriveTrialContext,
  useTimelineContext,
} from '../../../details'
import { MediaSyncContext } from '../../../details/types'
import { toMilliseconds } from '../../../utils'
import { calculateOriginalTime } from '../utils'
import './style.scss'

export const useTags = (selectedGroup: IdType) => {
  const { pathname } = useLocation()
  const { reportType } = useParams()
  const isKPI = pathname.includes('kpi')
  const timelineCtx = useTimelineContext()

  const { time } = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, highlightMode, setHighlightMode } =
    useDriveTrialContext()
  const { removeTagMutation } = useRemoveTagMutation()
  const { editTagMutation } = useEditTagMutation()
  const { createTagMutation } = useCreateTagMutation()
  const { enqueueSnackbar } = useSnackbar()

  const removeTag = async (
    activeTag: number | null,
    highlightMode: HighlightMode
  ) => {
    if (!activeTag) {
      return
    }

    if (!time) {
      return
    }

    try {
      await removeTagMutation(activeTag)

      const allItems = timelineCtx.items.get({ returnType: 'Array' })

      const currentItem = allItems.filter(
        (item) => item.id === `tag-${activeTag}`
      )

      const currentItemGroup = currentItem[0].group
      const currentItemStart = currentItem[0].start

      timelineCtx.items.remove(`tag-${activeTag}`)
      if (highlightMode.id !== -1) {
        highlightMode.items.remove(`tag-${activeTag}`)
      }
      const tagsCopy = { ...timelineCtx.tags }

      if (Object.keys(tagsCopy).length > 0) {
        delete tagsCopy[currentItemGroup!][+currentItemStart]
      }

      timelineCtx.setTags(tagsCopy)
    } catch (e) {
      console.error(e)
    }
  }

  const editTag = async (
    activeTag: number | null,
    text: string,
    jiraID?: string | undefined
  ) => {
    if (!activeTag) {
      return
    }

    await editTagMutation({ tagId: activeTag, note: text, jiraID: jiraID })

    const tag = timelineCtx.items
      .map((x) => x)
      .find((x) => x.id === `tag-${activeTag}`)

    if (tag) {
      timelineCtx.items.update({
        ...tag,
        title: text,
        jiraID: jiraID,
      })
    }
  }

  const addTag = useCallback(async () => {
    if (selectedGroup === undefined || selectedGroup === null) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    const clockTime = time?.query().position
    const activeVideo = getCurrentDriveTrial(clockTime)

    if (!activeVideo) {
      return
    }

    const offsetTime = toMilliseconds(activeVideo.previousDuration)

    const currentTime = parseInt(toMilliseconds(clockTime).toFixed())

    const tagsCopy = { ...timelineCtx.tags }

    if (!tagsCopy[selectedGroup]) {
      tagsCopy[selectedGroup] = {}
    }

    if (!tagsCopy[selectedGroup][currentTime]) {
      tagsCopy[selectedGroup][currentTime] = true

      try {
        const group = timelineCtx.groups
          .map((grp) => grp)
          .find((g) => g.id === selectedGroup)!

        const calcTime =
          highlightMode.id === -1
            ? currentTime - offsetTime
            : calculateOriginalTime(currentTime, highlightMode)

        const { data } = await createTagMutation({
          dtid: activeVideo.hilKey.DTID,
          tag: {
            startTimestamp: Math.round(calcTime),
            description: isKPI
              ? `${reportType}|${group.value.replace('-', '|')}`
              : group.value.replace('-', ''),
          },
        })

        timelineCtx.setTags(tagsCopy)
        addTagToGroup(
          currentTime - offsetTime,
          selectedGroup,
          timelineCtx,
          highlightMode,
          setHighlightMode,
          data,
          activeVideo.hilKey.DTID,
          activeVideo
        )
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar({
            message: `Error saving the tag on server. ${error.message}`,
            variant: 'error',
          })
        }
      }
    } else {
      enqueueSnackbar({ message: enUS.ERR_ITEM_ON_KEY, variant: 'info' })
    }
  }, [
    selectedGroup,
    time,
    enqueueSnackbar,
    createTagMutation,
    getCurrentDriveTrial,
    timelineCtx,
  ])

  const addNotation = useCallback(async () => {
    if (selectedGroup === undefined || selectedGroup === null) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    const clockTime = time?.query().position
    const activeVideo = getCurrentDriveTrial(clockTime)

    if (!activeVideo) {
      return
    }

    const offsetTime = toMilliseconds(activeVideo.previousDuration)

    const startTime = timelineCtx.startMarker

    const endTime = timelineCtx.endMarker

    const tagsCopy = { ...timelineCtx.tags }

    if (!tagsCopy[selectedGroup]) {
      tagsCopy[selectedGroup] = {}
    }

    if (!tagsCopy[selectedGroup][startTime!]) {
      tagsCopy[selectedGroup][startTime!] = true

      try {
        const group = timelineCtx.groups
          .map((grp) => grp)
          .find((g) => g.id === selectedGroup)!
        const { data } = await createTagMutation({
          dtid: activeVideo.hilKey.DTID,
          tag: {
            startTimestamp: startTime! - offsetTime,
            endTimestamp: endTime! - offsetTime,
            description: isKPI
              ? `${reportType}|${group.value.replace('-', '|')}`
              : group.value.replace('-', ''),
          },
        })

        timelineCtx.setTags(tagsCopy)
        addNotationToGroup(
          startTime! - offsetTime,
          endTime! - offsetTime,
          selectedGroup,
          timelineCtx,
          highlightMode,
          data,
          activeVideo.hilKey.DTID,
          activeVideo,
          editTagMutation
        )

        timelineCtx.setStartMarker!(null)
        timelineCtx.setEndMarker!(null)
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar({
            message: `Error saving the notation on server. ${error.message}`,
            variant: 'error',
          })
        }
      }
    } else {
      enqueueSnackbar({ message: enUS.ERR_ITEM_ON_KEY, variant: 'info' })
    }
  }, [
    selectedGroup,
    time,
    enqueueSnackbar,
    createTagMutation,
    getCurrentDriveTrial,
    timelineCtx,
  ])

  const markIn = useCallback(async () => {
    if (!timelineCtx.setStartMarker || !time) return

    if (
      timelineCtx.selectedGroup === undefined ||
      timelineCtx.selectedGroup === null
    ) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    if (timelineCtx.hidden.includes(timelineCtx.selectedGroup!)) {
      enqueueSnackbar({
        message: enUS.TAGS_HIDDEN,
        variant: 'info',
      })
      return
    }

    timelineCtx.setStartMarker(
      Math.round(toMilliseconds(time.query().position))
    )
  }, [time, timelineCtx])

  const markOut = useCallback(async () => {
    if (!timelineCtx.setEndMarker || !time) return

    if (
      timelineCtx.selectedGroup === undefined ||
      timelineCtx.selectedGroup === null
    ) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    if (!timelineCtx.startMarker) {
      enqueueSnackbar({
        message: enUS.NO_START_TIME,
        variant: 'info',
      })
      return
    }

    if (timelineCtx.hidden.includes(timelineCtx.selectedGroup!)) {
      enqueueSnackbar({
        message: enUS.TAGS_HIDDEN,
        variant: 'info',
      })
      return
    }

    timelineCtx.setEndMarker(Math.round(toMilliseconds(time.query().position)))
  }, [time, timelineCtx])

  return { addTag, addNotation, removeTag, editTag, markIn, markOut }
}
