import { forwardRef } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SourceChoice, TopDownData } from '../../../../details/types'
import { selectViewportState } from '../../../../store/details/viewportData/selectors'
import { topDownDataChange } from '../../../../store/details/viewportData/viewportDataSlice'
import { PopupMenu } from '../../../../ui_toolkit/PopupMenu/PopupMenu'
import { MenuProps } from '../../types'

const dropdownOptions = ['both', 'otto', 'me']

interface SourceDropDownProps extends MenuProps {
  id: number
}

export const SourceDropDownMenu = forwardRef(function SourceDropDownMenu(
  props: SourceDropDownProps,
  ref
) {
  const dispatch = useDispatch()
  const data = useSelector(selectViewportState(props.id)) as TopDownData

  return (
    <PopupMenu
      closeHandler={props.closeHandler}
      backgroundColor='#3f4c6b'
      arrowPosition={props.arrowPosition}
      ref={ref}
    >
      {dropdownOptions.map((item) => {
        const dataTestIdLayer = `layer-selector-${item}-${props.id}`
        const dataTestIdIcon = `layer-check-icon-${item}-${props.id}`
        return (
          <MenuItem
            sx={{ display: 'flex' }}
            data-testid={dataTestIdLayer}
            key={item}
            onClick={() => {
              dispatch(
                topDownDataChange({
                  id: props.id,
                  data: {
                    lane: data.lane,
                    source: item as SourceChoice,
                  },
                })
              )
              props.closeHandler()
            }}
          >
            <ListItemIcon sx={{ color: 'whitesmoke' }}>
              {item === data.source && (
                <CheckIcon data-testid={dataTestIdIcon} fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>{item}</ListItemText>
          </MenuItem>
        )
      })}
    </PopupMenu>
  )
})
