import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes as Router,
} from 'react-router-dom'
import Dashboard from './components/Dashboard/Dashboard'
import Header from './components/Header/Header'
import { Routes, URLS } from './constants'
import { Details } from './pages/Details/Details'
import { ErrorComponent } from './pages/Error/Error'
import LoginComponent from './pages/Login/Login'
import DashboardReportContextLayout from './pages/Report/layout/DashboardReportContextLayout'
import ReportContextLayout from './pages/Report/layout/ReportContextLayout'
import ReportTable from './pages/Report/ReportTable'
import StatusPage from './pages/StatusPage/StatusPage'
import { useAuthContext } from './security'

function App() {
  const { isAuthenticated } = useAuthContext()

  return (
    <BrowserRouter>
      <Router>
        <Route
          index
          path={Routes.INDEX}
          errorElement={<ErrorComponent errorType='An error has occured.' />}
          element={<LoginComponent />}
        />
        {!isAuthenticated && (
          <Route
            path={Routes.NOT_FOUND}
            element={<Navigate to={Routes.INDEX} replace={true} />}
          />
        )}
        {isAuthenticated && (
          <>
            <Route
              path={`${Routes.REPORTING}/`}
              element={
                <>
                  <Header />
                  <div className='page-container'>
                    <Outlet />
                  </div>
                </>
              }
            >
              <Route path={URLS.DRIVE_TRIAL} element={<ReportContextLayout />}>
                <Route path=':reportType' element={<ReportTable />} />
              </Route>
              <Route path={URLS.KPI_SUMMARY} element={<ReportContextLayout />}>
                <Route path=':reportType' element={<ReportTable />} />
              </Route>
              <Route
                path={Routes.DASHBOARD}
                element={<DashboardReportContextLayout />}
              >
                <Route path=':dashboardName' element={<Dashboard />} />
              </Route>
            </Route>

            <Route
              path={Routes.ERROR}
              element={
                <ErrorComponent errorType='Please contact your sistem administrator.' />
              }
            />
            <Route path=':reportType'>
              <Route path={Routes.DETAILS} element={<Details />} />
              <Route path={Routes.KPI_DETAILS} element={<Details />} />
            </Route>
            <Route path={Routes.NOT_FOUND} element={<StatusPage />} />
          </>
        )}
      </Router>
    </BrowserRouter>
  )
}

export default App
