import { RefObject, useRef, useState } from 'react'
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong'
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import ExploreIcon from '@mui/icons-material/Explore'
import MapIcon from '@mui/icons-material/Map'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { IconButton } from '@mui/material'
import { MapRef } from 'react-map-gl'
import { useDispatch } from 'react-redux'
import { iconStyles } from './commonStyles'
import { enUS } from '../../constants'
import { DriveTrial, useDriveTrialContext } from '../../details'
import { viewportMapStyleChange } from '../../store/details/viewport/viewportSlice'
import { StyledTooltip } from '../../ui_toolkit/StyledTooltip/StyledTooltip'
import { MarkerSourceRef } from '../DetailViewport/DetailViewport'
import { centerMarker } from '../Map/utils'
import { MenuRef } from '../Menus/types'
import { DriveTrialDropDownMenu as DriveTrialDropDownMenu } from '../Menus/ViewportMenu/DriveTrialDropDownMenu/DriveTrialDropDownMenu'

interface IProps {
  mapRef: RefObject<MapRef>
  markerRef: RefObject<MarkerSourceRef>
  viewportId: number
  focusMap: boolean
  setFocusMap: React.Dispatch<React.SetStateAction<boolean>>
  selectedDTID: number
  setSelectedDTID: React.Dispatch<React.SetStateAction<number>>
}

const getData = (driveTrials: DriveTrial[]) => {
  const dtOptions = new Set<number>()
  const uniqueDtids = new Set<number>()
  driveTrials.forEach((dt) => {
    dtOptions.add(dt.hilKey.DTID)
    uniqueDtids.add(dt.key.DTID)
  })

  return {
    dtOptions: [...dtOptions].sort((a, b) => a - b),
    uniqueDtids: [...uniqueDtids],
  }
}

export const MapTools = ({
  mapRef,
  markerRef,
  viewportId,
  focusMap,
  selectedDTID,
  setSelectedDTID,
}: IProps) => {
  const dispatch = useDispatch()
  const { driveTrials } = useDriveTrialContext()
  const { dtOptions, uniqueDtids } = getData(driveTrials)
  const [mapDataTestId, setMapDataTestId] = useState<string>(
    `mapStyle-${
      mapRef.current
        ? mapRef.current.getMap().getStyle.name?.replace(' ', '')
        : 'MapboxSatellite'
    }-${viewportId}`
  )
  const dtidsDropDownSpawner = useRef<MenuRef | undefined>(undefined)
  const hasMultiVersion = dtOptions.length !== uniqueDtids.length

  const zoomIn = () => {
    if (mapRef.current) mapRef.current.zoomIn()
  }

  const zoomOut = () => {
    if (mapRef.current) mapRef.current.zoomOut()
  }

  const toNorth = () => {
    if (mapRef.current) mapRef.current.resetNorth()
  }

  const changeMapStyle = () => {
    dispatch(
      viewportMapStyleChange({
        id: viewportId,
      })
    )
    if (mapRef.current) {
      const mapCopy = mapRef.current.getMap()
      const mapStyle = mapCopy.getStyle()
      mapCopy.setStyle(
        mapStyle.name === 'Mapbox Streets'
          ? 'mapbox://styles/mapbox/satellite-v9'
          : 'mapbox://styles/mapbox/streets-v11'
      )
      setMapDataTestId(
        `mapStyle-${mapStyle.name?.replace(' ', '')}-${viewportId}`
      )
    }
  }

  return (
    <>
      <StyledTooltip title='Zoom in'>
        <IconButton data-testid={`zoomInMap-${viewportId}`} onClick={zoomIn}>
          <ZoomInIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Zoom out'>
        <IconButton data-testid={`zoomOutMap-${viewportId}`} onClick={zoomOut}>
          <ZoomOutIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Return to north'>
        <IconButton
          data-testid={`returnMapToNorth-${viewportId}`}
          onClick={toNorth}
        >
          <ExploreIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Change map style'>
        <IconButton data-testid={mapDataTestId} onClick={changeMapStyle}>
          <MapIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title={enUS.FOCUS_MARKER_TITLE}>
        <IconButton
          data-testid={`focusMapMarker-${viewportId}`}
          onClick={() => centerMarker({ mapRef, markerRef })}
        >
          {focusMap ? (
            <CenterFocusStrongIcon sx={iconStyles} />
          ) : (
            <CenterFocusWeakIcon sx={iconStyles} />
          )}
        </IconButton>
      </StyledTooltip>

      {hasMultiVersion && (
        <StyledTooltip title='Choose drive trial'>
          <IconButton
            data-testid={`chooseDT-${viewportId}`}
            onClick={(e) =>
              dtidsDropDownSpawner.current?.spawn(e.currentTarget)
            }
          >
            <ConfirmationNumberIcon sx={iconStyles} />
          </IconButton>
        </StyledTooltip>
      )}

      <DriveTrialDropDownMenu
        id={viewportId}
        data={dtOptions ?? []}
        selectedDTID={selectedDTID}
        setSelectedDTID={setSelectedDTID}
        closeHandler={() => dtidsDropDownSpawner.current?.spawn(undefined)}
        ref={dtidsDropDownSpawner}
      />
    </>
  )
}
