import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { match } from 'ts-pattern'
import { createVideoPath, usePlyJsonQuery } from '../../api'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { DoubleBufferProvider, useDriveTrialContext } from '../../details'
import { getCameraURL } from '../../details/config'
import {
  MediaSyncContext,
  ObjectColor,
  ViewportContent,
  ViewportMenuObject,
} from '../../details/types'
import { getProjectType } from '../../storage/projectIdStorage'
import { selectViewportLayout } from '../../store/details/viewport/selectors'
import { viewportContentChange } from '../../store/details/viewport/viewportSlice'
import { viewportChange } from '../../store/details/viewportData/viewportDataSlice'
import { GridOption } from '../../ui_toolkit/GridSelectors/GridSelectors'
import MediaWrapper from '../../ui_toolkit/MediaWrapper/MediaWrapper'
import { Viewport } from '../../ui_toolkit/Viewport/Viewport'
import { useDetectZoomLevel } from '../../utils'
import { ILayerPanelItem } from '../LayersPanel/types'
import MapView from '../Map/Map'
import { GpsPoint } from '../Map/types'
import NoData from '../NoData/NoData'
import { View3D } from '../TopDown3D_V1_Copy/View3D'
import { TopViewMap } from '../TopViewMap/TopViewMap'
import { VideoTimeline } from '../VideoTimeline/VideoTimeline'
import { BufferedVideo } from '../VideoWithCanvas/BufferedVideo'
import { VideoWithCanvas } from '../VideoWithCanvas/VideoWithCanvas'
import { ViewportToolbar } from '../ViewportToolbarTools/ViewportToolbar'
import type { MapRef } from 'react-map-gl'

export interface MarkerSourceRef {
  setPosition: (point: GpsPoint) => void
  getPosition: () => GpsPoint | undefined
}

interface DetailViewportProps {
  goFullscreen: (content: number) => void
  exitFullScreen: () => void
  isFullscreen: boolean
  id: number
  colors: ObjectColor[]
  layerPanelData: ILayerPanelItem[]
  menuOptions: ViewportMenuObject[]
  grid: GridOption
  synchronizer?: ISynchronizer
}

export function DetailViewport({
  goFullscreen,
  exitFullScreen,
  isFullscreen,
  id,
  colors,
  layerPanelData,
  menuOptions,
  grid,
  synchronizer,
}: DetailViewportProps) {
  const { driveTrials, modeKey } = useDriveTrialContext()
  const { activeVideo } = useContext(MediaSyncContext)
  const mapRef = useRef<MapRef>(null)
  const markerRef = useRef<MarkerSourceRef>(null)
  const [focusMap, setFocusMap] = useState(true)
  const [selectedDTID, setSelectedDTID] = useState<number>(activeVideo!)
  const viewportData = useSelector(selectViewportLayout(id))
  const dispatch = useDispatch()
  const { data: signsData } = usePlyJsonQuery()

  const getTitle = useCallback(
    () => menuOptions.find((option) => option.id === viewportData)?.name ?? '',
    [viewportData, menuOptions]
  )

  useDetectZoomLevel(0.75, 1.5)

  const changeViewport = (viewportId: number, content: ViewportContent) => {
    dispatch(
      viewportContentChange({
        id: viewportId,
        content,
      })
    )

    dispatch(
      viewportChange({
        id: viewportId,
        content,
      })
    )
  }

  const pickContent = (content: ViewportContent) => {
    changeViewport(id, content)
  }

  const fullscreen = () => {
    goFullscreen(id)
  }

  useEffect(() => {
    synchronizer?.updateStatus(id, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportData])

  const renderVideo = (videoSide: ViewportContent) => {
    const projectType = getProjectType()
    let videoName = ''
    try {
      videoName = getCameraURL(videoSide, projectType)
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message)
      }
    }

    if (!videoName) {
      synchronizer?.updateStatus(id, true)
    }

    return !videoName || !driveTrials.length ? (
      <NoData languageCode='enUS' viewport={getTitle()} />
    ) : driveTrials.length > 1 ? (
      <DoubleBufferProvider key={modeKey}>
        <BufferedVideo
          viewportId={id}
          colors={colors}
          videoName={videoName}
          id={0}
          title={getTitle()}
          synchronizer={synchronizer}
        />
        <BufferedVideo
          viewportId={id}
          colors={colors}
          videoName={videoName}
          id={1}
          title={getTitle()}
          synchronizer={synchronizer}
        />
      </DoubleBufferProvider>
    ) : (
      <VideoWithCanvas
        key={modeKey}
        videoId={driveTrials[0].id}
        playerId={id.toString()}
        videoKey={driveTrials[0].key}
        hilKey={driveTrials[0].hilKey}
        playbackRate={1}
        colors={colors}
        isFullscreen={isFullscreen}
        viewportId={id}
        title={getTitle()}
        synchronizer={synchronizer}
        url={createVideoPath(driveTrials[0].key.DTID, videoName)}
      />
    )
  }

  return (
    <Viewport isViewportStyled viewportContentTitle title={getTitle()}>
      {match(viewportData)
        .with(ViewportContent.FRONT_CAMERA, () =>
          renderVideo(ViewportContent.FRONT_CAMERA)
        )
        .with(ViewportContent.MAP, () => (
          <MapView
            focusMap={focusMap}
            mapRef={mapRef}
            markerRef={markerRef}
            synchronizer={synchronizer}
            viewportId={id}
            selectedDTID={selectedDTID}
          />
        ))
        .with(ViewportContent.TIMELINE, () => (
          <VideoTimeline
            synchronizer={synchronizer}
            viewportId={id}
            signsData={signsData}
          />
        ))
        .with(ViewportContent['3D_VIEW'], () => (
          <MediaWrapper>
            <View3D
              viewportId={id}
              isFullscreen={isFullscreen}
              synchronizer={synchronizer}
              grid={grid}
              signsData={signsData}
            />
          </MediaWrapper>
        ))
        .with(ViewportContent.LEFT_CAMERA, () =>
          renderVideo(ViewportContent.LEFT_CAMERA)
        )
        .with(ViewportContent.REAR_CAMERA, () =>
          renderVideo(ViewportContent.REAR_CAMERA)
        )
        .with(ViewportContent.RIGHT_CAMERA, () =>
          renderVideo(ViewportContent.RIGHT_CAMERA)
        )
        .with(ViewportContent.TOP_VIEW_MAP, () => (
          <TopViewMap viewportId={id} synchronizer={synchronizer} />
        ))
        .otherwise(() => (
          <NoData languageCode='enUS' />
        ))}

      <ViewportToolbar
        direction='column'
        focusMap={focusMap}
        setFocusMap={setFocusMap}
        setContent={pickContent}
        currentContent={viewportData}
        layerPanelData={layerPanelData}
        goFullscreen={fullscreen}
        exitFullScreen={exitFullScreen}
        isFullscreen={isFullscreen}
        mapRef={mapRef}
        markerRef={markerRef}
        viewportId={id}
        colors={colors}
        menuOptions={menuOptions}
        selectedDTID={selectedDTID}
        setSelectedDTID={setSelectedDTID}
      />
    </Viewport>
  )
}
