import { forwardRef } from 'react'
import { ColorKeys, colorsDict } from '../../constants'
import { ObjectColor } from '../../details/types'
import PopupOverlay from '../../ui_toolkit/PopupOverlay/PopupOverlay'
import './style.scss'

interface DomainObjectColor {
  name: string
  ottoColor?: string | undefined | null
  mobileEyeColor?: string | undefined | null
}

const colorBlock = (color: string | null | undefined) => (
  <div
    className={'color-legend-color-block'}
    style={{
      backgroundColor: `#${color}`,
    }}
  />
)

const prepareColors = (colors: ObjectColor[]) => {
  if (!colors?.length) {
    return
  }

  const colorObjs = colors.reduce(
    (acc: DomainObjectColor[], color: ObjectColor) => {
      const { source, layerColor, name } = color
      let collectionColor = acc.find((x) => x.name === name)
      const key = source === 'Ottometric' ? 'ottoColor' : 'mobileEyeColor'
      if (collectionColor) {
        collectionColor[key] = layerColor
      } else {
        collectionColor = {
          name: name,
          [key]: layerColor,
        } as DomainObjectColor
        acc.push(collectionColor)
      }

      return acc
    },
    []
  )

  return colorObjs
}

interface IProps {
  closeHandler: () => void
  colors: ObjectColor[]
  popupPosition: string
}

export const ColorLegend = forwardRef(function ColorLegend(props: IProps, ref) {
  const { closeHandler, colors, popupPosition } = props
  const colorObjs = prepareColors(colors)

  return (
    <PopupOverlay
      closeHandler={closeHandler}
      ref={ref}
      popupPosition={popupPosition}
    >
      <div className={'color-legend-container'}>
        <h3 className={'color-legend-heading'}>{`Colors`}</h3>
        <div>
          <div className={'color-legend-column-heading'}>
            <div>Layers</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>OL</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>VL</div>
          </div>
          <div>
            {colorObjs?.map((color) => (
              <div key={color.name} className={'color-legend-row'}>
                <div>{colorsDict[color.name as ColorKeys]}</div>
                {colorBlock(color.ottoColor)}
                {colorBlock(color.mobileEyeColor)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </PopupOverlay>
  )
})

export default ColorLegend
