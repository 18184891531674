export interface Color {
  r: number
  g: number
  b: number
}

export interface GainColor {
  pct: number
  color: Color
}

export const getYellowToRed = (percent: number): [number, number, number] => {
  const g = Math.floor(255 - percent * 255)
  const r = 255
  const b = 0

  return [r, g, b]
}

const createColorPalette = () => {
  const a: GainColor[] = []
  for (let i = 0; i <= 100; i = i + 2) {
    const per = i / 100
    const col = getYellowToRed(per)
    a.push({ pct: per, color: { r: col[0], g: col[1], b: 0 } })
  }

  return a
}

export const getColorForPercentage = (pct: number): Color | undefined => {
  if (pct < 0 || pct > 1) {
    return undefined
  }

  const percentColors = createColorPalette()

  const i = percentColors.findIndex((color) => color.pct && pct <= color.pct)
  const lower = percentColors[i - 1]
  const upper = percentColors[i]
  const range = upper.pct - lower.pct
  const rangePct = (pct - lower.pct) / range
  const pctLower = 1 - rangePct
  const pctUpper = rangePct

  return {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  }
}

export const createHighlightGainStyle = (gain: number): string => {
  if (!gain) {
    return 'height: 18px;'
  }
  const gainColors = getColorForPercentage(gain)
  const alphaBG = 1

  if (!gainColors) {
    return 'height: 18px;'
  }

  const values = [...Object.values(gainColors)]
  const backgroundRGBA = `rgba(${values.join(',')}, ${alphaBG})`

  return `height: 18px; background-color: ${backgroundRGBA}; border-color: black;`
}
