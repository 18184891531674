import { useCallback } from 'react'
import { SnackbarKey, useSnackbar, VariantType } from 'notistack'
import CloseSnackbarAction from '../ui_toolkit/CloseSnackbarAction/CloseSnackbarAction'

export const useClosableSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return useCallback(
    (variant: VariantType, message: string) =>
      enqueueSnackbar({
        variant,
        message,
        action: (key: SnackbarKey) =>
          CloseSnackbarAction({
            id: key,
            closeSnackbar,
          }),
        autoHideDuration: null,
      }),
    [closeSnackbar, enqueueSnackbar]
  )
}
