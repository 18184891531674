import { PropsWithChildren } from 'react'
import { VideoContext } from './types'
import { useVideoUrlQuery } from '../../api/url'

interface VideoProviderProps extends PropsWithChildren {
  videoPath: string
}

export function VideoProvider({ children, videoPath }: VideoProviderProps) {
  const { data } = useVideoUrlQuery(videoPath)

  return (
    <VideoContext.Provider value={{ url: data?.url }}>
      {children}
    </VideoContext.Provider>
  )
}
