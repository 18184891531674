import ErrorIcon from '@mui/icons-material/Error'
import { match } from 'ts-pattern'
import { enUS } from '../../constants'

interface IProps {
  languageCode: string
  viewport?: string
}

const NoData = ({ languageCode, viewport }: IProps) => {
  const langCode = match(languageCode)
    .with('enUS', () => enUS)
    .otherwise(() => enUS)

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ErrorIcon fontSize='large' sx={{ color: '#6baff8', fontSize: '3rem' }} />
      <h3
        style={{
          color: 'whitesmoke',
          fontWeight: 300,
          fontSize: '16px',
        }}
      >
        {viewport
          ? `${langCode.DATA_NOT_AVAILABLE} - ${viewport}`
          : langCode.DATA_NOT_AVAILABLE}
      </h3>
    </div>
  )
}

export default NoData
