import { PropsWithChildren, RefObject, useRef, useState } from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import PreviewIcon from '@mui/icons-material/Preview'
import { IconButton, Stack } from '@mui/material'
import { MapRef } from 'react-map-gl'
import { P, match } from 'ts-pattern'
import { CameraTools } from './CameraTools'
import { iconStyles } from './commonStyles'
import { MapTools } from './MapTools'
import { TimelineTools } from './TimelineTools'
import { TopDownTools } from './TopDownTools'
import ColorLegend from '../../components/ColorLegend/ColorLegend'
import { MarkerSourceRef } from '../../components/DetailViewport/DetailViewport'
import LayersPanel from '../../components/LayersPanel/LayersPanel'
import { ILayerPanelItem } from '../../components/LayersPanel/types'
import { MenuRef } from '../../components/Menus/types'
import { ViewportMenu } from '../../components/Menus/ViewportMenu/ViewportMenu'
import {
  ObjectColor,
  ViewportContent,
  ViewportMenuObject,
} from '../../details/types'
import { StyledTooltip } from '../../ui_toolkit/StyledTooltip/StyledTooltip'
import './style.scss'
import ObjectsTable from '../ObjectsTable/ObjectsTable'

interface ViewportToolbarProps {
  direction: 'row' | 'column'
  isFullscreen: boolean
  focusMap: boolean
  setFocusMap: React.Dispatch<React.SetStateAction<boolean>>
  layerPanelData?: ILayerPanelItem[]
  colors: ObjectColor[]
  currentContent: ViewportContent
  setContent: (content: ViewportContent) => void
  goFullscreen: () => void
  exitFullScreen: () => void
  mapRef: RefObject<MapRef>
  markerRef: RefObject<MarkerSourceRef>
  viewportId: number
  menuOptions: ViewportMenuObject[]
  selectedDTID: number
  setSelectedDTID: React.Dispatch<React.SetStateAction<number>>
}

export const ViewportToolbar = ({
  direction,
  isFullscreen,
  focusMap,
  setFocusMap,
  currentContent,
  layerPanelData,
  colors,
  setContent,
  goFullscreen,
  exitFullScreen,
  mapRef,
  markerRef,
  viewportId,
  menuOptions,
  selectedDTID,
  setSelectedDTID,
}: PropsWithChildren<ViewportToolbarProps>) => {
  const layerPanelRefSpawner = useRef<MenuRef>()
  const colorLegendRefSpawner = useRef<MenuRef>()
  const viewportSpawnerMenu = useRef<MenuRef>()
  const objectsTableSpawner = useRef<MenuRef>()
  const layerBtnRef = useRef<SVGSVGElement | null>(null)
  const legendBtnRef = useRef<SVGSVGElement | null>(null)
  const objectsTableBtnRef = useRef<SVGSVGElement | null>(null)
  const isLayerPanelOpen = useRef<boolean | null>(false)
  const isColorLegendOpen = useRef<boolean | null>(false)
  const isObjectsTableOpen = useRef<boolean | null>(false)
  const [showObjectsTable, setShowObjectsTable] = useState(false)

  const renderCameraTools = () => (
    <CameraTools
      layersIconRef={layerBtnRef}
      legendIconRef={legendBtnRef}
      objectsIconRef={objectsTableBtnRef}
      viewportId={viewportId}
      layersHandler={(e) => {
        layerPanelRefSpawner.current?.spawn(
          isLayerPanelOpen.current ? undefined : e.currentTarget
        )
        isLayerPanelOpen.current = !isLayerPanelOpen.current
      }}
      legendHandler={(e) => {
        colorLegendRefSpawner.current?.spawn(
          isColorLegendOpen.current ? undefined : e.currentTarget
        )
        isColorLegendOpen.current = !isColorLegendOpen.current
      }}
      objectsHandler={(e) => {
        objectsTableSpawner.current?.spawn(
          isObjectsTableOpen.current ? undefined : e.currentTarget
        )
        setShowObjectsTable(!isObjectsTableOpen.current)
        isObjectsTableOpen.current = !isObjectsTableOpen.current
      }}
    />
  )

  return (
    <>
      <Stack direction={direction} className={'toolbar-items-container'}>
        <StyledTooltip title='Change viewport'>
          <IconButton
            data-testid={`viewportMenu-${viewportId}`}
            onClick={(e) => viewportSpawnerMenu.current?.spawn(e.currentTarget)}
          >
            <PreviewIcon sx={iconStyles} />
          </IconButton>
        </StyledTooltip>

        {!isFullscreen ? (
          <StyledTooltip title='Fullscreen'>
            <IconButton
              data-testid={`fullscreen-enter-${viewportId}`}
              onClick={goFullscreen}
            >
              <FullscreenIcon sx={iconStyles} />
            </IconButton>
          </StyledTooltip>
        ) : (
          <StyledTooltip title='Exit fullscreen'>
            <IconButton
              data-testid={`fullscreen-exit-${viewportId}`}
              onClick={exitFullScreen}
            >
              <FullscreenExitIcon sx={iconStyles} />
            </IconButton>
          </StyledTooltip>
        )}

        {match(currentContent)
          .with(ViewportContent.MAP, () => (
            <MapTools
              mapRef={mapRef}
              markerRef={markerRef}
              viewportId={viewportId}
              focusMap={focusMap}
              setFocusMap={setFocusMap}
              selectedDTID={selectedDTID}
              setSelectedDTID={setSelectedDTID}
            />
          ))
          .with(ViewportContent.TIMELINE, () => (
            <TimelineTools zoomStep={1} viewportId={viewportId} />
          ))
          .with(ViewportContent['3D_VIEW'], () => (
            <TopDownTools viewportId={viewportId} />
          ))
          .with(
            P.union(
              ViewportContent.FRONT_CAMERA,
              ViewportContent.LEFT_CAMERA,
              ViewportContent.REAR_CAMERA,
              ViewportContent.RIGHT_CAMERA
            ),
            renderCameraTools
          )
          .otherwise(() => (
            <></>
          ))}
      </Stack>
      <ViewportMenu
        setContent={setContent}
        closeHandler={() => viewportSpawnerMenu.current?.spawn(undefined)}
        ref={viewportSpawnerMenu}
        menuOptions={menuOptions}
        viewportId={viewportId}
      />
      {layerPanelData && (
        <LayersPanel
          viewportId={viewportId}
          ref={layerPanelRefSpawner}
          layerBtnSpawner={layerBtnRef}
          closeHandler={() => {
            layerPanelRefSpawner.current?.spawn(undefined)
            isLayerPanelOpen.current = !isLayerPanelOpen.current
          }}
          data={layerPanelData}
        />
      )}
      <ColorLegend
        ref={colorLegendRefSpawner}
        closeHandler={() => {
          colorLegendRefSpawner.current?.spawn(undefined)
          isColorLegendOpen.current = !isColorLegendOpen.current
        }}
        popupPosition='left'
        colors={colors}
      />
      <ObjectsTable
        popupPosition='right'
        ref={objectsTableSpawner}
        objectsTableBtnSpawner={objectsTableBtnRef}
        showObjectsTable={showObjectsTable}
        closeHandler={() => {
          objectsTableSpawner.current?.spawn(undefined)
          isObjectsTableOpen.current = !isObjectsTableOpen.current
        }}
      />
    </>
  )
}
