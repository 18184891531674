import {
  BufferGeometry,
  Line,
  Material,
  NormalBufferAttributes,
  Object3D,
  PerspectiveCamera,
  Points,
  PointsMaterial,
  Scene,
  Vector3,
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { urls } from '../../../api'
import { LaneChoice, SourceChoice } from '../../../details/types'
import { getToken } from '../../../security'
import { removeTrailingSlash } from '../../../utils'
import { SignDataResponse } from '../types'

export const createPointCloud = (
  dtid: number,
  frame: number,
  callback: (
    points: Points<BufferGeometry<NormalBufferAttributes>, PointsMaterial>
  ) => void
) => {
  const material = new PointsMaterial({
    size: 0.1,
    vertexColors: true,
  })
  const loader = new PLYLoader()
  loader.setRequestHeader({
    Authorization: `Bearer ${getToken()}`,
    'Return-Type': 'binary/octet-stream',
  })

  try {
    loader.load(
      removeTrailingSlash(process.env.REACT_APP_URL_BACKEND_WEB!) +
        urls.files.replace('url', btoa(`plys/pcd-${dtid}-${frame}.ply`)),
      function (geometry) {
        const points = new Points(geometry, material)
        points.rotateX(-Math.PI / 2)
        points.position.set(0, 1.6, 0)
        points.name = 'point-cloud'
        callback(points)
      }
    )
  } catch (err) {
    console.error('Loading PLY failed. ', err)
  }
}

export const getPointCloudData = (
  DTID: number,
  frame: number,
  signsData: SignDataResponse
) => {
  if (!signsData || !signsData[DTID]) {
    return undefined
  }

  const dtidData = signsData[DTID]
  const dtidFrames = Object.keys(dtidData)

  if (dtidFrames.includes(frame.toString())) {
    return [dtidData[frame]]
  }

  return undefined
}

export const removeObjectsByNameFromScene = (
  scene: Scene,
  namesToRemove: string[]
) => {
  const objectsToRemove: Array<Points | Line> = []

  scene.traverse((object: Object3D) => {
    if (namesToRemove.includes(object.name)) {
      objectsToRemove.push(object as Points | Line)
    }
  })

  for (const object of objectsToRemove) {
    scene.remove(object)
    if (object.geometry) object.geometry.dispose()
    if (object.material) {
      if (Array.isArray(object.material)) {
        object.material.forEach((material: Material) => material.dispose())
      } else {
        object.material.dispose()
      }
    }
  }
}

export const setLaneVisibility = (
  obj: Object3D,
  source: SourceChoice,
  lane: LaneChoice
) => {
  const includesSource = source === 'both' || obj.name.includes(source)
  const includesLane = lane === 'both' || obj.name.includes(lane)

  obj.visible = includesSource && includesLane
}

export const updateScene = (
  scene: Scene,
  X: number,
  Y: number,
  heading: number,
  control: OrbitControls,
  camera: PerspectiveCamera,
  isOrbitControlsLocked: boolean
) => {
  scene.traverse((object) => {
    if (
      object.name === 'otto-left' ||
      object.name === 'otto-right' ||
      object.name === 'otto-adjacent-left' ||
      object.name === 'otto-adjacent-right'
    ) {
      object.position.set(-Y, 0, -X)
    }

    if (object.name === 'el_vehicle') {
      object.rotation.z = -heading

      if (isOrbitControlsLocked) {
        control.enabled = false

        const distance = 7.080362981962773
        const offsetRotation = 1.57
        const offsetY = 3.317541816541957
        const offsetX = distance * Math.sin(-heading - offsetRotation)
        const offsetZ = distance * Math.cos(-heading - offsetRotation)

        camera.position.x = object.position.x + offsetX
        camera.position.y = object.position.y + offsetY
        camera.position.z = object.position.z + offsetZ

        // Look at the car
        camera.lookAt(new Vector3(0, 2, 0))
        control.target = new Vector3(0, 2, 0)
      } else {
        control.enabled = true
      }
    }
  })
}
