import React from 'react'
import AppsIcon from '@mui/icons-material/Apps'
import { IconButton, Tooltip } from '@mui/material'
import { enUS } from '../../constants'
import './style.scss'

interface GridLogoProps {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  isDisabled: boolean
}

export const GridLogo = ({ onClick, isDisabled }: GridLogoProps) => (
  <Tooltip title={enUS.VIEWPORT_MENU} arrow placement='left'>
    <IconButton
      data-testid='viewportMenu'
      onClick={onClick}
      disabled={isDisabled}
    >
      <AppsIcon
        className='viewport-logo'
        sx={{ color: isDisabled ? 'grey !important' : 'whitesmoke' }}
      />
    </IconButton>
  </Tooltip>
)
