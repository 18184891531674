import { useEffect, useImperativeHandle, useState } from 'react'
import { Stack } from '@mui/material'
import { Field, RuleGroupType } from 'react-querybuilder'
import { getDropdownOptions } from './queryBuilderUtils'
import StyledQueryStringWrapper from './StyledDiv'
import {
  INIT_FILTER_STATE,
  INIT_QUERY_BUILDER_STATE,
} from '../../pages/Report/initialStates'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { ReportType } from '../../pages/Report/types'
import QueryBuilder from '../QueryBuilder/QueryBuilder'
import {
  StyledApplyBtn,
  StyledBtnContainer,
  StyledResetBtn,
} from '../StyledComponents/buttons'
import { formatQuerySql } from '../Toolbox/utils'

function QueryBuilderSelector({ reportType }: { reportType: ReportType }) {
  const { searchColumns, preserveState, queryBuilderRef, setPreserveState } =
    useReportContext()
  const [query, setQuery] = useState(
    preserveState[reportType].queryBuilderState
  )

  useEffect(() => {
    setQuery(preserveState[reportType].queryBuilderState)
    //If querybuilder is open, we need to refresh the data inside the state when the reportType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  //expose handleReset function to main context so we can refresh the selection from other components
  useImperativeHandle(queryBuilderRef, () => ({ resetQuery: handleReset }), [])

  const isDisabled = query?.rules?.length === 0
  const options: Field[] | undefined =
    getDropdownOptions(searchColumns ?? [], reportType) ?? []

  const updateQuery: any = (query: RuleGroupType) => ({
    ...query,
    rules: query.rules.map((x) => {
      if ('rules' in x) return updateQuery(x)
      if (
        'operator' in x &&
        (x.operator === 'between' || x.operator === 'notBetween')
      ) {
        const sortBetween = x.value.split(',').toSorted().join(', ')
        return {
          ...x,
          value: sortBetween,
        }
      }

      return x
    }),
  })

  const handleQuery = () => {
    const queryRulesModified = updateQuery(query)
    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        queryBuilderState: query,
        searchType: 'sql',
        clause: `WHERE ${formatQuerySql(queryRulesModified)}`,
        filterState: INIT_FILTER_STATE,
        filterToSql: {},
      },
    })
  }

  const handleReset = () => {
    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        queryBuilderState: INIT_QUERY_BUILDER_STATE,
        searchType: 'sql',
        clause: `WHERE ${formatQuerySql(INIT_QUERY_BUILDER_STATE)}`,
      },
    })
    setQuery(INIT_QUERY_BUILDER_STATE)
  }

  return (
    <Stack spacing={4} direction='column'>
      <QueryBuilder fields={options} query={query!} setQuery={setQuery} />
      <StyledQueryStringWrapper>
        <p style={{ marginTop: 0 }}>Sql query:</p>
        {isDisabled ? <i>Empty</i> : formatQuerySql(query!)}
      </StyledQueryStringWrapper>
      <StyledBtnContainer spacing={1} direction='column'>
        <StyledApplyBtn
          onClick={handleQuery}
          variant='contained'
          fullWidth
          disabled={isDisabled}
        >
          Apply
        </StyledApplyBtn>
        <StyledResetBtn onClick={handleReset} variant='contained' fullWidth>
          Reset
        </StyledResetBtn>
      </StyledBtnContainer>
    </Stack>
  )
}

export default QueryBuilderSelector
