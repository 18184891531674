import dayjs, { Dayjs } from 'dayjs'
import { DateType, Timeline } from 'otto-vis-timeline'
import {
  formatCustomTime,
  toMilliseconds,
  unixTimestampToDate,
} from '../../../utils'
import { CustomTime } from '../model'

const slideAnimationDuration = 350

export const slideTimeline = (
  start: Dayjs,
  end: Dayjs,
  timeline: Timeline,
  sliding: React.MutableRefObject<boolean>
) => {
  if (!timeline || sliding.current) {
    return
  }

  sliding.current = true
  timeline.setWindow(start.toDate(), end.toDate(), {
    animation: {
      duration: slideAnimationDuration,
      easingFunction: 'easeInOutQuart',
    },
  })

  setTimeout(() => {
    sliding.current = false
  }, slideAnimationDuration + 15)
}

export const checkShouldSlide = (
  currentTime: Dayjs,
  controlledTime: CustomTime,
  timeline: Timeline,
  sliding: React.MutableRefObject<boolean>,
  slideOffsetPercent: number
) => {
  if (
    !currentTime ||
    !timeline ||
    !controlledTime ||
    !slideOffsetPercent ||
    sliding.current
  ) {
    return
  }

  const visibleTime = timeline.getWindow()
  const bg = document.getElementsByClassName(
    'vis-panel vis-center'
  )[0] as HTMLElement
  const marker = document.getElementsByClassName(
    'vis-custom-time custom-marker'
  )[0] as HTMLElement

  const shouldSlideForward =
    marker.offsetLeft >= bg.getBoundingClientRect().width * slideOffsetPercent
  const shouldSlideBackward = marker.offsetLeft < 0

  if (shouldSlideBackward || shouldSlideForward) {
    const currentTimeDJS = dayjs(currentTime)
    const sub = dayjs(visibleTime.end).subtract(currentTimeDJS.valueOf())
    const start = dayjs(visibleTime.end).subtract(sub.valueOf())
    const windowEnd = dayjs(visibleTime.end)
    const windowStart = dayjs(visibleTime.start)
    const diff = windowEnd.subtract(windowStart.valueOf())
    const end = start.add(diff.valueOf())

    slideTimeline(start, end, timeline, sliding)
  }
}

export const moveMainMarker = (
  currentTimeDate: number,
  currentTime: DateType,
  controlledTime: CustomTime,
  timeline: Timeline,
  sliding: React.MutableRefObject<boolean>,
  slideOffsetPercent: number
) => {
  if (!timeline || !controlledTime) {
    return
  }
  timeline.setCustomTimeTitle(
    formatCustomTime(unixTimestampToDate(currentTimeDate)),
    controlledTime.id
  )
  const ctDay = dayjs(toMilliseconds(currentTime as number))
  timeline.setCustomTime(ctDay.toDate(), controlledTime.id)

  checkShouldSlide(ctDay, controlledTime, timeline, sliding, slideOffsetPercent)
}
