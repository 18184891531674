import { useContext, useRef } from 'react'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone'
import PowerInputIcon from '@mui/icons-material/PowerInput'
import SyncLockIcon from '@mui/icons-material/SyncLock'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { IconButton } from '@mui/material'
import { iconStyles } from './commonStyles'
import { TopViewContext } from '../../details'
import { StyledTooltip } from '../../ui_toolkit/StyledTooltip/StyledTooltip'
import { MenuRef } from '../Menus/types'
import { LinesDropDownMenu } from '../Menus/ViewportMenu/LinesDropDownMenu/LinesDropDownMenu'
import { SourceDropDownMenu } from '../Menus/ViewportMenu/SourceDropDownMenu/SourceDropDownMenu'

interface IProps {
  viewportId: number
}

export const TopDownTools = ({ viewportId }: IProps) => {
  const linesDropDownSpawner = useRef<MenuRef | undefined>(undefined)
  const sourceDropDownSpawner = useRef<MenuRef | undefined>(undefined)

  const { cameras, setIsOrbitControlsLocked, isOrbitControlsLocked } =
    useContext(TopViewContext)
  const camera = cameras[viewportId]

  return (
    <>
      <StyledTooltip title='Zoom in'>
        <IconButton
          data-testid={`zoomIn3D-${viewportId}`}
          onClick={() => {
            if (camera && camera.fov >= 15) {
              camera.fov -= 5
              camera.updateProjectionMatrix()
            }
          }}
        >
          <ZoomInIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Zoom out'>
        <IconButton
          data-testid={`zoomOut3D-${viewportId}`}
          onClick={() => {
            if (camera && camera.fov <= 100) {
              camera.fov += 5
              camera.updateProjectionMatrix()
            }
          }}
        >
          <ZoomOutIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Reset zoom'>
        <IconButton
          data-testid={`resetZoom3D-${viewportId}`}
          onClick={() => {
            if (camera) {
              camera.fov = 50
              camera.updateProjectionMatrix()
            }
          }}
        >
          <FindReplaceIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip
        title={isOrbitControlsLocked ? 'Unlock rotation' : 'Lock rotation'}
      >
        <IconButton
          data-testid={
            isOrbitControlsLocked
              ? `unlockRotation3D-${viewportId}`
              : `lockRotation3D-${viewportId}`
          }
          onClick={() => {
            setIsOrbitControlsLocked(!isOrbitControlsLocked)
          }}
        >
          {isOrbitControlsLocked ? (
            <ThreeDRotationIcon sx={iconStyles} />
          ) : (
            <SyncLockIcon sx={iconStyles} />
          )}
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Lines selector'>
        <IconButton
          data-testid={`lineSelectro3D-${viewportId}`}
          onClick={(e) => linesDropDownSpawner.current?.spawn(e.currentTarget)}
        >
          <PowerInputIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Choose layer'>
        <IconButton
          data-testid={`chooseLayer3D-${viewportId}`}
          onClick={(e) => sourceDropDownSpawner.current?.spawn(e.currentTarget)}
        >
          <LayersTwoToneIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <LinesDropDownMenu
        id={viewportId}
        closeHandler={() => linesDropDownSpawner.current?.spawn(undefined)}
        ref={linesDropDownSpawner}
      />

      <SourceDropDownMenu
        id={viewportId}
        closeHandler={() => sourceDropDownSpawner.current?.spawn(undefined)}
        ref={sourceDropDownSpawner}
      />
    </>
  )
}
