import { Dispatch, SetStateAction, createContext } from 'react'
import { HighlightMode } from './TimelineContextProvider'
import { RedirectData } from '../../api/redirectionData'
import { RowKey } from '../../models/table'

export type SamplerReference = {
  terminate: () => void
}

export type SynchronizerReference = {
  pauseVideos: () => void
}

//#region DriveTrial
export interface DriveTrial {
  id: number
  key: RowKey
  hilKey: RowKey
  version: string
  startDate: number
  endDate: number
  duration: number
  cumulativeDuration: number
  startTime: number
  endTime: number
  description: string
  previousDuration: number
  playbackSkew: number
}

export interface HandleTitleChangeProps {
  setActiveVideo: (
    activeVideo: React.SetStateAction<number | undefined>
  ) => void
}

export interface IDriveTrialContext {
  modeKey: string
  driveTrials: DriveTrial[]
  redirectData: RedirectData
  highlightMode: HighlightMode
  setHighlightMode: React.Dispatch<React.SetStateAction<HighlightMode>>
  getCurrentDriveTrial: (timestamp: number) => DriveTrial | undefined
  getDriveTrialByKey: (key: number) => DriveTrial | undefined
  getDriveTrialByHilKey: (key: number) => DriveTrial | undefined
  getDriveTrialById: (id: number) => DriveTrial | undefined
  isConcatenation: () => boolean
  handleTitleChangeRef?: React.RefObject<HandleTitleChangeProps | undefined>
  synchronizerRef: React.RefObject<SynchronizerReference>
}

export const DriveTrialContext = createContext({} as IDriveTrialContext)
//#endregion DriveTrial

//#region DoubleBuffer
export enum DisplayValue {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export interface DoubleBuffer {
  setBufferOne?: React.Dispatch<React.SetStateAction<DriveTrial | undefined>>
  setBufferTwo?: React.Dispatch<React.SetStateAction<DriveTrial | undefined>>
}

export interface IDoubleBufferContext {
  firstRef: React.RefObject<HTMLDivElement>
  secondRef: React.RefObject<HTMLDivElement>
  doubleBufferRef: React.RefObject<DoubleBuffer>
}

export const DoubleBufferContext = createContext({} as IDoubleBufferContext)
//#endregion DoubleBuffer

//#region VideoProvider
interface IVideoContext {
  url?: string
}

export const videoContext: IVideoContext = {
  url: undefined,
}

export const VideoContext = createContext(videoContext)
//#endregion VideoProvider

//#region LoadingProvider
interface LoadingContextProps {
  text: string
  showLoadingSpinner: boolean
  isVideoLoaded: boolean
  setIsVideoLoaded: Dispatch<SetStateAction<boolean>>
  setIsBuffering: Dispatch<SetStateAction<boolean>>
  clearUnusedCanvases: (canvses: string[]) => void
  setAreCanvasesLoading: (objectType: string, value: boolean) => void
}
export const LoadingContext = createContext({} as LoadingContextProps)
//#endregion LoadingProvider
