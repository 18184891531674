import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'
import { getEmail } from '../../storage/emailStorage'
import { getSource, getToken, IdentityProvider } from '../tokenStorage'

export type SetAuthContextProps = {
  token?: string
  source?: IdentityProvider
  email?: string
}

interface AuthContextProps extends SetAuthContextProps {
  isAuthenticated: boolean
  setAuthContext: (args: SetAuthContextProps) => void
}

const initialValue = {
  isAuthenticated: false,
  setAuthContext: () => {},
}

const AuthContext = createContext<AuthContextProps>(initialValue)
const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useState<string | undefined>(() => getToken())
  const [email, setEmail] = useState<string | undefined>(() => getEmail())
  const [source, setSource] = useState<IdentityProvider | undefined>(() =>
    getSource()
  )

  const setAuthContext = useCallback(
    ({ token, source, email }: SetAuthContextProps) => {
      setToken(token)
      setSource(source)
      setEmail(email)
    },
    []
  )

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: Boolean(token && source && email),
        email,
        token,
        source,
        setAuthContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuthContext }
