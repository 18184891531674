import { Field, RuleType } from 'react-querybuilder'
import { match } from 'ts-pattern'
import {
  completenessTypeMap,
  generalTypeMap,
  getDuplicatePrefixes,
  getOptionName,
  getOptionValue,
} from './types'
import { ReportType } from '../../pages/Report/types'

const getOptionConfig = (val: string, reportType: ReportType) => {
  const map = match(reportType)
    .with('completeness', () => completenessTypeMap)
    .with('quality', () => generalTypeMap)
    .with('characterization', () => generalTypeMap)
    .otherwise(() => generalTypeMap)

  const existingName = map.get(val)
  return existingName ? existingName : {}
}

const validator = (r: RuleType) => !!r.value

export const getDropdownOptions = (
  items: string[],
  reportType: ReportType
): Field[] => {
  const duplicatePrefixes = getDuplicatePrefixes(items)

  return items.map((item) => {
    const parts = item.split('|')
    const name = getOptionName(parts[0], duplicatePrefixes)
    const value = parts[1]
    return {
      name: getOptionValue(value),
      label: name.toLocaleUpperCase(),
      placeholder: `Enter ${name.toLowerCase()}`,
      validator,
      ...getOptionConfig(name, reportType),
    }
  })
}
