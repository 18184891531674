import { useEffect } from 'react'
import { capitalize } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { StyledAlert } from './components/StyledComponentsTable'
import TableCreator from './components/TableCreator'
import {
  INIT_BREADCRUMBS,
  INIT_FILTER_STATE,
  INIT_QUERY_BUILDER_STATE,
} from './initialStates'
import { useReportContext } from './providers/ReportContextProvider'
import { useTableQuery, useKpiTableQuery } from '../../api'
import { TableRouteParams, enUS } from '../../constants'
import { setProjectID, setProjectType } from '../../storage/projectIdStorage'
import { Loader } from '../../ui_toolkit/Loader/Loader'

function ReportTable() {
  setProjectID(1009)
  setProjectType('endurance')
  const { reportType } = useParams<TableRouteParams>()
  const {
    setSearchColumns,
    setBreadcrumbs,
    setSelectedRowIds,
    headers,
    isLoadingHeaders,
    breadcrumbs,
    queryBuilderRef,
    preserveState,
    setPreserveState,
  } = useReportContext()

  const location = useLocation()
  const isKpi = location.pathname.includes('kpi')
  const {
    data: tableData,
    isFetching: isFetchingData,
    isError: isErrorData,
    refetch: refreshTable,
  } = useTableQuery()

  const {
    data: KPITableData,
    isFetching: isFetchingKPI,
    isError: isErrorKPI,
  } = useKpiTableQuery()

  useEffect(() => {
    setSelectedRowIds(new Set())
    setBreadcrumbs(INIT_BREADCRUMBS)
  }, [reportType])

  useEffect(() => {
    if (!isKpi) refreshTable()
    queryBuilderRef.current?.resetQuery()
    const resetMetaFilters =
      preserveState[reportType!].searchType === 'sql'
        ? {
            filterState: INIT_FILTER_STATE,
            filterToSql: {},
          }
        : {}

    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        queryBuilderState: INIT_QUERY_BUILDER_STATE,
        searchType: 'filter',
        ...resetMetaFilters,
      },
    })
    setSelectedRowIds(new Set())
  }, [breadcrumbs])

  useEffect(() => {
    if (tableData?.data.searchColumns) {
      setSearchColumns(tableData.data.searchColumns)
    }
  }, [setSearchColumns, tableData])

  if (isFetchingData || isFetchingKPI || isLoadingHeaders) {
    return <Loader center text={`Loading ${capitalize(reportType)}`} />
  }

  if (isErrorData || isErrorKPI || !headers) {
    return (
      <StyledAlert variant='standard' severity='info'>
        {enUS.NO_DATA_TO_DISPLAY}
      </StyledAlert>
    )
  }

  return (
    <TableCreator
      tableData={tableData || KPITableData}
      reportType={reportType!}
    />
  )
}

export default ReportTable
