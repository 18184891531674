import { match } from 'ts-pattern'
import { gridOptions } from '../../ui_toolkit/GridSelectors/GridSelectors'

export const getDefaultGridOption = (clientID: string | null) => {
  if (!clientID) {
    return {
      value: [2, 1],
      name: '2/1',
    }
  }

  const layout = localStorage.getItem('detailsLayout')?.slice(1, -1)
  const layoutData = gridOptions.find((x) => x.name === layout)
  if (layoutData) return layoutData

  return match(clientID)
    .with('102', () => ({ value: [3, 3], name: '3/3' }))
    .otherwise(() => ({ value: [2, 1], name: '2/1' }))
}
