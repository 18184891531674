import React from 'react'
import Drawer from '@mui/material/Drawer'
import { capitalize } from '../../tslib/strings'

import './style.scss'

export type Anchor = 'top' | 'left' | 'bottom' | 'right'
interface IProps {
  open: boolean
  children: React.ReactElement[] | React.ReactElement
  anchor: Anchor
  offset?: number
  variant?: 'permanent' | 'persistent' | 'temporary'
}

export const DrawerMenu = ({
  children,
  open,
  anchor,
  variant,
  offset = 0,
}: IProps) => {
  return (
    <div
      className={`
            drawer-container 
            ${anchor}`}
    >
      <Drawer
        elevation={20}
        PaperProps={{
          sx: {
            overflowX: 'hidden',
            overflowY: 'auto',
            positon: 'fixed',
            backgroundColor: (theme) => theme.palette.secondary.main,
            width: anchor === 'left' || anchor === 'right' ? 265 : 'auto',
            height: anchor === 'top' || anchor === 'bottom' ? 350 : '100%',
            scrollbarColor: '#e5e5e5',
            '&::-webkit-scrollbar': { width: '8px' },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#e5e5e533',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#e5e5e5',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#fff',
            },
            [`margin${capitalize(anchor)}`]: `${offset}px`,
            display: 'flex',
            alignContent: 'center',
            [anchor]: '64px',
            top: '48px',
            boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)',
          },
        }}
        anchor={anchor}
        open={open}
        variant={variant}
      >
        {children}
      </Drawer>
    </div>
  )
}
