import { DataFrame } from '../../../details/types'

const prepareLineData = (
  showThisPosition: boolean,
  positions: number[],
  x: number,
  y: number,
  c0: number,
  cs: number,
  ss: number
) => {
  if (showThisPosition) {
    const c0X = x + c0 * cs
    const c0Y = y - c0 * ss
    positions.push(c0Y, 0, c0X)
  }
}

export const drawData = (
  block_data: { [timestamp: number]: DataFrame } | undefined,
  setter: (position: number[], name: string) => void
) => {
  const vehiclePositions: number[] = []
  let leftC0Positions: number[] = []
  let rightC0Positions: number[] = []

  for (const i in block_data) {
    const x = block_data[+i].x
    const y = block_data[+i].y
    vehiclePositions.push(y, 0, x)

    const heading = block_data[+i].heading
    const leftc0 = -block_data[+i].leftC0GT
    const rightc0 = -block_data[+i].rightC0GT

    const leftConfidence = block_data[+i].leftConfidenceGT
    const rightConfidence = block_data[+i].rightConfidenceGT

    const showLeftPoint = leftConfidence > 0.49
    const showRightPoint = rightConfidence > 0.49

    const cs = Math.cos(heading)
    const ss = Math.sin(heading)

    if (leftc0 !== 0) {
      prepareLineData(showLeftPoint, leftC0Positions, x, y, leftc0, cs, ss)
    }
    if (rightc0 !== 0) {
      prepareLineData(showRightPoint, rightC0Positions, x, y, rightc0, cs, ss)
    }

    if (leftConfidence === 0) {
      setter(leftC0Positions, 'left')
      leftC0Positions = []
    }

    if (rightConfidence === 0) {
      setter(rightC0Positions, 'right')
      rightC0Positions = []
    }
  }

  if (leftC0Positions.length > 0) {
    setter(leftC0Positions, 'left')
  }

  if (rightC0Positions.length > 0) {
    setter(rightC0Positions, 'right')
  }
}
