export const weatherOptions = [
  { value: 'Cloudy', text: 'Cloudy' },
  { value: 'Raining', text: 'Raining' },
  { value: 'Snowing', text: 'Snowing' },
  { value: 'Sunny', text: 'Sunny' },
]

export const roadTypeOptions = [
  { value: 'City', text: 'City' },
  { value: 'Highway', text: 'Highway' },
  { value: 'Rural', text: 'Rural' },
]

export const timeOfDayOptions = [
  { value: 'Day', text: 'Day' },
  { value: 'Night', text: 'Night' },
  { value: 'Twilight', text: 'Twilight' },
]

export const countryOptions = [
  { value: 'US', text: 'US' },
  { value: 'DE', text: 'DE' },
  { value: 'CZ', text: 'CZ' },
  { value: 'IE', text: 'IE' },
]

export const cameraOptions = [
  { value: 'all', text: 'All cameras' },
  { value: 'fv', text: 'Front camera' },
  { value: 'mvr', text: 'Right camera' },
  { value: 'rv', text: 'Rear camera' },
  { value: 'mvl', text: 'Left camera' },
]
