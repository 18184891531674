import { useContext, useEffect, useRef, useState } from 'react'
import { IdType } from 'otto-vis-timeline'
import { useParams } from 'react-router-dom'
import { createHighlightGainStyle } from './colors'
import { addNotationToGroup, addTagToGroup } from './TimelineTags/handlers'
import { TimelineItemData, VideoTimelineFetcherProps } from './types'
import {
  addInitialGroup,
  calculateStartEndTime,
  createBackground,
  createExclusionBackground,
  createTimelineError,
  useUpdateLoadedStatus,
} from './utils'
import {
  getNameFromTagType,
  useEditTagMutation,
  useKpiHighlightsQueries,
  useTagsQueries,
} from '../../api'
import { useExclusionsQuery } from '../../api/exclusions'
import { useDriveTrialContext, useTimelineContext } from '../../details'
import { getFrameRate } from '../../details/config'
import { MediaSyncContext } from '../../details/types'
import { getProjectType } from '../../storage/projectIdStorage'
import { notEmpty } from '../../tslib/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { TimedData } from '../Canvas/CanvasBuffer'

export function KpiTimelineDataFetcher({
  synchronizer,
  viewportId,
  onDataReceived,
}: VideoTimelineFetcherProps) {
  const { reportType } = useParams()
  const { totalDuration } = useContext(MediaSyncContext)
  const timelineContext = useTimelineContext()
  const {
    redirectData,
    driveTrials,
    getDriveTrialByKey,
    getDriveTrialByHilKey,
    highlightMode,
    setHighlightMode,
  } = useDriveTrialContext()
  const groupItemsFetched = useRef<Record<string, boolean>>({})
  const updateLoadedStatus = useUpdateLoadedStatus(viewportId, synchronizer)
  const { editTagMutation } = useEditTagMutation()
  const columns = redirectData?.columns
  const projectType = getProjectType()
  const frameRate = getFrameRate(projectType)
  const queries = useKpiHighlightsQueries()
  const { data: exclusions } = useExclusionsQuery()
  const tags = useTagsQueries()
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const allColumns =
    columns?.flatMap((x) => x.items.map((y) => `${x.kpi}-${y}`)) || []
  const shouldWaitForExclusions =
    location.pathname.toLocaleLowerCase() === '/lanes/kpi-details' ||
    location.pathname.includes('/details')

  useEffect(() => {
    const dataIsReady =
      queries && tags && (!shouldWaitForExclusions || exclusions)

    if (dataIsReady) {
      setDataReceived(true)
      onDataReceived()
    }
  }, [queries, exclusions, tags])

  useEffect(() => {
    if (columns) {
      columns.forEach(({ items, kpi }, index) => {
        items.forEach((name) => {
          addInitialGroup(index, name, timelineContext, undefined, kpi)
        })
        addInitialGroup(index, kpi, timelineContext, {
          nestedGroups: items.map(
            (group) => allColumns.indexOf(`${kpi}-${group}`) + index
          ),
        })

        // here we add one placeholder timeline item in each group
        // to prevent nested groups from losing height if they have no items.
        const timelinePlaceholders: TimelineItemData[] = []

        driveTrials && createBackground(timelinePlaceholders, driveTrials)

        timelineContext.groups.forEach((g) => {
          timelinePlaceholders.push({
            isItem: false,
            start: 0,
            end: 100,
            content: '',
            id: g.id + 'placeholder',
            selectable: false,
            group: g.id,
            style: 'height: 24px; visibility: hidden; pointer-events: none;',
            type: 'range',
          })
        })

        timelineContext.items.update(timelinePlaceholders)
        timelineContext.initialItems.update(timelinePlaceholders)
      })
    }

    // on first render set initial `timelineContext` state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    queries.forEach(
      ({ kpiData, loaderKey, isFetched, isError, statusCode }) => {
        if (
          !groupItemsFetched.current[loaderKey] &&
          isFetched &&
          kpiData?.length
        ) {
          const timelineItems: TimelineItemData[] = []

          kpiData
            .sort(
              (a, b) =>
                b.timelineEndTime -
                b.timelineStartTime -
                (a.timelineEndTime - a.timelineStartTime)
            )
            ?.forEach((highlight, index) => {
              const timeData = calculateStartEndTime(
                driveTrials,
                highlight.dtid!,
                highlight
              )
              const groupId = timelineContext.groups
                .map((x) =>
                  x.value
                    .toLowerCase()
                    .includes(
                      highlight
                        .highlightType!.toLowerCase()
                        .replace('adjacent', 'adj')
                    )
                    ? x.id
                    : undefined
                )
                .filter(notEmpty)[0]

              const title = `(${highlight.timelineStartTime} - ${highlight.timelineEndTime}): ${highlight.comment}`

              const startInSeconds = new Date(timeData.start).getTime() / 1000
              const endInSeconds = new Date(timeData.end).getTime() / 1000

              timelineItems.push({
                ...timeData,
                isItem: true,
                content: '',
                id: highlight.hlid.toString(),
                selectable: true,
                group: groupId,
                title: title,
                type: 'range',
                style:
                  createHighlightGainStyle(highlight.gain) +
                  `z-index: ${index};`,
                gain: highlight.gain,
                dtid: highlight.dtid,
                className: highlight.highlightType!.includes('SPR')
                  ? 'vertical-line'
                  : `${Math.floor(endInSeconds - startInSeconds)}s`,
              })
            })

          timelineContext.items.update(timelineItems)
          timelineContext.initialItems.update(timelineItems)
          groupItemsFetched.current[loaderKey!] = true
        }

        if (isFetched) {
          groupItemsFetched.current[loaderKey] = true
        }

        if (isFetched && isError && statusCode !== 404) {
          const errorItem = createTimelineError(
            loaderKey,
            totalDuration,
            timelineContext.items.get(loaderKey)?.group
          )
          timelineContext.items.update([errorItem])
        }
      }
    )
    updateLoadedStatus()
    // update of the timeline context should happen
    // when fetching state of `queries` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries])

  useEffect(() => {
    if (!exclusions) return
    const timelinePlaceholders: TimelineItemData[] = []
    const exclusionGroups = timelineContext.groups
      .get({ filter: (x) => !x.nestedGroups })
      .map((x) => x.id as number)

    driveTrials?.length &&
      createExclusionBackground(
        timelinePlaceholders,
        driveTrials,
        exclusions,
        frameRate,
        exclusionGroups
      )

    timelineContext.items.update(timelinePlaceholders)
    timelineContext.initialItems.update(timelinePlaceholders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusions, driveTrials])

  // add tags to the group
  useEffect(() => {
    const tagsData: Record<IdType, TimedData<boolean>> = {}
    tags.forEach(({ tags, dtid }) =>
      tags?.forEach((tag) => {
        const groupID = timelineContext.groups
          .map((group) => group)
          .filter((grp) => !grp.nestedGroups)
          .find(
            (g) =>
              `${reportType}|${g.value.replace('-', '|')}` === tag.description
          )!.id
        if (!tag.endTimestamp) {
          addTagToGroup(
            tag.startTimestamp,
            groupID,
            timelineContext,
            highlightMode,
            setHighlightMode,
            tag,
            dtid,
            getDriveTrialByHilKey(dtid)
          )
        } else {
          addNotationToGroup(
            tag.startTimestamp,
            tag.endTimestamp,
            groupID,
            timelineContext,
            highlightMode,
            tag,
            dtid,
            getDriveTrialByKey(dtid),
            editTagMutation
          )
        }
        const id = getNameFromTagType(groupID)

        const timelineRowId = timelineContext.groups
          .get({ returnType: 'Array' })
          .findIndex((x) => (x.content as string).includes(id!))

        if (!tagsData[timelineRowId]) tagsData[timelineRowId] = {}
        tagsData[timelineRowId][tag.startTimestamp] = true
      })
    )

    timelineContext.setTags(tagsData)
    // tags should be added to context only on fetching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  return !dataReceived ? (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loader text='Receiving KPI data...' scale={1.5} />
    </div>
  ) : null
}
