import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { DriveTrialDataRedirect } from '../details/types'
import { FilterObject } from '../models/table'

export interface VisibleColumns {
  kpi: string
  items: string[]
}

export interface RedirectData {
  filters?: FilterObject
  columns?: VisibleColumns[]
  rows: DriveTrialDataRedirect[]
}

export const getSegments = (data: RedirectData | undefined): number[] =>
  data?.rows?.reduce((acc, i) => acc.concat(i.segmentIDs), [] as number[]) ?? []

export const getDriveTrials = (data: RedirectData | undefined) =>
  data?.rows?.map((x) => x.videoKey) || []

export const getUniqueDTParentKeys = (data: RedirectData | undefined) => [
  ...new Set(data?.rows?.map((x) => x.parentKey) || []),
]

export const getParentKeys = (data: RedirectData | undefined): number[] =>
  data?.rows?.map((x) => x.parentKey) || []

export const getDriveTrialSegmentIDs = (
  data: DriveTrialDataRedirect[],
  dtid: number
): number[] => data.find((x) => x.videoKey === dtid)?.segmentIDs || []

export const useRedirectData = (guid: string) =>
  useQuery({
    queryKey: ['guid', guid],
    queryFn: async (): Promise<RedirectData> => {
      const redirectUrl = `${urls.redirectionData}/${guid}`
      const res = (await axios.get<RedirectData>(redirectUrl))?.data
      return res
    },
  })
