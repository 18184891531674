import { createContext, useContext, useState } from 'react'
import { Divider } from '@mui/material'
import { Navigate } from 'react-router-dom'
import GoogleLogin from '../../components/GoogleLogin/GoogleLogin'
import { LoginForm } from '../../components/LoginForm/LoginForm'
import MicrosoftLogin from '../../components/MicrosoftLogin/MicrosoftLogin'
import { Routes } from '../../constants'
import { useAuthContext } from '../../security'
import './style.scss'

interface LoginContextProps {
  isAuthenticating: boolean
  setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginContext = createContext<LoginContextProps>({} as LoginContextProps)
export const useLoginContext = () => useContext(LoginContext)

function LoginComponent() {
  const { isAuthenticated } = useAuthContext()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const schemas = process.env.REACT_APP_LOGIN_SCHEMAS?.split('|') || []
  const hasLoginForm = schemas.includes('login_password')

  // prevent hitting the login page when authenticated
  if (isAuthenticated) {
    return (
      <Navigate
        to={`${Routes.REPORTING}/${Routes.DASHBOARD}/Dashboard`}
        replace={true}
      />
    )
  }

  return (
    <LoginContext.Provider
      value={{
        isAuthenticating,
        setIsAuthenticating,
      }}
    >
      <div className='splash-screen-container'>
        <div
          className={
            schemas.length > 1 ? 'form-container' : 'no-form-container'
          }
        >
          {hasLoginForm && <LoginForm />}
          {hasLoginForm && schemas.length > 1 && (
            <Divider
              sx={{
                margin: '24px 0',
                borderColor: '#e5e5e5B3',
              }}
            />
          )}
          {schemas.includes('google') && <GoogleLogin />}
          {schemas.includes('microsoft') && <MicrosoftLogin />}
        </div>
      </div>
    </LoginContext.Provider>
  )
}

export default LoginComponent
