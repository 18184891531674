import { useCallback, useContext, useEffect } from 'react'
import { camelCase } from 'lodash'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCanvasObjectType, getVisibleCanvases } from './utils'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { LoadingContext } from '../../details'
import {
  Dimensions,
  EnabledLayers,
  MediaSyncContext,
  ObjectColor,
  SourceType,
} from '../../details/types'
import { RowKey } from '../../models/table'
import { ReportType } from '../../pages/Report/types'
import { selectViewportLayout } from '../../store/details/viewport/selectors'
import { selectViewportState } from '../../store/details/viewportData/selectors'
import { notEmpty } from '../../tslib/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { Canvas } from '../Canvas/Canvas'
import '../Canvas/canvas.scss'

interface CanvasLayersProps {
  videoId: number
  playerId: string
  videoKey: RowKey
  hilKey: RowKey
  colors: ObjectColor[]
  canvasSize: Dimensions
  viewportId: number
  synchronizer: ISynchronizer | undefined
}

export function CanvasLayers({
  videoId,
  videoKey,
  hilKey,
  colors,
  canvasSize,
  viewportId,
  synchronizer,
}: CanvasLayersProps) {
  const { reportType } = useParams()
  const viewportContent = useSelector(selectViewportLayout(viewportId))
  const mediaSyncContext = useContext(MediaSyncContext)
  const { clearUnusedCanvases } = useContext(LoadingContext)

  const enabledLayers = useSelector(
    selectViewportState(viewportId)
  ) as EnabledLayers

  const isMediaSyncReady =
    Object.values(mediaSyncContext).filter(notEmpty).length === 0

  useEffect(() => {
    const data = [
      ...enabledLayers.me.map((x) => `${getCanvasObjectType(x)}-me`),
      ...enabledLayers.otto.map((x) => `${getCanvasObjectType(x)}-otto`),
    ]
    synchronizer?.clearUnusedCanvases(viewportContent, data)
    clearUnusedCanvases(data)

    //needs to be triggered only on change of enabledLayers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledLayers])

  const renderCanvases = useCallback(
    (objects: string[], source: SourceType) => {
      const canvases = getVisibleCanvases(
        objects,
        colors,
        source,
        reportType as ReportType
      )

      return canvases.map((obj) => {
        const dataTestId = `layer-${source}-${camelCase(
          obj.name
        )}-${viewportId}-canvas`

        return (
          <Canvas
            key={`canvas-${obj.name}-${videoId}-${viewportContent}`}
            videoId={videoId}
            dataTestId={dataTestId}
            size={canvasSize}
            videoKey={videoKey}
            hilKey={hilKey}
            colors={colors}
            sourceType={source}
            objectType={obj.name}
            camera={viewportContent}
            synchronizer={synchronizer}
            viewportId={viewportId}
            lineType={
              obj.name === 'egoLane' || obj.name === 'egoLabel'
                ? 'EGO'
                : obj.name === 'adjacentLane' || obj.name === 'adjacentLabel'
                ? 'ADJACENT'
                : undefined
            }
          />
        )
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewportContent, videoKey, hilKey, videoId, canvasSize, colors, viewportId]
  )

  return isMediaSyncReady ? (
    <Loader />
  ) : (
    <>
      {renderCanvases(enabledLayers?.me, 'me')}
      {renderCanvases(enabledLayers?.otto, 'otto')}
    </>
  )
}
