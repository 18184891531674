import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react'
import { useDriveTrialContext } from './DriveTrialDataProvider'
import {
  DisplayValue,
  DoubleBuffer,
  DoubleBufferContext,
  DriveTrial,
  SamplerReference,
} from './types'
import { MediaSyncContext } from '../../details/types'

export const DoubleBufferProvider = ({ children }: PropsWithChildren) => {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, getDriveTrialById } = useDriveTrialContext()
  const doubleBufferRef = useRef<DoubleBuffer>(null)
  const firstObjectRef = useRef<HTMLDivElement>(null)
  const secondObjectRef = useRef<HTMLDivElement>(null)

  const switchBuffer = useCallback(
    (
      activeDriveIndex: number,
      visibleBuffer: HTMLDivElement | null,
      hiddenBuffer: HTMLDivElement | null,
      setBufferData:
        | Dispatch<SetStateAction<DriveTrial | undefined>>
        | undefined
    ) => {
      if (visibleBuffer && hiddenBuffer) {
        visibleBuffer.style.visibility = DisplayValue.VISIBLE
        hiddenBuffer.style.visibility = DisplayValue.HIDDEN

        const nextId = activeDriveIndex + 1
        hiddenBuffer.id = nextId.toString()

        setBufferData?.(getDriveTrialById(nextId))
      }
    },
    [getDriveTrialById]
  )

  const reloadBuffers = useCallback(
    (activeDriveIndex: number) => {
      const visibleBufferKey = activeDriveIndex
      const hiddenBufferKey = activeDriveIndex + 1

      if (firstObjectRef.current && secondObjectRef.current) {
        const isFirstBufferVisible =
          firstObjectRef?.current?.style.visibility === DisplayValue.VISIBLE

        const firstId = isFirstBufferVisible
          ? visibleBufferKey
          : hiddenBufferKey
        const secondId = !isFirstBufferVisible
          ? visibleBufferKey
          : hiddenBufferKey
        firstObjectRef.current.id = firstId.toString()
        secondObjectRef.current.id = secondId.toString()

        doubleBufferRef.current?.setBufferOne?.(getDriveTrialById(+firstId))
        doubleBufferRef.current?.setBufferTwo?.(getDriveTrialById(+secondId))
      }
    },
    [getDriveTrialById]
  )

  useEffect(() => {
    let samplerEvent: SamplerReference | null = null

    if (mediaSyncContext.sampler !== undefined) {
      // @ts-expect-error Missing correct INTERFACE
      samplerEvent = mediaSyncContext.sampler.on('change', (time: number) => {
        const activeTrial = getCurrentDriveTrial(time)
        const activeDriveKey = activeTrial?.id

        const refWithActive = [firstObjectRef, secondObjectRef].find(
          (x) => x?.current?.id === activeDriveKey?.toString()
        )

        const isActiveVideoHidden =
          refWithActive?.current?.style?.visibility === DisplayValue.HIDDEN

        if (
          firstObjectRef?.current &&
          secondObjectRef?.current &&
          (!refWithActive || isActiveVideoHidden)
        ) {
          const activeDriveIndex = activeTrial!.id

          const firstKey = parseInt(firstObjectRef.current?.id ?? '')
          const secondKey = parseInt(secondObjectRef.current?.id ?? '')

          if (firstKey === activeDriveKey) {
            switchBuffer(
              activeDriveIndex,
              firstObjectRef?.current,
              secondObjectRef?.current,
              doubleBufferRef.current?.setBufferTwo
            )
          } else if (secondKey === activeDriveKey) {
            switchBuffer(
              activeDriveIndex,
              secondObjectRef?.current,
              firstObjectRef?.current,
              doubleBufferRef.current?.setBufferOne
            )
          } else {
            reloadBuffers(activeDriveIndex)
          }
        }
      })
    }

    return () => {
      samplerEvent?.terminate()
    }
  }, [
    mediaSyncContext,
    mediaSyncContext?.sampler,
    getCurrentDriveTrial,
    switchBuffer,
    reloadBuffers,
  ])

  return (
    <DoubleBufferContext.Provider
      value={{
        doubleBufferRef,
        firstRef: firstObjectRef,
        secondRef: secondObjectRef,
      }}
    >
      {children}
    </DoubleBufferContext.Provider>
  )
}
