import { MicrosoftLoginButton } from 'react-social-login-buttons'
import { useLoginContext } from '../../pages/Login/Login'
import { useHandleMicrosoftLogin } from '../../security'

function MicrosoftLogin() {
  const { isAuthenticating } = useLoginContext()
  const handleMicrosoftLogin = useHandleMicrosoftLogin()

  return (
    <MicrosoftLoginButton
      disabled={isAuthenticating}
      onClick={handleMicrosoftLogin}
      align='center'
      className='social-login-button'
    />
  )
}

export default MicrosoftLogin
