import { match } from 'ts-pattern'
import { ViewportContent } from './types'
import { ProjectType } from '../models/project'
import { ReportType } from '../pages/Report/types'

export enum CameraPosition {
  FRONT = 1,
  LEFT,
  RIGHT,
  REAR,
}

export const CLIENT_ID = {
  DEMO: 101,
  VALEO: 102,
  MAGNA: 100,
} as const

const viewportToCameraPosition = (content: ViewportContent) =>
  match(content)
    .with(ViewportContent.FRONT_CAMERA, () => CameraPosition.FRONT)
    .with(ViewportContent.LEFT_CAMERA, () => CameraPosition.LEFT)
    .with(ViewportContent.RIGHT_CAMERA, () => CameraPosition.RIGHT)
    .with(ViewportContent.REAR_CAMERA, () => CameraPosition.REAR)
    .otherwise(() => CameraPosition.FRONT)

export const getCameraURL = (
  camPosition: ViewportContent,
  project: ProjectType
) => {
  const cameraPosition = viewportToCameraPosition(camPosition)

  if (project === 'endurance' && cameraPosition !== CameraPosition.FRONT) {
    throw new Error('Unexpected value combination received for camera URL.')
  }

  const videoUrl = match(cameraPosition)
    .with(CameraPosition.FRONT, () => {
      return match(project)
        .with('endurance', () => 'undestorted_raw_white.mp4')
        .with('episodic', () => 'video_1_lossy.mp4')
        .exhaustive()
    })
    .otherwise(() => `video_${cameraPosition}_lossy.mp4`)

  return videoUrl
}

export const getFrameRate = (project: ProjectType) =>
  match(project)
    .with('endurance', () => 18)
    .with('episodic', () => 30)
    .exhaustive()

export const getCanvasLayers = (project: ReportType) =>
  match(project)
    .with('lanes', () => [
      'VEHICLELAYERS',
      'OTTOLAYERS',
      'EgoLane',
      'EgoClass&Color',
      'AdjacentLane',
      'AdjacentClass&Color',
    ])
    .with('lights', () => ['VEHICLELAYERS', 'OTTOLAYERS', 'TrafficLight'])
    .with('signs', () => ['VEHICLELAYERS', 'OTTOLAYERS', 'Sign'])
    .otherwise(() => [
      'VEHICLELAYERS',
      'OTTOLAYERS',
      'Car',
      'Bus',
      'Truck',
      'Motorcycle',
      'Sign',
      'TrafficLight',
      'Pedestrian',
      'Cycle',
      'EgoLane',
      'EgoClass&Color',
      'AdjacentLane',
      'AdjacentClass&Color',
    ])
