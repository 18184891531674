import { ChangeEvent, FormEvent, useState } from 'react'
import {
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
} from '@mui/material'
import { LoginRequest } from '../../api'
import { useLoginContext } from '../../pages/Login/Login'
import { useOttoLogin } from '../../security'
import Password from '../../ui_toolkit/Inputs/Password/Password'
import { Loader } from '../../ui_toolkit/Loader/Loader'

const initialFormData: LoginRequest = {
  email: '',
  password: '',
}

export const LoginForm = () => {
  const [formData, setFormData] = useState(initialFormData)
  const [showHint, setShowHint] = useState(false)
  const { isAuthenticating } = useLoginContext()
  const ottoLogin = useOttoLogin()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!formData.email || !formData.password) {
      setShowHint(true)
      return
    }

    ottoLogin(formData)
  }

  return (
    <form action='' method='POST' onSubmit={handleSubmit}>
      <Stack direction='column' spacing={1}>
        <FormControl variant='filled' fullWidth>
          <InputLabel
            sx={{
              '&.Mui-focused': { color: '#e5e5e5B3' },
              fontSize: '14px',
            }}
            htmlFor='Email'
          >
            Email
          </InputLabel>
          <FilledInput
            disabled={isAuthenticating}
            autoComplete='off'
            id='Email'
            name='email'
            sx={{
              color: 'whitesmoke !important',
              backgroundColor: 'rgba(0,0,0,0.25) !important',
            }}
            onChange={handleChange}
          />
          {showHint && !formData.email && (
            <FormHelperText sx={{ color: 'red' }}>
              * Please enter a valid email address.
            </FormHelperText>
          )}
        </FormControl>
        <Password
          isAuthenticating={isAuthenticating}
          onChange={handleChange}
          value={formData.password}
          showHint={showHint}
        />
        <Button
          disabled={isAuthenticating}
          sx={{ height: '40px' }}
          type='submit'
          variant='contained'
          color='info'
          fullWidth
        >
          {!isAuthenticating ? 'Login' : <Loader scale={0.5} />}
        </Button>
      </Stack>
    </form>
  )
}
