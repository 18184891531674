import { useContext, useEffect, useRef, useState } from 'react'
import { useIdleTimerContext } from 'react-idle-timer'
import ReactPlayer from 'react-player'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import {
  LoadingContext,
  VideoContext,
  useDriveTrialContext,
} from '../../details'
import { MediaSyncContext } from '../../details/types'
import MCorp from '../../timing/mcorp'
import { toggleClasses } from '../../utils'
import NoData from '../NoData/NoData'
import './videoPlayerReact.scss'

interface IProps {
  playbackRate: number
  onMediaLoaded?: (player: HTMLVideoElement, id: string) => void
  playerId: string
  isFullscreen: boolean
  viewportId: number
  synchronizer?: ISynchronizer | undefined
  videoId: number
}

interface MCorpApi {
  setSkew: () => void
  getSkew: () => void
  setOption: () => void
  getMethod: () => void
  setMotion: () => void
  stop: () => void
  pause: (elem: HTMLVideoElement) => void
  on: () => void
  off: () => void
  init: () => void
}

const fullScreenOff = 'video-init'
const fullScreenOn = 'video-fsc-horizontal-stretch'

const VideoPlayerReact = ({
  playbackRate,
  onMediaLoaded,
  playerId,
  isFullscreen,
  synchronizer,
  viewportId,
  videoId,
}: IProps) => {
  // We have to distinguish between pause/play events in the `onReady` handler. This code block is necessary to do so.
  const { url } = useContext(VideoContext)
  const { isVideoLoaded, setIsVideoLoaded, setIsBuffering } =
    useContext(LoadingContext)
  const [hasFailed, setHasFailed] = useState(false)
  const { getCurrentDriveTrial, isConcatenation } = useDriveTrialContext()
  const mediaSync = useContext(MediaSyncContext)
  const msyncRef = useRef<MCorpApi | null>(null)
  const selfRef = useRef<ReactPlayer>(null)
  const { reset } = useIdleTimerContext()

  const isVideoActive = () => {
    const activeId = getCurrentDriveTrial(
      mediaSync?.time?.query()?.position
    )?.id
    return activeId === videoId
  }

  const handleSeeked = () => {
    if (mediaSync?.time?.query().velocity !== 0) return
    const time: number = mediaSync.time?.query().position
    synchronizer?.triggerDraw(time, viewportId, videoId)
  }

  useEffect(() => {
    if (!isVideoLoaded) return
    const videoStateInterval = setInterval(() => {
      const player = selfRef.current?.getInternalPlayer() as HTMLVideoElement
      if (!player || !isVideoActive()) return

      if (msyncRef.current === null) {
        const player = selfRef.current?.getInternalPlayer()
        msyncRef.current = MCorp.mediaSync(player, mediaSync.delay)
      }

      const readyStates = [3, 4]
      if (readyStates.includes(player?.readyState)) {
        synchronizer?.updateStatus(viewportId, true)
        setIsBuffering(false)
      } else {
        synchronizer?.updateStatus(viewportId, false)
        setIsBuffering(true)
      }
    }, 100)

    const player = selfRef.current?.getInternalPlayer() as HTMLVideoElement
    player?.addEventListener('seeking', handleSeeked)

    return () => {
      if (
        videoId < mediaSync.activeVideoId! &&
        msyncRef.current &&
        isConcatenation()
      ) {
        msyncRef.current.pause(player)
        msyncRef.current = null

        player?.removeEventListener('seeking', handleSeeked)
        clearInterval(videoStateInterval)
      }
    }

    // Should be triggered only once when video becomes available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoLoaded, videoId])

  const onResize = () => {
    if (onMediaLoaded) {
      onMediaLoaded(
        selfRef.current?.getInternalPlayer() as HTMLVideoElement,
        playerId
      )
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  if (!url || hasFailed) <NoData languageCode='enUS' />

  return (
    <ReactPlayer
      className={'player-container'}
      style={{ paddingRight: '40px' }}
      url={url}
      playbackRate={playbackRate}
      muted
      onReady={(player) => {
        if (isVideoLoaded) {
          return
        }

        setIsVideoLoaded(true)

        const internalPlayer = player.getInternalPlayer() as HTMLVideoElement

        if (isFullscreen) {
          internalPlayer.style.all = 'revert'
          toggleClasses(internalPlayer, [fullScreenOff], [fullScreenOn])
        } else {
          internalPlayer.style.all = 'revert'
          toggleClasses(internalPlayer, [fullScreenOn], [fullScreenOff])
        }

        if (onMediaLoaded) {
          onMediaLoaded(
            player.getInternalPlayer() as HTMLVideoElement,
            playerId
          )
        }
      }}
      width='100%'
      height='auto'
      onError={(err) => {
        console.error('Error loading video ' + playerId + ': ', err)
        setHasFailed(true)
        synchronizer?.updateStatus(viewportId, true)
      }}
      ref={selfRef}
      onProgress={reset}
    />
  )
}

export default VideoPlayerReact
