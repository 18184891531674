import { PropsWithChildren } from 'react'
import { IdleTimerProvider } from 'react-idle-timer'
import { useOnIdle } from '../logout'

const DEFAULT_IDLE_TIMEOUT = 1000 * 60 * 30

function IdleTimerContext({ children }: PropsWithChildren) {
  const onIdle = useOnIdle()

  return (
    <IdleTimerProvider
      crossTab={true}
      timeout={DEFAULT_IDLE_TIMEOUT}
      onIdle={onIdle}
    >
      {children}
    </IdleTimerProvider>
  )
}

export default IdleTimerContext
