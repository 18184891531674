import { SyncData } from './synchronizer'

export const initializeViewportSync = (
  layerValue: number[],
  defaultValue = false
) => {
  const total = layerValue[0] + layerValue[1]
  return Array.from({ length: 7 }, (_, index) => {
    return [index + 1, index < total ? defaultValue : undefined] as [
      number,
      boolean | undefined
    ]
  }).reduce((obj, [key, value]) => {
    obj[key] = value
    return obj
  }, {} as SyncData)
}
