import { Property } from 'csstype'
import { match } from 'ts-pattern'
import { ColorKeys } from '../../constants'
import { LanePoint, ObjectColor, Point, SourceType } from '../types'

export const getLayerAlias = (objType: string) => {
  switch (objType) {
    case 'egoLane':
    case 'egoLabel':
      return 'ActiveEgoLineColor'
    case 'adjacentLane':
    case 'adjacentLabel':
      return 'ActiveAdjacentLaneColor'
    case 'sign':
      return 'ActiveSignColor'
    case 'car':
      return 'ActiveCarColor'
    case 'bus':
      return 'ActiveBusColor'
    case 'pedestrian':
      return 'ActivePedColor'
    case 'bicycle':
      return 'ActiveCycleColor'
    case 'truck':
      return 'ActiveTruckColor'
    case 'motorcycle':
      return 'ActiveMotorcycleColor'
    case 'trafficLight':
      return 'ActiveTrafficLightColor'
    default:
      return 'ActiveCarColor'
  }
}

export const findColor = (
  key: ColorKeys,
  colors: ObjectColor[],
  sourceType: SourceType
): Property.Color => {
  if (!colors) {
    return 'black'
  }

  const source = match(sourceType)
    .with('me', () => 'Mobileye')
    .with('otto', () => 'Ottometric')
    .exhaustive()

  const color = colors.find(
    (x) => x.name === key && x.source === source
  )?.layerColor

  if (!color) {
    return '#000000'
  }

  return `#${color}`
}

const commonRectRadius = 1

export const renderText = (
  ctx: OffscreenCanvasRenderingContext2D,
  rectX: number,
  rectY: number,
  textX: number,
  textY: number,
  fillStyle: string,
  text: string,
  color: string,
  height: number
) => {
  const textWidth = ctx.measureText(text.toLowerCase()).width
  const fontSize = Math.max(Math.floor(height * 0.025) | 0)

  ctx.beginPath()
  ctx.font = fontSize + 'px Helvetica'
  ctx.fillStyle = color
  ctx.roundRect(rectX, rectY, textWidth + 6, fontSize + 4, commonRectRadius)
  ctx.fill()

  ctx.beginPath()
  ctx.fillStyle = fillStyle
  ctx.fillText(text.toLowerCase(), textX, textY)
}

const resolutionDivider = 4000

const createPointWithConfidence = (
  point: LanePoint,
  width: number,
  height: number
): Point | undefined => {
  const x = Math.round((point[0] / resolutionDivider) * width),
    y = Math.round((point[1] / resolutionDivider) * height)

  return {
    confidence: point[2],
    x,
    y,
  }
}

export const fillPointsArray = (
  points: LanePoint[],
  width: number,
  height: number
): Point[] => {
  const path: Point[] = []

  points.forEach((p) => {
    const point = createPointWithConfidence(p, width, height)
    if (point) {
      path.push(point)
    }
  })

  return path
}
