import {
  IdType,
  Timeline,
  TimelineGroup,
  TimelineOptions,
} from 'otto-vis-timeline'
import { DataSet } from 'vis-data'
import {
  TimelineEventData,
  TimelineItemData,
} from '../../components/VideoTimeline/types'
import type {
  TimelineAnimationOptions,
  TimelineEvents,
  DateType,
} from 'otto-vis-timeline/types'

export type TimelineEventsWithMissing =
  | TimelineEvents
  | 'dragover'
  | 'markerchange'
  | 'markerchanged'

export type TimelineEventHandler =
  | 'currentTimeTickHandler'
  | 'clickHandler'
  | 'contextmenuHandler'
  | 'doubleClickHandler'
  | 'dragoverHandler'
  | 'dropHandler'
  | 'mouseOverHandler'
  | 'mouseDownHandler'
  | 'mouseUpHandler'
  | 'mouseMoveHandler'
  | 'groupDraggedHandler'
  | 'changedHandler'
  | 'rangechangeHandler'
  | 'rangechangedHandler'
  | 'selectHandler'
  | 'itemoverHandler'
  | 'itemoutHandler'
  | 'timechangeHandler'
  | 'timechangedHandler'
  | 'markerchangeHandler'
  | 'markerchangedHandler'

export type TimelineEventsHandlers = Partial<
  Record<TimelineEventHandler, (props: TimelineEventData) => void>
>

export interface CustomTime {
  datetime: Date
  id: string
}

export interface SelectionOptions {
  focus?: boolean
  animation?: TimelineAnimationOptions
}

export const events: TimelineEventsWithMissing[] = [
  'currentTimeTick',
  'click',
  'contextmenu',
  'doubleClick',
  'dragover',
  'drop',
  'mouseOver',
  'mouseDown',
  'mouseUp',
  'mouseMove',
  'groupDragged',
  'changed',
  'rangechange',
  'rangechanged',
  'select',
  'itemover',
  'itemout',
  'timechange',
  'timechanged',
  'markerchange',
  'markerchanged',
]

export const eventDefaultProps: TimelineEventsHandlers = {}

events.forEach((event) => {
  eventDefaultProps[`${event}Handler`] = undefined
})

export interface TimelineProps extends TimelineEventsHandlers {
  initialItems?: DataSet<TimelineItemData>
  initialGroups?: DataSet<TimelineGroup>
  options?: TimelineOptions
  selection?: IdType[]
  customTimes?: CustomTime[]
  selectionOptions?: SelectionOptions
  animate?: boolean | object
  currentTime?: DateType
  controlledTime?: CustomTime
  slideOffsetPercent: number // Number in percentages which tells us when to slide the marker. Percentage means how close the marker is to the edge. (100% means all the way to the edge)
  onTimeClick?: (time: number) => void
  onTimelineInit?: (timeline: Timeline) => void
  onGroupClick?: (id: number | undefined) => void
}
