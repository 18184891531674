import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { P, match } from 'ts-pattern'
import { urls } from './urls'
import { TimedData } from '../components/Canvas/CanvasBuffer'
import { LineType, ObjectType, SourceType } from '../details/types'

const getObjectUrl = (objectType: ObjectType) =>
  match(objectType)
    .with(
      P.union('bicycle', 'car', 'bus', 'pedestrian', 'truck', 'motorcycle'),
      () => urls.objects
    )
    .with(
      P.union('egoLane', 'egoLabel', 'adjacentLane', 'adjacentLabel'),
      () => urls.roadLanes
    )
    .with('sign', () => urls.trafficSigns)
    .with('trafficLight', () => urls.trafficLights)
    .exhaustive()

export function useObjectsQuery<T>(
  enabled: boolean,
  dtid: number,
  objectType: ObjectType,
  sourceType: SourceType,
  camera: number,
  startTime: number,
  endTime: number,
  lineType?: LineType
) {
  if (objectType === 'egoLabel') objectType = 'egoLane'
  if (objectType === 'adjacentLabel') objectType = 'adjacentLane'
  return useQuery({
    queryKey: [
      'objects',
      dtid,
      objectType,
      sourceType,
      camera,
      startTime,
      endTime,
      lineType,
    ],
    staleTime: Infinity,
    enabled,
    queryFn: async (): Promise<TimedData<T>> => {
      const { data } = await axios.get(getObjectUrl(objectType), {
        params: {
          startTime,
          endTime,
          dtid,
          objectType,
          camera,
          lineType,
          source: sourceType,
        },
      })

      return data || {}
    },
  })
}
