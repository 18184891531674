import { RefObject, forwardRef, useContext, useEffect, useRef } from 'react'
import { Table, TableBody, TableHead } from '@mui/material'
import { keysOrder, columnNames, ObjectTableData } from './types'
import {
  layerColorsData,
  layersColorsDataDic,
} from '../../constants/layerColorsData'
import {
  SamplerReference,
  useDriveTrialContext,
  useTimelineContext,
} from '../../details'
import { getFrameRate } from '../../details/config'
import { MediaSyncContext } from '../../details/types'
import {
  StyledTableHeaderCell,
  StyledTableHeaderRow,
} from '../../pages/Report/components/StyledComponentsTable'
import PopupOverlay from '../../ui_toolkit/PopupOverlay/PopupOverlay'
import './style.scss'

interface IProps {
  closeHandler: () => void
  popupPosition: string
  showObjectsTable?: boolean
  objectsTableBtnSpawner?: RefObject<SVGSVGElement | null> | null
}

export const ObjectsTable = forwardRef(function ObjectsTable(
  props: IProps,
  ref
) {
  const {
    closeHandler,
    popupPosition,
    objectsTableBtnSpawner,
    showObjectsTable,
  } = props
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const mediaSync = useContext(MediaSyncContext)
  const { highlightMode } = useDriveTrialContext()
  const { objectsData } = useTimelineContext()

  useEffect(() => {
    const updateTableWithData = (time: number) => {
      if (highlightMode.id === -1) {
        const frame = Math.floor(time * getFrameRate('endurance'))
        updateTable(objectsData[frame])
      }

      const found = highlightMode.items
        .get()
        .find(
          (x) =>
            x.isItem &&
            time * 1000 >= new Date(x.start).getTime() &&
            x.end &&
            time * 1000 <= new Date(x.end).getTime()
        )

      if (found?.originalStart) {
        const highlightFrame = Math.floor(
          (found.originalStart / 1000 +
            time -
            new Date(found.start).getTime() / 1000) *
            getFrameRate('endurance')
        )

        updateTable(objectsData[highlightFrame])
      }
    }

    let samplerEvent: SamplerReference | null = null

    if (!mediaSync.isPlaying && showObjectsTable) {
      const time = mediaSync.time?.query().position
      updateTableWithData(time)
    }

    if (mediaSync.sampler !== undefined) {
      // @ts-expect-error Missing correct INTERFACE
      samplerEvent = mediaSync.sampler.on('change', (time: number) => {
        updateTableWithData(time)
      })
    }

    return () => {
      samplerEvent?.terminate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsData, showObjectsTable, highlightMode.id])

  const updateTable = (newData: ObjectTableData) => {
    if (!tableBodyRef.current) return

    const tbody = tableBodyRef.current
    tbody.innerHTML = ''

    const data = newData?.data
    if (!data) return

    data.forEach((row) => {
      const source = row.sourceType === 'otto' ? 'Ottometric' : 'Mobileye'
      const colorKey =
        layersColorsDataDic[row.class as keyof typeof layersColorsDataDic]
      const color = layerColorsData.find(
        (x) => x.name === colorKey && x.source === source
      )?.layerColor
      const tr = document.createElement('tr')
      tr.style.borderBottom = '1px solid black'
      tr.style.backgroundColor = `#${color}`

      keysOrder.forEach((key) => {
        if (key in row) {
          const value =
            typeof row[key] === 'number' && key !== 'id'
              ? (row[key] as number).toFixed(2)
              : row[key]
          const td = document.createElement('td')
          td.style.textAlign =
            typeof row[key] === 'number' || !value ? 'right' : 'left'
          td.style.fontFamily = 'courier'
          td.style.paddingLeft = '8px'
          td.style.paddingRight = '8px'
          td.style.borderBottom = '1px solid #50504f'
          td.textContent = !value ? '-' : String(value)

          tr.appendChild(td)
        }
      })

      tbody.appendChild(tr)
    })
  }

  if (!objectsData) {
    return null
  }

  return (
    <PopupOverlay
      closeHandler={closeHandler}
      ref={ref}
      popupPosition={popupPosition}
      isResizable
      isDraggable
      btnSpawner={objectsTableBtnSpawner}
      popupName='objectsTable'
    >
      <div className='objects-table-container handle'>Objects table</div>
      <Table
        size='small'
        aria-label='simple table'
        sx={{
          marginTop: '32px',
          borderCollapse: 'separate',
        }}
      >
        <TableHead style={{ position: 'sticky', top: 32, zIndex: 2 }}>
          <StyledTableHeaderRow>
            {keysOrder.map((col) => (
              <StyledTableHeaderCell
                align={col === 'class' ? 'left' : 'right'}
                key={col}
              >
                {columnNames[col]}
              </StyledTableHeaderCell>
            ))}
          </StyledTableHeaderRow>
        </TableHead>
        <TableBody ref={tableBodyRef}></TableBody>
      </Table>
    </PopupOverlay>
  )
})

export default ObjectsTable
