import { GoogleLoginButton } from 'react-social-login-buttons'
import { useLoginContext } from '../../pages/Login/Login'
import { useInitGoogleApi } from '../../security'

function GoogleLogin() {
  const initGoogleApi = useInitGoogleApi()
  const { isAuthenticating } = useLoginContext()

  return (
    <GoogleLoginButton
      disabled={isAuthenticating}
      onClick={initGoogleApi}
      align='center'
      className='social-login-button'
    />
  )
}

export default GoogleLogin
