import { useCallback, useEffect } from 'react'
import RateReviewIcon from '@mui/icons-material/RateReview'
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded'
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded'
import { Button, Stack } from '@mui/material'
import { IdType } from 'otto-vis-timeline'
import { useTags } from '../../../components/VideoTimeline/TimelineTags/useTags'
import { useDriveTrialContext, useTimelineContext } from '../../../details'
import { StyledTooltip } from '../../StyledTooltip/StyledTooltip'

const iconSX = {
  color: '#f4f4f4',
}

const iconPulseGreen = { color: 'green', animation: 'pulse 0.5s infinite' }

const iconDisabledSX = {
  color: '#A9A9A9',
}

const buttonSX = {
  minWidth: '48px',
}

export const TimelineMarkControls = () => {
  const timelineContext = useTimelineContext()
  const { highlightMode } = useDriveTrialContext()
  const { addTag, markIn, markOut } = useTags(
    timelineContext.selectedGroup as IdType
  )
  const isDisabled = highlightMode.id !== -1
  const keyDownEvent = useCallback(
    (e: KeyboardEvent) => {
      const modalClass = 'MuiDialog-root dialog-container MuiModal-root'
      if (
        document.getElementsByClassName(modalClass).length === 0 &&
        e.key.toLowerCase() === 'm'
      ) {
        addTag()
      }
    },
    [addTag]
  )

  useEffect(() => {
    window.addEventListener('keyup', keyDownEvent)

    return () => {
      window.removeEventListener('keyup', keyDownEvent)
    }
  }, [keyDownEvent])

  const tagsMarkingCompliant = (
    <>
      <StyledTooltip title='Add Tag' placement='top'>
        <span>
          <Button
            data-testid='addTag'
            disabled={isDisabled}
            sx={buttonSX}
            onClick={addTag}
          >
            <RateReviewIcon
              sx={isDisabled ? iconDisabledSX : iconSX}
              fontSize='medium'
            />
          </Button>
        </span>
      </StyledTooltip>
      <>
        <StyledTooltip title='Mark In' placement='top'>
          <span>
            <Button
              data-testid='markIn'
              disabled={isDisabled}
              sx={buttonSX}
              onClick={markIn}
            >
              <SkipPreviousRoundedIcon
                sx={isDisabled ? iconDisabledSX : iconSX}
                fontSize='medium'
              />
            </Button>
          </span>
        </StyledTooltip>
        <StyledTooltip title='Mark Out' placement='top'>
          <span>
            <Button
              data-testid='markOut'
              disabled={isDisabled}
              sx={buttonSX}
              onClick={markOut}
            >
              <SkipNextRoundedIcon
                sx={
                  isDisabled
                    ? iconDisabledSX
                    : timelineContext.startMarker
                    ? iconPulseGreen
                    : iconSX
                }
                fontSize='medium'
              />
            </Button>
          </span>
        </StyledTooltip>
      </>
    </>
  )

  return <Stack direction='row'>{tagsMarkingCompliant}</Stack>
}
