import { RefObject } from 'react'
import Ballot from '@mui/icons-material/Ballot'
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import { IconButton } from '@mui/material'
import { iconStyles } from './commonStyles'
import { StyledTooltip } from '../../ui_toolkit/StyledTooltip/StyledTooltip'

interface iProps {
  layersHandler: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  legendHandler: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  objectsHandler: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  layersIconRef?: RefObject<SVGSVGElement> | null
  legendIconRef?: RefObject<SVGSVGElement> | null
  objectsIconRef?: RefObject<SVGSVGElement> | null
  viewportId: number
}

export const CameraTools = ({
  layersHandler,
  legendHandler,
  layersIconRef,
  legendIconRef,
  objectsIconRef,
  viewportId,
  objectsHandler,
}: iProps) => (
  <>
    <StyledTooltip title='Choose layer'>
      <IconButton
        data-testid={`layersPanel-${viewportId}`}
        onClick={layersHandler}
      >
        <LayersTwoToneIcon ref={layersIconRef} sx={iconStyles} />
      </IconButton>
    </StyledTooltip>
    <StyledTooltip title='Color legend'>
      <IconButton
        data-testid={`colorLegend-${viewportId}`}
        onClick={legendHandler}
      >
        <Ballot ref={legendIconRef} sx={iconStyles} />
      </IconButton>
    </StyledTooltip>
    <StyledTooltip title='Objects'>
      <IconButton onClick={objectsHandler}>
        <PivotTableChartIcon ref={objectsIconRef} sx={iconStyles} />
      </IconButton>
    </StyledTooltip>
  </>
)
