import { match, P } from 'ts-pattern'
import { ReportType } from '../pages/Report/types'

export const getPath = (reportType: ReportType, guid: string) =>
  match(reportType)
    .with(
      P.union('lanes', 'lights', 'signs'),
      () => `/${reportType}/kpi-details?guid=${guid}`
    )
    .otherwise(() => `/${reportType}/details?guid=${guid}`)

export const openNewTab = (reportType: ReportType, guid: string) =>
  window.open(getPath(reportType, guid), '_blank')
