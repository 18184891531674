import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { Timeline as VisTimeline } from 'otto-vis-timeline/standalone'
import { IdType, TimelineOptions } from 'otto-vis-timeline/types'
import { match } from 'ts-pattern'
import { CustomGroupTemplate } from './CustomGroupTemplate'
import { KpiTimelineDataFetcher } from './KpiTimelineDataFetcher'
import ShowHidden from './ShowHidden'
import { TIMELINE_OPTIONS_DEFAULT } from './timelineOptions.default'
import { TimelineSettings } from './TimelineSettings/TimelineSettings'
import { useTags } from './TimelineTags/useTags'
import { TimelineTrackMenu } from './TimelineTrackMenu/TimelineTrackMenu'
import { TimelineEventData, VideoTimelineProps } from './types'
import { controlledTimeMarkerId, getTimelineDuration } from './utils'
import VideoTimelineDataFetcher from './VideoTimelineDataFetcher'
import { enUS } from '../../constants'
import {
  useTimelineContext,
  SamplerReference,
  useDriveTrialContext,
} from '../../details'
import { MediaSyncContext } from '../../details/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import {
  ITimeline,
  TimelineControlled,
} from '../../ui_toolkit/Timeline/TimelineControlled'
import { moveMainMarker } from '../../ui_toolkit/Timeline/utils/sliding'
import { applyStyle, toSeconds } from '../../utils'
import NoData from '../NoData/NoData'
import { TagPopup } from '../TagPopup/TagPopup'

import './style.scss'

const SLIDE_OFFSET_PERCENT = 0.95

export function VideoTimeline({
  synchronizer,
  viewportId,
  signsData,
}: VideoTimelineProps) {
  const [isLoading, setIsLoading] = useState(true)
  const [anchorTagPopup, setAnchorTagPopup] = useState<HTMLDivElement>()

  const [activeTag, setActiveTag] = useState<number>()

  const mediaSyncContext = useContext(MediaSyncContext)
  const {
    redirectData,
    highlightMode,
    modeKey,
    driveTrials,
    getCurrentDriveTrial,
  } = useDriveTrialContext()
  const timelineContext = useTimelineContext()
  const {
    groups,
    selectedGroup,
    items,
    initialItems,
    setTimeline,
    startMarker,
    endMarker,
  } = timelineContext
  const { addNotation } = useTags(selectedGroup as IdType)
  const driveTrialData = redirectData?.rows
  const [ctxMenuGroup, setCtxMenuGroup] = useState<number>()
  const [isStartTime, setIsStartTime] = useState<boolean>(false)
  const timelineRef = useRef<ITimeline>(null)
  const sliding = useRef(false)
  const { editTag, removeTag } = useTags(selectedGroup as IdType)
  const isKpi = location.pathname.includes('kpi-details')
  const start = dayjs(0).toDate()
  const end = dayjs(0).add(mediaSyncContext.totalDuration, 'second').toDate()
  const controlledTime = useMemo(
    () => ({
      datetime: dayjs(0).toDate(),
      id: controlledTimeMarkerId,
    }),
    []
  )
  const [trackMenuEl, setTrackMenuEl] = useState<null | HTMLElement>(null)
  const [jumpItemId, setJumpItemId] = useState<string | number>(-1)
  const [dataReceived, setDataReceived] = useState<boolean>(false)

  const handleDataReceived = () => {
    setDataReceived(true)
  }

  const handleTimelineMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    group: IdType
  ) => {
    setCtxMenuGroup(group as number)
    setTrackMenuEl(event.currentTarget)
  }
  const handleCloseTrackMenu = () => {
    setTrackMenuEl(null)
  }

  const handleKey = (event: KeyboardEvent) => {
    timelineRef.current?.setOptions({
      moveable: event.ctrlKey,
    })
  }

  const handleHairColor = (color: string) => {
    const currentTimeBar = document.querySelector('.vis-custom-time')
    if (currentTimeBar instanceof HTMLElement) {
      currentTimeBar.style.backgroundColor = color
    }
  }

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      timelineRef.current?.removeCustomTime('startMarker')
      timelineContext.setStartMarker!(null)
      setIsStartTime(false)
      handleHairColor('yellow')
    }
  }

  useEffect(() => {
    if (jumpItemId !== -1) {
      const itemsCollection =
        highlightMode.id !== -1 ? highlightMode?.items : items
      const clickedItems = itemsCollection.get({
        filter: (item) => item.id === jumpItemId,
      })
      if (!clickedItems.length) return

      const clickedItem = clickedItems[0]
      const date = dayjs(clickedItem.start).valueOf()
      console.info('skacem sa itema na item: ', date)
      mediaSyncContext.time?.update({
        position: toSeconds(date + 1),
      })
    }
  }, [jumpItemId])

  useEffect(() => {
    const activeTrack = document.getElementById(
      `group-template-${highlightMode.id}`
    )
    const otherTracks = document.querySelectorAll('[id*="group-template-"]')

    activeTrack?.classList.add('active-track')
    otherTracks.forEach((x) => {
      if (!x.id.includes(`group-template-${highlightMode.id}`)) {
        x.classList.remove('active-track')
      }
    })

    const options: TimelineOptions = {
      end:
        highlightMode.id !== -1
          ? getTimelineDuration(highlightMode.items.map((t) => t))
          : end,
      max:
        highlightMode.id !== -1
          ? getTimelineDuration(highlightMode.items.map((t) => t))
          : end,
    }

    const items = highlightMode.id !== -1 ? highlightMode.items : initialItems

    timelineRef.current?.setOptions(options)
    timelineRef.current?.setItems(items)

    if (highlightMode.id !== -1) {
      applyStyle()
    }
  }, [highlightMode])

  useEffect(() => {
    if (selectedGroup === undefined && isStartTime) {
      timelineRef.current?.removeCustomTime('startMarker')
      timelineContext.setStartMarker!(null)
      setIsStartTime(false)
      handleHairColor('yellow')
    }
  }, [selectedGroup])

  useEffect(() => {
    window.addEventListener('keydown', handleKey)
    window.addEventListener('keyup', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
      window.removeEventListener('keyup', handleKey)
    }
  }, [])

  useEffect(() => {
    if (isStartTime) {
      window.addEventListener('keydown', handleEscKey)
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey)
    }
  }, [isStartTime])

  /**
   * Subscribe to the mediaSyncContext
   */
  useEffect(() => {
    let samplerEvent: SamplerReference | null = null
    if (mediaSyncContext.sampler) {
      // @ts-expect-error Missing correct INTERFACE
      samplerEvent = mediaSyncContext.sampler.on('change', (t: number) => {
        const currentDriveTrial = getCurrentDriveTrial(t)
        if (!currentDriveTrial) return
        const currentTimeDate =
          currentDriveTrial.startDate - currentDriveTrial.previousDuration + t

        moveMainMarker(
          currentTimeDate,
          t,
          controlledTime,
          timelineRef.current as VisTimeline,
          sliding,
          SLIDE_OFFSET_PERCENT
        )
      })
    }

    setIsLoading(false)

    if (
      startMarker &&
      !endMarker &&
      selectedGroup !== undefined &&
      selectedGroup !== null
    ) {
      if (isStartTime) {
        timelineRef.current?.removeCustomTime('startMarker')
      }
      timelineRef.current?.addCustomTime(new Date(startMarker), 'startMarker')
      setIsStartTime(true)
      handleHairColor('green')
    }
    if (endMarker!) {
      addNotation()
      timelineRef.current?.removeCustomTime('startMarker')
      setIsStartTime(false)
      handleHairColor('yellow')
    }

    return () => {
      samplerEvent?.terminate()
      window.onkeydown = null
    }
  }, [mediaSyncContext, timelineContext, controlledTime])

  const updateTime = (date: Date) => {
    if (mediaSyncContext.time !== undefined) {
      console.info('on change time: ', toSeconds(dayjs(date).valueOf()))
      mediaSyncContext.time.update({
        velocity: 0,
        position: toSeconds(dayjs(date).valueOf()),
      })
    }
  }

  if (isLoading) {
    return <Loader text={enUS.LOADING_TIMELINE} center={true} />
  }

  if (!driveTrials || (!isKpi && !driveTrialData)) {
    return <NoData languageCode='enUS' />
  }

  return (
    <div
      className={`timeline-track-menu${
        dataReceived ? '-enabled' : '-disabled'
      }`}
      style={{ width: '100%', height: '100%' }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <TimelineControlled
        ref={timelineRef}
        animate
        initialGroups={groups}
        initialItems={
          highlightMode.id !== -1 ? highlightMode.items : timelineContext.items
        }
        options={{
          ...TIMELINE_OPTIONS_DEFAULT,
          min: start,
          max: end,
          start,
          end,
          groupTemplate: (group, element) =>
            CustomGroupTemplate({
              group,
              element,
              handleTimelineMenu,
              viewportId,
              timelineContext,
            }),
        }}
        controlledTime={controlledTime}
        slideOffsetPercent={SLIDE_OFFSET_PERCENT}
        timechangeHandler={(props) => {
          if (!mediaSyncContext.time) {
            return
          }

          updateTime(props.time)
        }}
        clickHandler={(props: TimelineEventData) => {
          match(props.what)
            .with('item', () => setJumpItemId(props.item))
            .with('group-label', () => {})
            .otherwise(() => {
              if (!props.event.ctrlKey) {
                updateTime(props.time)
              }
            })
        }}
        contextmenuHandler={(data: TimelineEventData) => {
          if (data.item && data.item.includes('tag')) {
            setAnchorTagPopup(data.event.srcElement)
            setActiveTag(+data.item.split('-')[1])
          }
        }}
        onTimelineInit={(timeline) => setTimeline(timeline)}
      />

      <TimelineTrackMenu
        anchorEl={trackMenuEl}
        handleClose={handleCloseTrackMenu}
        groupId={ctxMenuGroup!}
      />
      {activeTag !== undefined && (
        <TagPopup
          anchorEl={anchorTagPopup}
          setAnchorEl={setAnchorTagPopup}
          activeTag={activeTag}
          setActiveTag={setActiveTag}
          onEdit={editTag}
          onRemove={() => removeTag(activeTag, highlightMode)}
        />
      )}
      {isKpi ? (
        <KpiTimelineDataFetcher
          synchronizer={synchronizer}
          viewportId={viewportId}
          onDataReceived={handleDataReceived}
        />
      ) : (
        <VideoTimelineDataFetcher
          synchronizer={synchronizer}
          viewportId={viewportId}
          signsData={signsData}
          onDataReceived={handleDataReceived}
        />
      )}
      <TimelineSettings key={modeKey} />
      <ShowHidden />
    </div>
  )
}
