import { useQueries } from '@tanstack/react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import {
  VisibleColumns,
  getDriveTrialSegmentIDs,
} from '../../api/redirectionData'
import { ProjectValues, TableRouteParams } from '../../constants'
import { useDriveTrialContext } from '../../details'
import { ObjectHighlight } from '../../details/types'
import { getProjectType } from '../../storage/projectIdStorage'
import { getResponseStatusCode } from '../../utils'
import { urls } from '../urls'

interface InitialGroups extends VisibleColumns {
  project: ProjectValues | 'SPR-A' | 'SPR-I'
}

// these are possible options for highlights, in ideal world these should
// be coming from the backend but they will remain hardcoded for now
export const INITIAL_GROUPS: InitialGroups[] = [
  { project: 'Signs', kpi: '', items: ['Signs'] },
  { project: 'Lights', kpi: '', items: ['Traffic Lights'] },
  {
    project: 'AdjacentLeft',
    kpi: '',
    items: ['Adjacent Left'],
  },
  {
    project: 'AdjacentRight',
    kpi: '',
    items: ['Adjacent Right'],
  },
  {
    project: 'EGOLeft',
    kpi: '',
    items: ['Ego Left'],
  },
  {
    project: 'EGORight',
    kpi: '',
    items: ['Ego Right'],
  },
]

const getUrl = (dtid: number) =>
  urls.driveTrialHighlights.replace(':id', dtid.toString())

export const useHighlightsQueries = () => {
  const projectType = getProjectType()
  const { reportType } = useParams<TableRouteParams>()
  const { redirectData } = useDriveTrialContext()
  const driveTrialData = redirectData?.rows || []
  const highlightTypes = INITIAL_GROUPS.flatMap(({ items }) => items)

  const queryData = highlightTypes.flatMap((highlightType) =>
    driveTrialData.map((dt) => ({
      parentDTID: dt.parentKey,
      dtid: dt.videoKey,
      highlightType,
    }))
  )

  return useQueries({
    queries: queryData.map(({ parentDTID, dtid, highlightType }) => ({
      queryKey: [
        'highlights',
        reportType,
        projectType,
        parentDTID,
        dtid,
        highlightType,
        driveTrialData,
      ],
      queryFn: (): Promise<ObjectHighlight[]> =>
        axios
          .post(
            getUrl(dtid),
            {
              [projectType === 'episodic' ? 'segments' : '']:
                getDriveTrialSegmentIDs(driveTrialData, parentDTID),
              parentDTID,
            },
            { params: { highlightType } }
          )
          .then(({ data }) => data),
    })),
    combine: (queries) =>
      queries.map(({ data, isFetched, isError, error }, index) => ({
        data,
        isFetched,
        isError,
        highlightType: queryData[index].highlightType,
        parentDTID: queryData[index].parentDTID,
        DTID: queryData[index].dtid,
        statusCode: getResponseStatusCode(error),
      })),
  })
}
