import { ProjectType } from '../models/project'

const projectIdKey = 'projectID'
const projectTypeKey = 'projectType'

export const setProjectID = (projectId: number) =>
  window.localStorage.setItem(projectIdKey, projectId.toFixed(0))

export const getProjectID = () => window.localStorage.getItem(projectIdKey)

export const setProjectType = (type: ProjectType) =>
  window.localStorage.setItem(projectTypeKey, type)

export const getProjectType = (): ProjectType =>
  (window.localStorage.getItem(projectTypeKey) as ProjectType) || 'endurance'
