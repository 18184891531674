import { createContext } from 'react'
import { Property } from 'csstype'
import {
  SkewConverter,
  TimingObject,
  TimingProgress,
  TimingSampler,
} from '../timing/timing'

export interface PlayControls {
  isPlaying: boolean
  setPlaying: (playing: boolean) => void
}

export type SourceType = 'otto' | 'me'

export type LanePoint = [number, number, number]

export interface Lanes {
  classL: string
  classR: string
  pointsL: LanePoint[]
  pointsR: LanePoint[]
  lineColorL?: string
  lineColorR?: string
}

interface SignLabels {
  speedData: string
  frameData: string
}

interface SignsData {
  class: string
  tx: number
  ty: number
  bx: number
  by: number
  id: number
  existanceProbability: number
  frameCount: number
  electric: number
  countryCode: number
  relevancy: number
  labels: SignLabels
}
export interface Signs {
  [data: string]: SignsData[]
}

interface LightsData {
  lightsObjectID: number
  tx: number
  ty: number
  bx: number
  by: number
  existanceProbability: number
  frameCount: number
  electric: number
  countryCode: number
  relevancy: number
  isRelevantCurrentFrame: number
  spotColor: number
  activeSpotCount: number
  structureType: number
}

export interface Lights {
  [data: string]: LightsData[]
}

export const spotColorLabels = {
  0: 'G',
  1: 'R',
  2: 'Y',
  3: '-',
}

export const spotColorMeLabels = {
  0: '-',
  1: 'R',
  2: 'Y',
  3: 'G',
  4: 'RO',
}

export type SpotColor = keyof typeof spotColorLabels

export const structureTypeLabels = {
  500: 'V3',
  501: 'Ped Hor',
  502: 'Ped Ver',
  503: 'V2',
  504: 'Hor (3)',
  505: 'Ver Struct',
  506: 'Hor Struct',
  507: 'Dog House',
  508: 'V5',
  509: 'V4',
  510: 'Hor (4)',
  511: 'Hor (5)',
  512: 'Unknown',
}
export type StructureType = keyof typeof structureTypeLabels

interface BBData {
  class: string
  camera: number
  active: number
  tx: number
  ty: number
  bx: number
  by: number
  id: number
  existanceProbability: number
  frameCount: number
  relevancy: number
}
export interface BBoxes {
  [data: string]: BBData[]
}

export interface ObjectColor {
  name: string
  source: string
  layerColor: Property.BackgroundColor
}

export interface Point {
  x: number
  y: number
  confidence: number
}

export interface Line {
  p1: Point
  p2: Point
}

export interface LanesConfidence {
  left: Line
  right: Line
}

export type ObjectType =
  | 'egoLane'
  | 'adjacentLane'
  | 'egoLabel'
  | 'adjacentLabel'
  | 'sign'
  | 'car'
  | 'bus'
  | 'pedestrian'
  | 'truck'
  | 'bicycle'
  | 'motorcycle'
  | 'trafficLight'

export type LineType = 'EGO' | 'ADJACENT'

export type LaneSide = keyof LanesConfidence

export interface MediaSync {
  sampler?: TimingSampler
  time?: TimingObject
  progress?: TimingProgress
  activeVideo?: number
  activeVideoId?: number
  delay?: SkewConverter
  totalDuration: number
  isPlaying: boolean
  playbackSpeed: number
}

export interface DriveTrialData {
  dtid: number
  startDate: number
  endDate: number
  duration: number
  cumulativeDuration: number
  description: string
  previousDuration: number
}

export interface DriveTrialsResp {
  [key: number]: DriveTrialData
}

export interface DriveSegmentData {
  startDate: number
  endDate: number
  duration: number
  cumulativeDuration: number
  dtid: number
}

export interface DriveSegmentsResp {
  [key: number]: DriveTrialData
}

const defaultContext: MediaSync = {
  totalDuration: 0,
  playbackSpeed: 1,
  isPlaying: false,
  activeVideoId: 1,
}
export const MediaSyncContext = createContext(defaultContext)

export interface EnabledLayers {
  otto: string[]
  me: string[]
}

export enum ViewportContent {
  FRONT_CAMERA = 1,
  LEFT_CAMERA,
  RIGHT_CAMERA,
  REAR_CAMERA,
  MAP,
  TIMELINE,
  '3D_VIEW',
  TOP_VIEW_MAP,
}

export type ViewportType =
  | 'FRONT_CAMERA'
  | 'LEFT_CAMERA'
  | 'RIGHT_CAMERA'
  | 'REAR_CAMERA'
  | 'MAP'
  | 'TIMELINE'
  | '3D_VIEW'

export interface ViewportMenuObject {
  id: number
  name: ViewportType
}

export type LaneChoice = 'left' | 'right' | 'both'
export type SourceChoice = SourceType | 'both'

export interface TopDownData {
  lane: LaneChoice
  source: SourceChoice
}

export interface ViewportState {
  content: ViewportContent
  data: TopDownData | EnabledLayers | undefined
}

export interface Dimensions {
  width: number
  height: number
}

export interface DataFrame {
  dtid: number
  timestamp: number
  lon: number
  lat: number
  x: number
  y: number
  z: number
  heading: number
  leftConfidence: number
  leftC0: number
  leftC1: number
  leftC2: number
  leftC3: number
  leftStart: number
  leftEnd: number
  rightConfidence: number
  rightC0: number
  rightC1: number
  rightC2: number
  rightC3: number
  rightStart: number
  rightEnd: number
  leftAvailability: number
  rightAvailability: number
  leftConfidenceGT: number
  rightConfidenceGT: number
  leftC0GT: number
  rightC0GT: number
}

export type TimestampDataFrameMap = Record<number, DataFrame>

export interface SendToDetailsData {
  dtId?: number
  sectionId?: number
  detailId?: number
  minSectionId?: number
}

export interface DriveTrialDataRedirect {
  videoKey: number
  parentKey: number
  segmentIDs: number[]
  version?: string
  sendToDetailsData?: SendToDetailsData
}

export interface ObjectHighlight {
  dtid?: number
  dsid: number
  gain: number
  comment: string
  hlid: string
  timelineEndTime: number
  timelineStartTime: number
  highlightType?: string
}

export interface ResponseError extends Error {
  response: Response
}
export interface TimelineBackground {
  timelineEndTime: number
  timelineStartTime: number
}
