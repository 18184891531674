export const enUS = {
  ACCOUNT_SETTINGS: 'Account Settings',
  CHANGELOG: 'Change log',
  USER_SETTINGS: 'User Settings',
  ADMIN_SETTINGS: 'Admin Settings',
  HELP: 'Help',
  SAVE: 'Save',
  LICENSES: 'Licenses',
  LOGOUT: 'Logout',
  VERSION: 'Version',
  SUPPORT_TOOLTIP: 'Send e-mail to support',
  SEGMENT: 'Segment: ',
  DESCRIPTION: 'Description: ',
  VIEWPORT_MENU: 'Viewport Menu',
  HIGHLIGHT_MODE: 'Highlights',
  HIGHLIGHT_MODE_EXIT: 'Exit highlights',
  HIDE_GROUP: 'Hide',

  ALL_DATA: 'All Data',
  MISSING_DATA: 'Missing Data',
  NO_DATA: 'No Data',
  NO_START_TIME: 'Start time has to be selected first!',
  NO_DATA_TO_DISPLAY: 'No Data to Display',

  CANCEL: 'Cancel',
  OK: 'OK',

  SESSION: 'Session',
  LOAD_SESSION: 'Load a session',

  SAVE_SESSION: 'Save current session',
  SESSION_PLACEHOLDER: 'Give this session a name',
  SESSION_LABEL: 'Session Name',
  DEFAULT_SESSION: 'Default Session',
  LAST_SAVED_SESSION: 'Last saved session',

  LANES: 'Lanes',
  SIGNS: 'Signs',
  LIGHTS: 'Lights',
  OBJECTS: 'Objects',

  KPI_SUMMARY: 'KPI Summary',
  DRIVE_TRIAL: 'Data/Drive Trials',

  VALUE: 'Value',
  SPARKLINE: 'Sparkline',
  VALUE_AND_SPARKLINE: 'Value | Sparkline',
  BARS: 'Bars',
  LINES: 'Lines',

  LOCK: 'Lock',
  UNLOCK: 'Unlock',
  COL: 'columns',
  HIDE: 'Hide column',

  DATE_START: 'Date Start',
  DATE_END: 'Date End',
  TO: 'To',

  ERR_LOGIN: 'Please contact your system administrator',
  ERR_REPORT_DATA:
    'Error fetching report data. Please reload the page or contact your administrator.',
  ERR_REPORT_TYPE: 'Unsupported report type selected. Please try again.',
  ERR_MAP: 'Unexpected error while loading GPS data.',
  ERR_API: 'API has returned status code: ',
  ERR_SERVER: 'Server Error',
  ERR_EXTRACTING_DATA: 'Extracting video data failed.',
  DATA_NOT_AVAILABLE: 'Data not available',

  INFO_NO_DATA: 'The query you used returned no data.',
  INFO_QUERY_EXECUTED: 'Query executed successfully!',
  INFO_NO_SORT_VALUES:
    "Selected column couldn't be sorted by. Column contains no numeric or alphanumeric data.",

  DOWNLOAD_PDF: 'Download PDF file',
  DOWNLOAD_CSV: 'Download CSV file',

  TIMELINE: 'Timeline',
  CREATE_TIMELINE_GROUP: 'Create new timeline group',
  GROUP_DATA: 'Group data',
  GROUP_NAME: 'Group name',
  GROUP_TYPE: 'Group type',
  GROUP_SELECT: 'Select',
  GROUP_UNSELECT: 'Deselect',
  GROUP_ADD: 'Add group',
  GROUP_SHOW_HIDE: 'Show/Hide tags',
  GROUP_REMOVE: 'Remove group',
  GROUP_ABOVE: 'Add group above',
  GROUP_UNDER: 'Add group under',
  GROUP_POSITION: 'Choose where to add new group',
  EXPORT: 'Export',
  ERR_ITEM_ON_KEY: 'Tag present on the timeline on current timestamp',
  ERR_NO_ROW_SELECTED:
    "You have tried adding a tag, but you didn't select a row. Please, select a row where the tag should be added.",
  TAGS_HIDDEN: 'Can not create tags in hidden mode!',
  NOT_FOUND: 'Page not found',
  NO_VIDEO: 'Video data not found. You can safely close this tab.',

  NO_COLUMNS_SHOWN: 'KPI Columns will be shown after selecting filters',
  NO_FILTERS_SELECTED: 'KPI Summary will be shown after selecting filters',
  NO_TABLE_DATA: 'No table data found, try selecting different filters.',
  NO_SEGMENTS_DATA: 'No data for drive trial segments found.',
  REDIRECTION_ERROR: 'Redirection to the details page has failed',
  USER_ON_IDLE: 'User session has expired, please, log in again.',
  SELECT_FORMAT: 'Select print format',
  SELECT_LAYOUT: 'Select print layout',
  SAVE_PDF: 'Save PDF',
  SEND_TO_DETAILS: 'Send to details',
  LOADING_TIMELINE: 'Loading timeline',
  NO_DASHBOARD_FOUND: 'Dashboard data not found',

  START_DRIVE_TRAIL_ID: 'Start:',
  END_DRIVE_TRAIL_ID: 'End:',

  FOCUS_MARKER_TITLE: 'Focus marker',
  COORDINATES_COPIED_TO_CLIPBOARD: 'Coordinates are copied to clipboard!',
  JIRA_NOT_FOUND: 'Jira issue not found, it might have been already deleted.',
}

export const enUS_COLORS = {
  lane: 'Lane',
  vehicle: 'Vehicle',
  trafficSigns: 'Traffic Signs',
  pedestrians: 'Pedestrians',
  egoLane: 'Ego Lane',
  leftLane: 'Left Lane',
  rightLane: 'Right Lane',
}

export const enUS_TOOLTIPS = {
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
}

export const enUS_SIDEBAR_TOOLBOX = {
  reports: 'Reports',
  columns: 'Columns',
  search: 'Search',
  advancedSearch: 'Advanced Search',
}
