import { ILayerPanelItem } from './types'
import { getCanvasLayers } from '../../details/config'
import { ObjectColor } from '../../details/types'
import { ReportType } from '../../pages/Report/types'

const defaultData = [
  {
    name: 'OTTO LAYERS',
    categories: [
      {
        name: 'Vehicle',
        options: [
          {
            name: 'ActiveCarColor',
            color: '',
            isChecked: true,
          },
          {
            name: 'ActiveTruckColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveBusColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveMotorcycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Traffic Signs',
        options: [
          {
            name: 'ActiveSignColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'Stop',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveTrafficLightColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Pedestrians',
        options: [
          {
            name: 'ActivePedColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveCycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Lane',
        options: [
          {
            name: 'ActiveEgoLineColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveEgoLineClass',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneClass',
            color: '',
            isChecked: false,
          },
        ],
      },
    ],
  },
  {
    name: 'VEHICLE LAYERS',
    categories: [
      {
        name: 'Vehicle',
        options: [
          {
            name: 'ActiveCarColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveTruckColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveBusColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveMotorcycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Traffic Signs',
        options: [
          {
            name: 'ActiveSignColor',
            color: '9E0067',
            isChecked: false,
          },
          {
            name: 'Stop',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveTrafficLightColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Pedestrians',
        options: [
          {
            name: 'ActivePedColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveCycleColor',
            color: '',
            isChecked: false,
          },
        ],
      },
      {
        name: 'Lane',
        options: [
          {
            name: 'ActiveEgoLineColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveEgoLineClass',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneColor',
            color: '',
            isChecked: false,
          },
          {
            name: 'ActiveAdjacentLaneClass',
            color: '',
            isChecked: false,
          },
        ],
      },
    ],
  },
]

export const buildLayoutData = (colors: ObjectColor[]): ILayerPanelItem[] => {
  const freshData = structuredClone(defaultData) as ILayerPanelItem[]

  return freshData.map((x, i) => {
    const panelName = i === 0 ? 'Ottometric' : 'Mobileye'
    return {
      ...x,
      categories: x.categories.map((y) => ({
        ...y,
        options: y.options.map((z) => {
          const optionColor = colors.find(
            (c) => c.source === panelName && c.name === z.name
          )
          return {
            ...z,
            color: optionColor?.layerColor ?? '',
          }
        }),
      })),
    }
  })
}

export const isDisabledOption = (
  name: string,
  color: string,
  project: ReportType
) => {
  const layers = getCanvasLayers(project)

  if (layers.includes(name.replaceAll(' ', '')) && color) {
    return false
  }

  return true
}
