import { Button } from '@mui/material'
import { NavLink } from 'react-router-dom'
import Header from '../../components/Header/Header'
import { enUS } from '../../constants'

interface IProps {
  message?: string
  hideReturnToHomePage?: boolean
  normalText?: boolean
  status?: number
  hideHeader?: boolean
}

const StatusPage = ({
  status = 404,
  normalText,
  hideReturnToHomePage = false,
  message = enUS.NOT_FOUND,
  hideHeader = false,
}: IProps) => {
  return (
    <>
      {!hideHeader && <Header />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 48px)',
          color: 'whitesmoke',
          lineHeight: '6rem',
          gap: '40px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h1
            style={{
              margin: 0,
              fontSize: '10rem',
              userSelect: 'none',
            }}
          >
            {status}
          </h1>
          <p
            style={{
              margin: 0,
              textTransform: normalText ? 'none' : 'uppercase',
              userSelect: 'none',
            }}
          >
            {message}
          </p>
        </div>
        {hideReturnToHomePage ? null : (
          <NavLink
            style={{ textDecoration: 'none', display: 'contents' }}
            to='/app/landing'
          >
            <Button
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.secondary.main,
                },
              }}
              variant='contained'
            >
              Return to home page
            </Button>
          </NavLink>
        )}
      </div>
    </>
  )
}

export default StatusPage
