import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { ViewportMenuObject } from '../details/types'

export const useClientViewportMenuQuery = () =>
  useQuery({
    queryKey: ['clientViewportMenu'],
    staleTime: Infinity,
    queryFn: (): Promise<ViewportMenuObject[]> =>
      axios
        .get<ViewportMenuObject[]>(urls.clientViewportMenuOptions)
        .then(({ data }) => data),
  })
