import {
  PropsWithChildren,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { enUS } from '../../constants'
import { DriveTrial, useDriveTrialContext } from '../../details'
import { MediaSyncContext } from '../../details/types'
import { getProjectType } from '../../storage/projectIdStorage'
import './style.scss'

interface DetailsLabel {
  idName?: string
  id?: number
  segmentId?: number
  description?: string
  isMulti?: boolean
}

const HeaderTitle = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  const { activeVideoId } = useContext(MediaSyncContext)
  const [activeVideo, setActiveVideo] = useState<number>()
  const { driveTrials, handleTitleChangeRef } = useDriveTrialContext()
  const [detailsLabel, setDetailsLabel] = useState<DetailsLabel>()
  const isDetailsPage = location.pathname.includes('details')

  const projectType = getProjectType()
  const isEpisodic = projectType === 'episodic'

  const [hilVersion, setHilVersion] = useState('')

  // this will expose `setActiveVideo` hook and it will enable us to update only
  // title when changing active video without re-rendering any other part of the page
  useImperativeHandle(handleTitleChangeRef, () => ({ setActiveVideo }), [])

  useEffect(() => {
    if (isDetailsPage) {
      const activeTrial = driveTrials.find((x) => x.id === activeVideoId)

      if (activeTrial?.version) {
        setHilVersion(activeTrial.version)
      }

      getDetailsLabel(activeTrial)
    }
    // we should update the title only when `activeVideo` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVideo])

  const getDetailsLabel = (currentDT?: DriveTrial) => {
    if (!currentDT || !driveTrials.length) return setDetailsLabel(undefined)

    if (driveTrials.length > 0) {
      return setDetailsLabel({
        idName: 'Drive ID',
        id: currentDT.hilKey.DTID,
        description: currentDT.description,
      })
    }

    setDetailsLabel({
      idName: isEpisodic ? 'Batch ID' : 'Drive ID',
      id: isEpisodic ? currentDT.key.DTID : activeVideo,
      segmentId: isEpisodic ? activeVideo : 0,
      description: currentDT.description,
    })
  }

  if (children) return <>{children}</>

  return (
    <Typography
      {...(!isDetailsPage && { className: 'heading', variant: 'h5' })}
    >
      {isDetailsPage && (
        <span className='header-title'>
          {detailsLabel?.idName}:<span> {detailsLabel?.id}</span>{' '}
          {hilVersion && (
            <>
              HIL:
              <span> {hilVersion}</span>
            </>
          )}
          {isEpisodic && (
            <>
              {enUS.SEGMENT}
              <span> {detailsLabel?.segmentId}</span>
              {enUS.DESCRIPTION}
              <span> {detailsLabel?.description}</span>
            </>
          )}
        </span>
      )}
    </Typography>
  )
}

export default HeaderTitle
