import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { getBaseAuthData } from './base'
import {
  RedirectData,
  getSegments,
  getUniqueDTParentKeys,
} from './redirectionData'
import { urls } from './urls'
import { useDriveTrialContext } from '../details'
import { getProjectType } from '../storage/projectIdStorage'

interface GpsData {
  dtid: number
  longitude: number
  latitude: number
  speed: number
  yaw: number
}

interface MapRoutesResponse {
  map: GpsData[]
}

const getMapRoutesParams = (dtidArray: string) => ({
  dtidArray,
  ...getBaseAuthData(),
})

const getMapPayload = (redirectData: RedirectData | undefined) => {
  const projectType = getProjectType()
  const data =
    projectType === 'episodic'
      ? getSegments(redirectData)
      : getUniqueDTParentKeys(redirectData)

  return data
}

export const useMapQuery = () => {
  const { redirectData } = useDriveTrialContext()
  const driveTrials = getMapPayload(redirectData)
  const mapPayload = (driveTrials || []).toString()

  return useQuery({
    queryKey: ['mapRoutes', mapPayload],
    staleTime: Infinity,
    queryFn: (): Promise<MapRoutesResponse> =>
      axios
        .get<MapRoutesResponse>(urls.mapRoutes, {
          params: getMapRoutesParams(mapPayload),
          withCredentials: true,
        })
        .then(({ data }) => {
          const dtids = redirectData.rows
          if (!dtids || driveTrials.length === dtids.length) return data
          const gpsData = dtids
            .map((dt) =>
              data.map
                .filter((gps) => gps.dtid === dt.parentKey)
                .map((x) => ({ ...x, dtid: dt.videoKey }))
            )
            .flat()

          return { map: gpsData }
        }),
  })
}
