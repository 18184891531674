import React from 'react'
import { ColumnDef, Header, createColumnHelper } from '@tanstack/react-table'
import axios, { AxiosResponse } from 'axios'
import { CellFormatter } from './components/CellFormatter'
import SparklineFormatter from './components/SparklineFormatter'
import {
  BreadcrumbItem,
  PerserveState,
  ReportType,
  SelectedRow,
  SubHeader,
  SuperHeader,
} from './types'
import { urls } from '../../api'
import { RedirectData, VisibleColumns } from '../../api/redirectionData'
import {
  DEFAULT_PAYLOAD_LANES_KPI,
  DEFAULT_PAYLOAD_LIGHTS_KPI,
  DEFAULT_PAYLOAD_SIGNS_KPI,
} from '../../api/table/defaultKPIPayloads'
import { SendToDetailsData } from '../../details/types'
import { TableResponse } from '../../models/table'
import { openNewTab } from '../../security'
import { SparklineVariant } from '../../ui_toolkit/Sparkline/Sparkline'

export const columnHelper = createColumnHelper<any>()

export const createHeaderId = (header: Header<any, unknown>) => {
  const child = header.column.columnDef.header?.toString().replaceAll(' ', '')
  const parent = header.column.parent?.columnDef.header
  if (parent) {
    const parentDense = parent.toString().replaceAll(' ', '')
    return `${parentDense}-${child}`
  }
  return `${child}`
}

export const createColumns = (
  headers: SuperHeader[],
  displayModeColumns:
    | {
        [key: string]: SparklineVariant
      }
    | undefined
) => {
  if (!headers?.length) {
    return []
  }

  return headers.map((header, i) =>
    columnHelper.group({
      id: (1000 + i).toString(),
      header: header.name,
      enableSorting: false,
      meta: header?.id,
      columns: header.subheaders.map((subheader: SubHeader, index: number) =>
        columnHelper.accessor(1000 + i.toString() + index, {
          id: 1000 + i.toString() + index,
          header: subheader.name,
          meta: subheader?.id,
          cell: (cell) => {
            if (
              displayModeColumns &&
              displayModeColumns[1000 + i.toString() + index]
            ) {
              const displayMode =
                displayModeColumns[1000 + i.toString() + index]
              return SparklineFormatter({ cell, variant: displayMode })
            } else {
              return CellFormatter({ cell, column: subheader.name })
            }
          },

          enablePinning: !(
            subheader.name === 'DTID' ||
            subheader.name === 'ParentDTID' ||
            subheader.name === 'Description' ||
            subheader.name === 'Version'
          ),
        })
      ),
    })
  ) as ColumnDef<any, any>[]
}

export const createData = (
  data: AxiosResponse<TableResponse> | undefined,
  headers: SuperHeader[],
  breadcrumbs: BreadcrumbItem[]
) => {
  if (!data?.data || !headers?.length) {
    return []
  }

  const cols: any = []

  headers.forEach((header, i) => {
    cols.push(
      columnHelper.group({
        id: (1000 + i).toString(),
        header: header.name,
        enableSorting: false,
        columns: header.subheaders.map((subheader: SubHeader, index: number) =>
          columnHelper.accessor(1000 + i.toString() + index, {
            id: 1000 + i.toString() + index,
            header: subheader.name,
            enablePinning: !(
              subheader.name === 'DTID' ||
              subheader.name === 'ParentDTID' ||
              subheader.name === 'Description' ||
              subheader.name === 'Version'
            ),
          })
        ),
      })
    )
  })

  const leafsIDs = cols.map((x: any) => x.columns.map((y: any) => y.id)).flat()

  const transform = (x: any) => {
    const o: { [key: string]: any } = {}

    x.forEach((y: any, i: number) => {
      o[leafsIDs[i]] = y
    })

    return o
  }

  if (!data.data.data) return []

  const transformedData = data.data.data.map(transform)
  if (data.data.summary) {
    const transformedSummary = [data.data.summary].map(transform)
    return [...transformedData, transformedSummary[0]]
  }

  if (breadcrumbs.length < 2) {
    return transformedData
  }

  return transformedData
}

const performRedirection = async (
  redirectionData: RedirectData,
  loaderCallback: React.Dispatch<React.SetStateAction<boolean>>,
  reportType: ReportType
) => {
  const guid: string = (await axios.post(urls.redirectionData, redirectionData))
    ?.data

  loaderCallback(false)
  if (!guid) return false

  openNewTab(reportType, guid)
  return true
}

export const sendToDetailsKPIEndurance = async (
  selectedRows: Set<SelectedRow>,
  reportType: ReportType,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>,
  kpiVisibleColumns: VisibleColumns[],
  preserveState: PerserveState
) => {
  const defaultFilters =
    reportType === 'lanes'
      ? DEFAULT_PAYLOAD_LANES_KPI
      : reportType === 'signs'
      ? DEFAULT_PAYLOAD_SIGNS_KPI
      : DEFAULT_PAYLOAD_LIGHTS_KPI

  const redirectionData = {
    rows: [...selectedRows]
      .sort((a, b) => a.DTID - b.DTID)
      .map((row) => ({
        videoKey: row.DTID,
        parentKey: row.parentDTID,
        version: row.version,
        segmentIDs: [],
      })),
    columns: kpiVisibleColumns,
    filters: {
      ...defaultFilters,
      ...preserveState[reportType].filterKpiToSql,
    },
  }

  return await performRedirection(redirectionData, setLoader, reportType)
}

export const sendToDetailsEndurance = async (
  sendToDetailsData: SendToDetailsData,
  selectedRows: Set<SelectedRow>,
  reportType: ReportType,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>,
  breadcrumbs: BreadcrumbItem[]
) => {
  const redirectionData = {
    rows: [...selectedRows]
      .sort((a, b) => a.DTID - b.DTID)
      .map((row) => ({
        videoKey:
          breadcrumbs.length === 1
            ? row.DTID
            : sendToDetailsData.dtId ?? row.parentDTID,
        segmentIDs: [],
        parentKey: row.parentDTID,
        version: row.version,
        sendToDetailsData,
      })),
  }

  return await performRedirection(redirectionData, setLoader, reportType)
}

export const sendToDetailsEpisodic = async (
  selectedRows: Set<SelectedRow>,
  reportType: ReportType,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const redirectData: any = []
  const rows = [...selectedRows]

  const promises = rows.map((row) =>
    axios.post<number[]>(urls.driveTrialSegmentIds, {
      dtids: row.DTID.toString(),
    })
  )

  const responses = await Promise.all(promises)

  responses.forEach((resp, index) => {
    redirectData.push({
      videoKey: rows[index],
      segmentIDs: resp.data,
    })
  })

  const redirectionData = {
    rows: redirectData,
  }

  return await performRedirection(redirectionData, setLoader, reportType)
}
