import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)

export const secondsToTimeString = (seconds: number) => {
  if (isNaN(seconds)) {
    return '00:00'
  }
  const time = dayjs.duration(seconds, 'seconds').asMilliseconds()

  return dayjs.utc(time).format('HH:mm:ss')
}
