import React from 'react'
import { MsalProvider } from '@azure/msal-react'
import { CssBaseline, ThemeProvider } from '@mui/material'
// import * as Sentry from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { TableContextProvider } from './pages/Report/providers/ReportContextProvider'
import reportWebVitals from './reportWebVitals'
import { AuthProvider, IdleTimerContext, msalClient } from './security'
import AxiosInterceptor from './security/Auth/AxiosInterceptor'
import { store } from './store/store'
import theme from './theme/theme'
import { getResponseStatusCode } from './utils'
import './index.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: Boolean(process.env.REACT_APP_SENTRY_ENABLED),
  environment: process.env.REACT_APP_ENV_NAME,
  integrations: [
    // eslint-disable-next-line
    Sentry.browserTracingIntegration(),
    // eslint-disable-next-line
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'otto-viz-s2.magna.ottometric.com',
    'otto-viz-s1.magna.ottometric.com',
    'ottoviz.magna.ottometric.com',
    'ottoviz.demo.ottometric.com',
    'ottoviz-s2.demo.ottometric.com ',
    'ottoviz-s1.demo.ottometric.com ',
    'dev-ottoviz.ominf.net',
    'localhost:3000',
    'localhost',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: Error | null) => {
        // Don't retry for 404 status code, else max 3 retries
        return getResponseStatusCode(error) !== 404 ? failureCount < 3 : false
      },
    },
  },
})

function ReactApp() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition='bottom-left'
          />
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider
                autoHideDuration={5000}
                maxSnack={3}
                disableWindowBlurListener={true}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                preventDuplicate
              >
                <IdleTimerContext>
                  <MsalProvider instance={msalClient}>
                    <AxiosInterceptor>
                      <TableContextProvider>
                        <App />
                      </TableContextProvider>
                    </AxiosInterceptor>
                  </MsalProvider>
                </IdleTimerContext>
              </SnackbarProvider>
            </ThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ReactApp />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
