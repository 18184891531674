import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import {
  DEFAULT_PAYLOAD_LANES_KPI,
  DEFAULT_PAYLOAD_SIGNS_KPI,
  DEFAULT_PAYLOAD_LIGHTS_KPI,
} from './defaultKPIPayloads'
import { TableRouteParams } from '../../constants'
import { TableResponse } from '../../models/table'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { getClientID } from '../../storage/clientIdStorage'
import { getProjectID } from '../../storage/projectIdStorage'
import { urls } from '../urls'

export const useKpiTableQuery = () => {
  const { reportType } = useParams<TableRouteParams>()
  const { pathname } = useLocation()
  const { preserveState, limit, page, hilVersion } = useReportContext()
  const offset = (page - 1) * limit

  const lanesPayload = {
    ...DEFAULT_PAYLOAD_LANES_KPI,
    clientId: getClientID(),
    projectId: getProjectID(),
    offset,
    limit,
    ...preserveState.lanes.filterKpiToSql,
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.lanes.fetchByOrder.sortBy,
    sortOrder: preserveState.lanes.fetchByOrder.sortDirection,
    ...preserveState.lanes.filterKpiToSql,
  }

  const signsPayload = {
    ...DEFAULT_PAYLOAD_SIGNS_KPI,
    clientId: getClientID(),
    projectId: getProjectID(),
    offset,
    limit,
    ...preserveState.signs.filterKpiToSql,
    speedRange: '',
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.signs.fetchByOrder.sortBy,
    sortOrder: preserveState.signs.fetchByOrder.sortDirection,
    ...preserveState.signs.filterKpiToSql,
  }

  const lightsPayload = {
    ...DEFAULT_PAYLOAD_LIGHTS_KPI,
    clientId: getClientID(),
    projectId: getProjectID(),
    offset,
    limit,
    ...preserveState.lights.filterKpiToSql,
    speedRange: '',
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.lights.fetchByOrder.sortBy,
    sortOrder: preserveState.lights.fetchByOrder.sortDirection,
    ...preserveState.lights.filterKpiToSql,
  }

  return useQuery({
    enabled: pathname.includes('kpis'),
    queryKey: [lanesPayload, signsPayload, lightsPayload, reportType],
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<AxiosResponse<TableResponse>> => {
      if (reportType === 'lanes') {
        return axios.post(urls.kpiSummaryLanes, lanesPayload)
      } else if (reportType === 'signs') {
        return axios.post(urls.kpiSummarySigns, signsPayload)
      } else {
        return axios.post(urls.kpiSummaryLights, lightsPayload)
      }
    },
  })
}
