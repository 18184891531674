import { useCallback, useContext, useEffect, useState } from 'react'
import CanvasBuffer, { TimedData } from './CanvasBuffer'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { LoadingContext, useDriveTrialContext } from '../../details'
import { getFrameRate } from '../../details/config'
import {
  LineType,
  MediaSyncContext,
  ObjectColor,
  ObjectType,
  SourceType,
} from '../../details/types'
import {
  InitializationMessageWorker,
  WorkerEvent,
} from '../../details/workers/messages'
import { RowKey } from '../../models/table'
import { getProjectType } from '../../storage/projectIdStorage'
import './canvas.scss'

interface CanvasProps {
  videoId: number
  size: {
    width: number
    height: number
  }
  videoKey: RowKey
  hilKey: RowKey
  dataTestId: string
  sourceType: SourceType
  objectType: ObjectType
  colors: ObjectColor[]
  camera: number
  lineType?: LineType
  synchronizer?: ISynchronizer
  viewportId: number
}

export function Canvas({
  videoId,
  size,
  videoKey,
  hilKey,
  dataTestId,
  sourceType,
  objectType,
  colors,
  camera,
  lineType,
  synchronizer,
  viewportId,
}: CanvasProps) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { setAreCanvasesLoading } = useContext(LoadingContext)
  const { getCurrentDriveTrial } = useDriveTrialContext()
  const [isPending, setIsPending] = useState<boolean>(false)
  const [objectWorker, setWorker] = useState<Worker | null>(null)
  const projectType = getProjectType()
  const frameRate = getFrameRate(projectType)

  const isVideoActive = () => mediaSyncContext.activeVideoId === videoId

  const draw = useCallback(
    (time: number) => {
      const currentDriveTrial = getCurrentDriveTrial(mediaSyncContext.time?.pos)
      if (!currentDriveTrial) return

      const { previousDuration, startTime, playbackSkew } = currentDriveTrial
      const frameOffset = previousDuration * frameRate

      const currentTime = time + (playbackSkew ? playbackSkew - startTime : 0)
      const currentFrame = Math.floor(currentTime * +frameRate)

      const wm: WorkerEvent = {
        type: 'timeChange',
        data: currentFrame - frameOffset,
      }

      objectWorker?.postMessage(wm)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectWorker, frameRate, objectType]
  )

  const setupWebWorker = useCallback(
    (node: HTMLCanvasElement | null) => {
      if (node !== null && colors?.length > 0) {
        try {
          const canvas = node.transferControlToOffscreen()

          const worker = new Worker(new URL(`./worker.ts`, import.meta.url))

          const initMessage: InitializationMessageWorker = {
            canvas,
            colors,
            sourceType,
            objectType,
          }

          const event: WorkerEvent = {
            data: { ...initMessage },
            type: 'initialize',
          }

          worker.postMessage(event, [canvas])
          setWorker(worker)
        } catch (e) {
          // fails silently
        }
      }
    },
    // If something of these dependencies change, we initial Web worker
    [colors, objectType, sourceType]
  )

  useEffect(() => {
    if (objectWorker && synchronizer) {
      synchronizer?.addDrawCallback(draw, viewportId, videoId)
    }
    return () => {
      if (objectWorker) {
        objectWorker?.terminate()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectWorker, synchronizer])

  useEffect(() => {
    const key = `${objectType}-${sourceType}`
    synchronizer?.updateCanvasStatus(viewportId, !isPending, key)
    setAreCanvasesLoading(key, isPending)
  }, [isPending])

  useEffect(() => {
    const event: WorkerEvent = {
      data: size,
      type: 'reconfigure',
    }
    objectWorker?.postMessage(event)
  }, [size, objectWorker])

  const triggerDraw = useCallback(() => {
    const time: number = mediaSyncContext.time?.query().position
    synchronizer?.triggerDraw(time, viewportId, videoId)
  }, [mediaSyncContext, synchronizer, viewportId, videoId])

  const handleBufferData = useCallback(
    (data: TimedData<unknown>) => {
      const event: WorkerEvent = {
        type: 'populate',
        data: {
          objectData: data,
        },
      }
      objectWorker?.postMessage(event)
      triggerDraw()
    },
    [objectWorker, triggerDraw]
  )

  const handleFetching = (isFetching: boolean) => {
    const key = `${objectType}-${sourceType}`
    if (isVideoActive()) {
      synchronizer?.updateCanvasStatus(viewportId, !isFetching, key)
    }

    setAreCanvasesLoading(key, isFetching)

    const isViewportLoading =
      synchronizer?.isViewportLoading(viewportId) ?? false

    if (!isFetching && !isViewportLoading) {
      triggerDraw()
    }
  }

  const handleDraw = (time: number) => {
    if (
      mediaSyncContext.time?.query().velocity !== 0 &&
      !synchronizer?.anyLoadingViewports() &&
      isVideoActive()
    ) {
      draw(time)
    }
  }

  return size.width === 0 || size.height === 0 ? null : (
    <div
      className={`worker-canvas-container ${
        objectType.includes('Label') ? 'label' : ''
      }`}
    >
      {objectWorker && (
        <CanvasBuffer
          id={videoId}
          dtid={sourceType === 'me' ? hilKey.DTID : videoKey.DTID}
          objectType={objectType}
          sourceType={sourceType}
          projectType={projectType}
          camera={camera}
          lineType={lineType}
          timeChangeCallback={handleDraw}
          handleFetching={handleFetching}
          fetchCallback={handleBufferData}
          setIsPending={setIsPending}
        />
      )}
      <canvas
        height={size.height}
        width={size.width}
        ref={setupWebWorker}
        data-testid={dataTestId}
      />
    </div>
  )
}
