import { forwardRef, Ref } from 'react'
import { secondsToTimeString } from '../../../tslib/secondsToTimeString'
import './style.scss'

interface IProps {
  duration: number
}

export const DisplayElapsedTime = forwardRef(function DisplayElapsedTime(
  { duration }: IProps,
  ref: Ref<HTMLSpanElement>
) {
  return (
    <div data-testid='displayTime' className='display-time-container'>
      <span style={{ width: 80 }} data-testid='elapsedTime' ref={ref}>
        {secondsToTimeString(0)}
      </span>
      <span style={{ width: 4 }} className='divider'>
        /
      </span>
      <span style={{ width: 80 }} data-testid='totalDuration'>
        {secondsToTimeString(duration)}
      </span>
    </div>
  )
})
