import { ChangeEvent, MouseEvent, useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material'

interface IProps {
  isAuthenticating: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
  showHint: boolean
}

const Password = ({ isAuthenticating, onChange, value, showHint }: IProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <FormControl fullWidth variant='filled'>
      <InputLabel
        sx={{
          '&.Mui-focused': { color: '#e5e5e580' },
          fontSize: '14px',
        }}
        htmlFor='Password'
      >
        Password
      </InputLabel>
      <FilledInput
        autoComplete='off'
        disabled={isAuthenticating}
        id='Password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        sx={{
          color: 'whitesmoke !important',
          backgroundColor: 'rgba(0,0,0,0.25) !important',
        }}
        onChange={onChange}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              disabled={isAuthenticating}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? (
                <VisibilityOff sx={{ color: '#e5e5e5B3' }} />
              ) : (
                <Visibility sx={{ color: 'whitesmoke' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {showHint && !value && (
        <FormHelperText sx={{ color: 'red' }}>
          * Please enter a password.
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Password
