import { Dashboard } from '../api/dashboards/dashboards'

const DASHBOARD_KEY = 'dashboard'

export const setDashboards = (data: Dashboard) =>
  window.localStorage.setItem(DASHBOARD_KEY, JSON.stringify(data))

export const getDashboard = () => {
  const dashboardJson = window.localStorage.getItem(DASHBOARD_KEY) || ''
  if (!dashboardJson) return null

  return JSON.parse(dashboardJson)
}
