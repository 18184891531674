import { useCallback } from 'react'
import { useAuthContext } from './Auth/AuthContext'
import { enUS } from '../constants'
import { useClosableSnackbar } from '../utils'

const clearLocalStoragePreserveViewportsState = () => {
  const viewports = window.localStorage.getItem('viewports') || ''
  const detailsLayout = window.localStorage.getItem('detailsLayout') || ''
  window.localStorage.clear()
  window.localStorage.setItem('viewports', viewports)
  window.localStorage.setItem('detailsLayout', detailsLayout)
  window.sessionStorage.clear()
}

export const useTokenExpiredEndSession = () => {
  const wrappedEnqueue = useClosableSnackbar()
  const logout = useLogout()

  return useCallback(() => {
    logout()
    wrappedEnqueue('info', enUS.USER_ON_IDLE)
    // notify all open tabs and logout
  }, [logout, wrappedEnqueue])
}

export const useOnIdle = () => {
  const { isAuthenticated } = useAuthContext()
  const wrappedEnqueue = useClosableSnackbar()
  const logout = useLogout()

  return useCallback(async () => {
    // only fire these functions when user is logged in
    if (isAuthenticated) {
      logout()
      wrappedEnqueue('info', enUS.USER_ON_IDLE)
    }
  }, [isAuthenticated, logout, wrappedEnqueue])
}

export const useLogout = () => {
  const { setAuthContext } = useAuthContext()

  return useCallback(async () => {
    // clear auth context
    setAuthContext({})
    // clear local storage state
    clearLocalStoragePreserveViewportsState()
    // clear redux state
  }, [setAuthContext])
}
