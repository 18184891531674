import { useContext, useImperativeHandle, useState } from 'react'
import { VideoWithCanvas } from './VideoWithCanvas'
import { createVideoPath } from '../../api'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { DoubleBufferContext, DriveTrial } from '../../details'
import { ObjectColor } from '../../details/types'

interface IProps {
  id: number
  viewportId: number
  videoName?: string
  colors?: ObjectColor[]
  title: string
  synchronizer?: ISynchronizer
}

export const BufferedVideo = ({
  id,
  viewportId,
  videoName,
  colors = [],
  title,
  synchronizer,
}: IProps) => {
  const { firstRef, secondRef, doubleBufferRef } =
    useContext(DoubleBufferContext)
  const [data, setData] = useState<DriveTrial>()
  const wrapperRef = id === 0 ? firstRef : secondRef

  useImperativeHandle(
    doubleBufferRef,
    () => ({
      ...doubleBufferRef.current,
      [`${id === 0 ? 'setBufferOne' : 'setBufferTwo'}`]: setData,
    }),
    // supposed to be triggered only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div
      ref={wrapperRef}
      style={{
        visibility: id === 0 ? 'visible' : 'hidden',
      }}
    >
      {data !== undefined ? (
        <VideoWithCanvas
          videoId={data.id}
          playerId={id.toString()}
          videoKey={data?.key}
          hilKey={data?.hilKey}
          playbackRate={1}
          colors={colors}
          isFullscreen={false}
          viewportId={viewportId}
          title={title}
          synchronizer={synchronizer}
          url={createVideoPath(data.key.DTID, videoName ?? '')}
        />
      ) : null}
    </div>
  )
}
