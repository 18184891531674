import { Cell } from '@tanstack/react-table'
import { ReportType } from '../../pages/Report/types'
import { toDateTimeString, toTimeString } from '../../utils'

export const csvAndPdfTransformer = (
  cell: Cell<any, unknown>,
  reportType: ReportType | undefined
) => {
  const header = cell.column.columnDef.header
  const superHeader = cell.column.parent?.columnDef.header
  const value: any = cell.getValue()

  if (value === null) {
    return '-'
  }

  if (superHeader === 'Signal') {
    if (!value.metricFloatAvg) {
      return 'No Data'
    } else if (value.metricFloatAvg > 0 && value.metricFloatAvg < 1) {
      return 'Missing Data'
    } else if (value.metricFloatAvg === 1) {
      return 'Available Data'
    }
  } else if (
    header === 'Start Date-Time' ||
    header === 'End Date-Time' ||
    header === 'Date Processed'
  ) {
    return toDateTimeString(value)
  } else if (header === 'Drive Time') {
    return toTimeString(value)
  } else if (typeof value === 'object') {
    if (!reportType) {
      return value.tableText ?? '-'
    } else {
      if (value.avgFlag === 1) {
        return value.metricFloatAvg ?? '-'
      } else {
        return value.compoundLabel ?? '-'
      }
    }
  }

  return value ?? '-'
}
