import { ArrowPosition } from '../../ui_toolkit/PopupMenu/PopupMenu'

export interface MenuProps {
  arrowPosition?: ArrowPosition
  closeHandler: () => void
}

export const commonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  color: 'whitesmoke',
  width: '100%',
}

export const menuItemStyle = {
  hoverColor: 'rgba(0,0,0,0.2)',
  dividerColor: '#e5e5e580',
}

export interface MousePosition {
  mouseX: number
  mouseY: number
}

export interface MenuRef {
  spawn: (
    target: HTMLElement | undefined,
    mousePosition?: MousePosition
  ) => void
}
