import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewportContent } from '../../../details/types'
import { getClientID } from '../../../storage/clientIdStorage'
import {
  getViewportLocalStorageState,
  setNewState,
  setMapStyle,
} from '../viewportStateUtils'

export interface SliceState {
  [viewportKey: number]: ViewportContent
  layoutName?: string
}

const clientID = getClientID()
const localStorageSavedState = getViewportLocalStorageState()

const initialState: SliceState = !localStorageSavedState
  ? clientID === '102'
    ? {
        1: ViewportContent.LEFT_CAMERA,
        2: ViewportContent.FRONT_CAMERA,
        3: ViewportContent.RIGHT_CAMERA,
        4: ViewportContent.TIMELINE,
        5: ViewportContent.REAR_CAMERA,
        6: ViewportContent.MAP,
        7: ViewportContent.MAP,
      }
    : {
        1: ViewportContent.FRONT_CAMERA,
        2: ViewportContent.MAP,
        3: ViewportContent.TIMELINE,
        4: ViewportContent['3D_VIEW'],
        5: ViewportContent.REAR_CAMERA,
        6: ViewportContent.LEFT_CAMERA,
        7: ViewportContent.RIGHT_CAMERA,
      }
  : {
      1: localStorageSavedState[1].content,
      2: localStorageSavedState[2].content,
      3: localStorageSavedState[3].content,
      4: localStorageSavedState[4].content,
      5: localStorageSavedState[5].content,
      6: localStorageSavedState[6].content,
      7: localStorageSavedState[7].content,
    }

interface ViewportContentChangeAction {
  id: number
  content: ViewportContent
}

interface ViewportMapStyleChangeAction {
  id: number
}

const viewportSlice = createSlice({
  name: 'viewportSlice',
  initialState,
  reducers: {
    viewportContentChange: (
      state: SliceState,
      action: PayloadAction<ViewportContentChangeAction>
    ) => {
      state[action.payload.id] = action.payload.content
      setNewState(action.payload.id, action.payload.content)
    },

    viewportNameChange: (state: SliceState, action: PayloadAction<string>) => {
      state.layoutName = action.payload
    },

    viewportMapStyleChange: (
      _state: SliceState,
      action: PayloadAction<ViewportMapStyleChangeAction>
    ) => {
      setMapStyle(action.payload.id)
    },
  },
})

export const {
  viewportContentChange,
  viewportNameChange,
  viewportMapStyleChange,
} = viewportSlice.actions
export default viewportSlice.reducer
