import { useContext, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDefaultGridOption } from './detailsUtils'
import { useClientViewportMenuQuery } from '../../api'
import { DetailViewport } from '../../components/DetailViewport/DetailViewport'
import { GridLayout } from '../../components/GridLayout/GridLayout'
import Header from '../../components/Header/Header'
import { buildLayoutData } from '../../components/LayersPanel/utils'
import { TransportControls } from '../../components/TransportControls/TransportControls'
import { layerColorsData } from '../../constants/layerColorsData'
import { Synchronizer } from '../../dataStructure/synchronizer/synchronizer'
import {
  DriveTrialDataProvider,
  TimelineContextProviderControlled,
  TopViewProvider,
  useDriveTrialContext,
} from '../../details'
import { MediaSyncContext } from '../../details/types'
import { getClientID } from '../../storage/clientIdStorage'
import { viewportNameChange } from '../../store/details/viewport/viewportSlice'
import { getViewportLocalStorageState } from '../../store/details/viewportStateUtils'
import {
  GridOption,
  gridOptions,
} from '../../ui_toolkit/GridSelectors/GridSelectors'
import './style.scss'

const pauseVideoViewports = (synchronizer: Synchronizer | undefined) => {
  const viewports = getViewportLocalStorageState()
  const videoKeys: number[] = []
  Object.entries(viewports!).forEach(([key, value]) => {
    if (value.content === 1) {
      videoKeys.push(+key)
    }
  })
  videoKeys.forEach((viewport) =>
    synchronizer?.updateStatus(viewport, false, () => {}, true)
  )
}

export const DetailsBase = () => {
  const { modeKey, synchronizerRef, redirectData } = useDriveTrialContext()
  const mediaSyncContext = useContext(MediaSyncContext)
  const dispatch = useDispatch()
  const clientID = getClientID()
  const initialLayer: GridOption = getDefaultGridOption(clientID)
  const [grid, setGrid] = useState<GridOption>(initialLayer)
  const [prevGrid, setPrevGrid] = useState<GridOption>(initialLayer)
  const [fullscreen, setFullscreen] = useState<number>()
  const [synchronizer, setSynchronizer] = useState<Synchronizer>()
  const layerPanelData = buildLayoutData(layerColorsData)
  const { data: menuOptions = [] } = useClientViewportMenuQuery()

  useImperativeHandle(
    synchronizerRef,
    () => ({
      pauseVideos: () => pauseVideoViewports(synchronizer),
    }),
    [synchronizer]
  )

  useEffect(() => {
    const { sendToDetailsData } = redirectData.rows[0]
    let newTime = 0
    if (sendToDetailsData) {
      const { sectionId, detailId } = sendToDetailsData
      newTime += sectionId ? sectionId * 20 : 0
      newTime += detailId ? detailId * 5 : 0
    }
    if (mediaSyncContext && mediaSyncContext.time) {
      console.info('sekcije, detalji: ', newTime)
      mediaSyncContext.time.update({
        velocity: 0,
        position: newTime,
      })
    }
  }, [])

  useEffect(() => {
    setSynchronizer(new Synchronizer(grid.value, mediaSyncContext, fullscreen))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.value, fullscreen])

  const goFullscreen = (id: number) => {
    setFullscreen(id)
    setPrevGrid(grid)
    setGrid({ value: [1, 0], name: '1/0' })
  }

  const exitFullScreen = () => {
    setFullscreen(undefined)
    setGrid(prevGrid)
  }

  const changeGrid = (option: GridOption) => {
    dispatch(viewportNameChange(option.name))
    setGrid(option)
  }

  return (
    <div className='dp-container'>
      <Header gridChangeHandler={changeGrid} fullscreen={fullscreen} />
      <TimelineContextProviderControlled>
        <TopViewProvider key={grid.name}>
          {fullscreen ? (
            <GridLayout option={grid}>
              <DetailViewport
                colors={layerColorsData}
                layerPanelData={layerPanelData}
                goFullscreen={goFullscreen}
                exitFullScreen={exitFullScreen}
                isFullscreen={Boolean(fullscreen)}
                id={fullscreen}
                menuOptions={menuOptions}
                grid={grid}
                synchronizer={synchronizer}
              />
              <></>
            </GridLayout>
          ) : (
            <GridLayout option={grid}>
              {gridOptions.map(({ name }, index) => (
                <DetailViewport
                  key={name}
                  colors={layerColorsData}
                  layerPanelData={layerPanelData}
                  goFullscreen={goFullscreen}
                  exitFullScreen={exitFullScreen}
                  isFullscreen={Boolean(fullscreen)}
                  id={index + 1}
                  menuOptions={menuOptions}
                  grid={grid}
                  synchronizer={synchronizer}
                />
              ))}
            </GridLayout>
          )}
          <TransportControls key={modeKey} synchronizer={synchronizer} />
        </TopViewProvider>
      </TimelineContextProviderControlled>
    </div>
  )
}

export const Details = () => (
  <DriveTrialDataProvider>
    <DetailsBase />
  </DriveTrialDataProvider>
)
