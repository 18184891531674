import { useEffect, useState } from 'react'
import { grafanaBaseUrl } from '../../api/base'
import { useDashboardsQuery } from '../../api/dashboards/dashboards'
import { setProjectID, setProjectType } from '../../storage/projectIdStorage'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import NoData from '../NoData/NoData'
import './style.scss'

export default function Dashboard() {
  setProjectID(1009)
  setProjectType('endurance')
  const { data, isLoading, isFetching, isError } = useDashboardsQuery()
  const [dashboard, setDashboard] = useState('')

  useEffect(() => {
    if (data) {
      setDashboard(data[0].url)
    }
  }, [data])

  if (isLoading || isFetching) {
    return <Loader text='Loading dashboard' center />
  }

  if (isError) {
    return <NoData languageCode='enUS' />
  }

  return (
    <iframe src={`${grafanaBaseUrl}/${dashboard}`} width='100%' height='100%' />
  )
}
