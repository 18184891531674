import { MouseEvent, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import { Header, flexRender } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import { Menu } from './Menu'
import { Sorting } from './Sorting'
import {
  StyledHeaderCellLayout,
  StyledTableHeaderCell,
} from './StyledComponentsTable'
import { TableRouteParams } from '../../../constants'
import { StyledTooltip } from '../../../ui_toolkit/StyledTooltip/StyledTooltip'
import { useReportContext } from '../providers/ReportContextProvider'
import { createHeaderId } from '../utils'

interface HeaderCellProps {
  header: Header<any, unknown>
}

export function HeaderCell({ header }: HeaderCellProps) {
  const { reportType } = useParams<TableRouteParams>()
  const { setPreserveState, preserveState, breadcrumbs, headerTooltips } =
    useReportContext()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const headerId = createHeaderId(header)
  const correspondingTooltip = headerTooltips
    ? headerTooltips[header.column.columnDef.header as string]
    : undefined

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDisplayMode = (
    type: 'bars' | 'line' | 'value' | 'value&bars' | 'value&line'
  ) => {
    if (type === 'value') {
      if (!preserveState[reportType!].displayModeColumns) {
        handleClose()
        return
      }

      const { [header.id]: _, ...newColumns } =
        preserveState[reportType!].displayModeColumns!

      setPreserveState({
        ...preserveState,
        [reportType!]: {
          ...preserveState[reportType!],
          displayModeColumns: newColumns,
        },
      })

      handleClose()
      return
    }

    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        displayModeColumns: {
          ...preserveState[reportType!].displayModeColumns,
          [header.id]: type as 'bars' | 'line',
        },
      },
    })

    handleClose()
  }

  const renderSorting = () =>
    header.column.getCanSort() &&
    header.column.id !== 'select' && <Sorting header={header} />

  const renderPinning = () =>
    !header.isPlaceholder &&
    header.column.getCanPin() && (
      <IconButton
        data-testid={`header-menu-${headerId}`}
        sx={{ padding: 0 }}
        onClick={(e) => handleClick(e)}
      >
        <MoreVertIcon fontSize='small' sx={{ color: '#141414' }} />
      </IconButton>
    )

  const renderText = () =>
    !header.isPlaceholder &&
    flexRender(header.column.columnDef.header, header.getContext())

  return (
    <StyledTableHeaderCell colSpan={header.colSpan}>
      <StyledHeaderCellLayout data-testid={headerId} component='div'>
        <div>
          {correspondingTooltip ? (
            <StyledTooltip title={correspondingTooltip} placement='top'>
              <span>
                {renderText() === 'DTID' && breadcrumbs.length === 2
                  ? 'SectionID'
                  : renderText() === 'DTID' && breadcrumbs.length === 3
                  ? 'DetailID'
                  : renderText()}
                {renderSorting()}
              </span>
            </StyledTooltip>
          ) : (
            <span>
              {renderText() === 'DTID' && breadcrumbs.length === 2
                ? 'SectionID'
                : renderText() === 'DTID' && breadcrumbs.length === 3
                ? 'DetailID'
                : renderText()}
              {renderSorting()}
            </span>
          )}
        </div>
        {renderPinning()}
      </StyledHeaderCellLayout>
      <Menu
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleDisplayMode={handleDisplayMode}
        open={open}
        header={header}
      />
    </StyledTableHeaderCell>
  )
}
