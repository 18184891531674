import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { P, match } from 'ts-pattern'
import { urls } from './urls'
import {
  Header,
  HeadersResponse,
  ReportType,
  SuperHeader,
  TableHeaders,
} from '../pages/Report/types'
import { useAuthContext } from '../security'

const staticHeaders: SuperHeader[] = [
  {
    name: 'Drive Trial Identification',
    subheaders: [
      {
        name: 'DTID',
      },
      {
        name: 'ParentDTID',
      },
      {
        name: 'Description',
      },
      {
        name: 'Version',
      },
    ],
  },
]

const staticCompletenessHeaders: SuperHeader[] = [
  ...staticHeaders,
  {
    name: 'Drive Data',
    subheaders: [
      {
        name: 'Start Date-Time',
      },
      {
        name: 'End Date-Time',
      },
      {
        name: 'Vehicle',
      },
      {
        name: 'Date Processed',
      },
      {
        name: 'Drive Time',
      },
    ],
  },
]

const staticQualityCharacterizationHeaders: SuperHeader[] = [
  ...staticHeaders,
  {
    name: 'Drive Data',
    subheaders: [
      {
        name: 'Start Date-Time',
      },
      {
        name: 'End Date-Time',
      },
      {
        name: 'Vehicle',
      },
      {
        name: 'Date Processed',
      },
    ],
  },
]

const getInitialHeaders = (type: ReportType) =>
  match(type)
    .with(P.union('lanes', 'signs', 'lights'), () => staticHeaders)
    .with('completeness', () => staticCompletenessHeaders)
    .otherwise(() => staticQualityCharacterizationHeaders)

const groupHeadersByReport = (headers: Header[]) =>
  headers.reduce((result, currentValue) => {
    const { reportID: reportId } = currentValue
    if (!result[reportId]) {
      result[reportId] = []
    }
    result[reportId].push(currentValue)
    return result
  }, {} as TableHeaders)

export const groupHeaders = (
  headers: Header[],
  headerType: ReportType
): SuperHeader[] => {
  if (!headers?.length) return []

  const groupedArray: SuperHeader[] = [...getInitialHeaders(headerType)]

  headers.forEach((current) => {
    const { parentID, ...rest } = current
    if (parentID !== null && parentID !== undefined) {
      const parentIndex = groupedArray.findIndex((item) => item.id === parentID)

      if (parentIndex === -1) {
        groupedArray.push({
          id: parentID,
          name: '',
          subheaders: [rest],
        })
      } else {
        groupedArray[parentIndex].subheaders.push(rest)
      }
    } else {
      const parent = groupedArray.find((x) => x.id === parentID)

      if (parent) {
        parent.name = rest.name
      } else {
        groupedArray.push({ ...rest, subheaders: [] })
      }
    }
  })

  return groupedArray
}
export const useHeadersQuery = () => {
  const { isAuthenticated } = useAuthContext()
  return useQuery({
    queryKey: ['headers'],
    staleTime: Infinity,
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<TableHeaders | undefined> =>
      axios.get<HeadersResponse>(urls.reportHeaders).then(({ data }) => {
        if (!data?.reportHeaders?.length) return undefined
        return groupHeadersByReport(data.reportHeaders)
      }),
  })
}
