import { Anchor } from '../../ui_toolkit/DrawerMenu/DrawerMenu'

export type Variants = 'permanent' | 'persistent' | 'temporary'

export type IconDefinition = Array<{
  icon: JSX.Element
  callback: () => void
  opened: boolean
  title: string
  disabled: boolean
}>

export interface ToolboxProps {
  anchor?: Anchor
  variant?: Variants
  onDrawerToggle: (isOpen: boolean) => void
}

export interface TabsRoutes {
  [key: string]: string
}

export const iconStyles = {
  color: 'whitesmoke',
  lineHeight: '0.85rem',
}

export const iconStylesDisabled = {
  color: 'gray',
  lineHeight: '0.85rem',
}

export type ToolbarItem =
  | 'Reports'
  | 'Columns'
  | 'Search'
  | 'Advanced Search'
  | ''
