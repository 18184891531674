import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { IconButton } from '@mui/material'
import { Header } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import { TableRouteParams } from '../../../constants'
import { useReportContext } from '../providers/ReportContextProvider'
import { createHeaderId } from '../utils'

interface SortingProps {
  header: Header<any, unknown>
}

export function Sorting({ header }: SortingProps) {
  const {
    searchColumns,
    setPreserveState,
    preserveState,
    setPage,
    breadcrumbs,
  } = useReportContext()
  const { reportType } = useParams<TableRouteParams>()
  const sortDirection = header.getContext().column.getIsSorted()
  const headerValue = header.getContext().column.columnDef.header?.toString()
  const headerParentValue = header
    .getContext()
    .column.parent?.columnDef.header?.toString()
  const breadCrumbsLevel = breadcrumbs.length

  const findSortingValue = () => {
    if (!headerValue || !headerParentValue) {
      return
    }

    if (reportType === 'completeness') {
      return searchColumns.find((x) => x.includes(headerValue))?.split('|')[1]
    } else if (reportType === 'quality' || reportType === 'characterization') {
      if (
        headerParentValue === 'Drive Trial Identification' ||
        headerParentValue === 'Drive Data' ||
        headerParentValue === 'Tags' ||
        headerParentValue === 'Gps Speed'
      ) {
        if (
          headerValue === 'DTID' &&
          (breadCrumbsLevel === 2 || breadCrumbsLevel === 3)
        ) {
          return searchColumns[0].split('|')[1]
        }
        return searchColumns.find((x) => x.includes(headerValue))?.split('|')[1]
      }

      const toLowerSearchColumns = searchColumns.map((x) =>
        x.toLocaleLowerCase()
      )

      return toLowerSearchColumns
        .find(
          (x) =>
            x.includes(headerValue.toLocaleLowerCase()) &&
            x.includes(headerParentValue.toLocaleLowerCase().replace(/ /g, ''))
        )
        ?.split('|')[1]
    } else {
      if (headerParentValue === 'Drive Trial Identification') {
        return headerValue
      } else {
        return `${headerParentValue}|${headerValue}`
      }
    }
  }

  const handleSorting = () => {
    let sortBy = findSortingValue()

    if (sortBy === 'geo_country_compoundlabel') {
      sortBy = 'Geo_Country_CompoundLabel'
    }

    const newSortDirection =
      !sortDirection || sortDirection === 'asc' ? 'desc' : 'asc'

    const sortingConfig = {
      sorting: [{ id: header.id, desc: newSortDirection === 'desc' }],
      fetchByOrder: { sortBy, sortDirection: newSortDirection },
    }

    if (sortBy) {
      let newState

      if (breadCrumbsLevel === 2) {
        newState = { sections: sortingConfig }
      } else if (breadCrumbsLevel === 3) {
        newState = { details: sortingConfig }
      } else {
        newState = sortingConfig
      }

      setPreserveState({
        ...preserveState,
        [reportType!]: {
          ...preserveState[reportType!],
          ...newState,
        },
      })
    }

    setPage(1)
  }

  return (
    <IconButton
      data-testid={`sorting-${sortDirection}-${createHeaderId(header)}`}
      sx={{ padding: 0, marginRight: 3 }}
      onClick={handleSorting}
    >
      {{
        asc: <ArrowUpwardIcon sx={{ color: '#141414' }} fontSize='small' />,
        desc: <ArrowDownwardIcon sx={{ color: '#141414' }} fontSize='small' />,
      }[header.column.getIsSorted() as string] ?? (
        <UnfoldMoreIcon sx={{ color: '#b7b7b7' }} fontSize='small' />
      )}
    </IconButton>
  )
}
