import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { authApi } from './base'
import { urls } from './urls'

export interface LoginRequest {
  email?: string
  password?: string
  googleToken?: string
  microsoftToken?: string
}
export interface LoginResponse extends RefreshTokensResponse {
  company: {
    id: number
    name: string
  }
}

interface RefreshTokenRequest {
  accessToken: string
  refreshToken: string
}

interface RefreshTokensResponse {
  tokens: {
    accessToken: string
    refreshToken: string
    accessTokenExpiresIn: number
    refreshTokenExpiresIn: number
  }
}

export const useLoginMutation = () => {
  const {
    mutateAsync: login,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['login'],
    mutationFn: (data: LoginRequest): Promise<AxiosResponse<LoginResponse>> =>
      authApi.post(urls.signIn, data),
  })

  return { login, data, isPending }
}

export const useRefreshTokenMutation = () => {
  const {
    mutateAsync: refreshToken,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['refreshToken'],
    mutationFn: (
      data: RefreshTokenRequest
    ): Promise<AxiosResponse<RefreshTokensResponse>> =>
      authApi.post(
        urls.refreshToken,
        { refreshToken: data.refreshToken },
        { headers: { 'x-access-token': data.accessToken } }
      ),
  })

  return { refreshToken, data, isPending }
}
