import { PropsWithChildren } from 'react'
import { StyledTooltip } from '../../../ui_toolkit/StyledTooltip/StyledTooltip'

interface IProps {
  textAlign: 'left' | 'center' | 'right'
  tooltip?: string
}

export const StyledDiv = ({
  textAlign,
  tooltip,
  children,
}: PropsWithChildren<IProps>) => {
  const content = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: textAlign,
      }}
    >
      {children}
    </div>
  )

  return tooltip && tooltip !== '-' ? (
    <StyledTooltip
      title={tooltip}
      showArrow={false}
      followCursor={true}
      enterDelay={50}
      placement='bottom'
    >
      {content}
    </StyledTooltip>
  ) : (
    content
  )
}
