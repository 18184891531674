import { InputLabel, Stack } from '@mui/material'
import { Dayjs } from 'dayjs'
import { date, datePickerFormat } from '../../../constants'
import { ReactDateRange } from '../../../ui_toolkit/Inputs/ReactDateRange/ReactDateRange'
import { DateRangeData } from '../../../ui_toolkit/Inputs/types'
import { dateToYYYYMMDD, stringToDayJs } from '../../../utils'
import { FilterProps } from '../types'

export const GeneralSelectors = ({ onChange, filters }: FilterProps) => {
  const dateRange = filters.generalFilters?.dateRange.map(
    (x) => stringToDayJs(x) ?? null
  ) as DateRangeData<Dayjs>

  const processedDateRange = filters.generalFilters?.processedDateRange.map(
    (x) => stringToDayJs(x) ?? null
  ) as DateRangeData<Dayjs>

  const handleChangeDateTime = (
    val: DateRangeData<Dayjs>,
    fieldName: string
  ) => {
    onChange({
      ...filters,
      generalFilters: {
        ...filters.generalFilters,
        [fieldName]: val?.map((x) => {
          if (dateToYYYYMMDD(x) === 'Invalid Date') {
            return null
          }
          return dateToYYYYMMDD(x)
        }),
      },
    })
  }

  return (
    <Stack direction='column' spacing={2}>
      <div style={{ position: 'relative' }}>
        <InputLabel
          sx={{
            marginBottom: 1,
          }}
        >
          Date
        </InputLabel>
        <ReactDateRange
          dateFormat={datePickerFormat}
          placeholder={date}
          onChange={(val) => handleChangeDateTime(val, 'dateRange')}
          dateRangeValues={dateRange}
        />
      </div>

      <div style={{ position: 'relative' }}>
        <InputLabel
          sx={{
            marginBottom: 1,
          }}
        >
          Processed date
        </InputLabel>
        <ReactDateRange
          dateFormat={datePickerFormat}
          placeholder={date}
          onChange={(val) => handleChangeDateTime(val, 'processedDateRange')}
          dateRangeValues={processedDateRange}
        />
      </div>
    </Stack>
  )
}
