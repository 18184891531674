import './style.scss'

interface IProps {
  min?: number
  max: number
  onChange: (value: number) => void
  onRender?: (node: HTMLInputElement) => void
}

export const Slider = ({ max, min, onChange, onRender }: IProps) => (
  <input
    data-testid='contorlsSlider'
    defaultValue={0}
    id='slider-range-input'
    type='range'
    step={'any'}
    min={min}
    max={max}
    aria-label='Default'
    onChange={(e) => {
      onChange(parseInt(e.target.value))
    }}
    ref={(node: HTMLInputElement) => {
      if (onRender) {
        onRender(node)
      }
    }}
  />
)
