import { match } from 'ts-pattern'
import {
  EnabledLayers,
  TopDownData,
  ViewportContent,
} from '../../details/types'
import { getClientID } from '../../storage/clientIdStorage'

type MapStyle = 'street' | 'satellite'

interface ViewportState {
  content: ViewportContent
  layerState: EnabledLayers
  topDown: TopDownData
  mapStyle: MapStyle
}

export interface DetailsPageState {
  [viewportId: number]: ViewportState
  synced: boolean
}

const clientID = getClientID()

const valeoChoice: DetailsPageState = {
  1: {
    content: ViewportContent.FRONT_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  2: {
    content: ViewportContent.LEFT_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  3: {
    content: ViewportContent.REAR_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  4: {
    content: ViewportContent.RIGHT_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  5: {
    content: ViewportContent.TIMELINE,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  6: {
    content: ViewportContent.MAP,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  7: {
    content: ViewportContent.MAP,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  synced: false,
}

const normalChoice: DetailsPageState = {
  1: {
    content: ViewportContent.FRONT_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  2: {
    content: ViewportContent.MAP,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  3: {
    content: ViewportContent.TIMELINE,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  4: {
    content: ViewportContent['3D_VIEW'],
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  5: {
    content: ViewportContent.REAR_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  6: {
    content: ViewportContent.LEFT_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  7: {
    content: ViewportContent.RIGHT_CAMERA,
    layerState: { me: [], otto: [] },
    topDown: { lane: 'both', source: 'both' },
    mapStyle: 'street',
  },
  synced: false,
}

const defaultLocalStorageState = match(clientID)
  .with('102', () => valeoChoice)
  .otherwise(() => normalChoice)

if (!localStorage.getItem('viewports')) {
  localStorage.setItem('viewports', JSON.stringify(defaultLocalStorageState))
}

export const getViewportLocalStorageState = ():
  | DetailsPageState
  | undefined => {
  const stringData = localStorage.getItem('viewports')
  if (!stringData) {
    return
  }

  const currentState: DetailsPageState = JSON.parse(stringData)

  return currentState
}

export const setNewState = (
  viewportId: number,
  content?: ViewportContent,
  data?: EnabledLayers | TopDownData
) => {
  const currentState = getViewportLocalStorageState()
  if (!currentState) {
    return
  }

  if (content !== undefined) {
    currentState[viewportId].content = content
  }

  if (!data) {
    localStorage.setItem('viewports', JSON.stringify(currentState))
    return
  }

  if ('source' in data) {
    currentState[viewportId].topDown = data as TopDownData
  } else if ('otto' in data) {
    currentState[viewportId].layerState = data as EnabledLayers
  }

  localStorage.setItem('viewports', JSON.stringify(currentState))
}

export const setMapStyle = (viewportId: number) => {
  const currentState = getViewportLocalStorageState()
  if (!currentState) {
    return
  }

  currentState[viewportId].mapStyle =
    currentState[viewportId].mapStyle === 'street' ? 'satellite' : 'street'
  localStorage.setItem('viewports', JSON.stringify(currentState))
}

export const setSynced = (areSynced: boolean) => {
  const currentState = getViewportLocalStorageState()
  if (!currentState) {
    return
  }

  currentState.synced = areSynced

  localStorage.setItem('viewports', JSON.stringify(currentState))
}
