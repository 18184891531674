import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import { JUMP_FRAME_TIME_OFFSET } from './utils'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { SamplerReference, useDriveTrialContext } from '../../details'
import { getFrameRate } from '../../details/config'
import {
  MediaSyncContext,
  PlayControls as PlayControlsRef,
} from '../../details/types'
import { getClientID } from '../../storage/clientIdStorage'
import { getProjectType } from '../../storage/projectIdStorage'
import { TimingProgress } from '../../timing/timing'
import { secondsToTimeString } from '../../tslib/secondsToTimeString'
import { Slider } from '../../ui_toolkit/Inputs/Slider/Slider'
import { DisplayElapsedTime } from '../../ui_toolkit/VideoControls/DisplayElapsedTime/DisplayElapsedTime'
import { JogWheel } from '../../ui_toolkit/VideoControls/JogWheel/JogWheel'
import { PlayControls } from '../../ui_toolkit/VideoControls/PlayControls/PlayControls'
import { TimelineMarkControls } from '../../ui_toolkit/VideoControls/TimelineMarkControls/TimelineMarkControls'
import './transportControls.scss'
import { toSeconds } from '../../utils'
import { Shortcuts } from '../Shortcuts/Shortcuts'

export const TransportControls = ({
  synchronizer,
}: {
  synchronizer?: ISynchronizer | undefined
}) => {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, driveTrials, highlightMode } =
    useDriveTrialContext()
  const durationRef = useRef<HTMLSpanElement>(null)
  const playRef = useRef<PlayControlsRef>(null)
  const frameRef = useRef<HTMLSpanElement>(null)
  const playbackRate = useRef<number>(1)
  const projectType = getProjectType()
  const frameRate = getFrameRate(projectType)
  const clientID = getClientID()
  const [currentFrame, setCurrentFrame] = useState<number | null>(null)
  const [upDownArrowPressed, setUpDownArrowPressed] = useState(false)
  const [frameInputOnFocus, setFrameInputOnFocus] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const prepareTimingData = (sliderRef: HTMLInputElement) => {
    if (!sliderRef) {
      return
    }

    const p = new TimingProgress(mediaSyncContext.time, sliderRef, {
      sampler: mediaSyncContext.sampler,
      range: [0, mediaSyncContext.totalDuration ?? 0],
    })

    mediaSyncContext.progress = p
  }

  const throttleChangeTimingPosition = throttle((num: number) => {
    mediaSyncContext.time?.update({
      position: num + JUMP_FRAME_TIME_OFFSET,
      velocity: 0,
    })
  }, 200)

  const setDurationVal = (currentTime: number) => {
    if (
      durationRef.current &&
      mediaSyncContext !== undefined &&
      mediaSyncContext.activeVideo
    ) {
      durationRef.current.innerText = secondsToTimeString(currentTime)
    }
  }

  useEffect(() => {
    let samplerEvent: SamplerReference | null = null

    if (
      mediaSyncContext.sampler !== undefined &&
      mediaSyncContext.totalDuration
    ) {
      // @ts-expect-error Missing correct INTERFACE
      samplerEvent = mediaSyncContext.sampler.on('change', (time: number) => {
        if (frameRef.current) {
          const currentVideo = getCurrentDriveTrial(time)

          if (
            !mediaSyncContext ||
            !mediaSyncContext.activeVideo ||
            !currentVideo
          ) {
            return
          }
          let frame = 0
          const frameOffset = Math.floor(
            currentVideo.previousDuration * frameRate
          )
          if (highlightMode.id === -1) {
            frame = Math.floor((time + currentVideo.playbackSkew) * frameRate)
          } else {
            highlightMode.items
              .get({ returnType: 'Array' })
              .filter(
                (i) =>
                  i.className !== 'exclusion-background' &&
                  !i.id.toString().includes('tag') &&
                  i.group === highlightMode.id &&
                  i.originalStart !== undefined
              )
              .forEach((item) => {
                const start = toSeconds(new Date(item.start).getTime())
                const end = toSeconds(new Date(item.end!).getTime())
                const originalStart = toSeconds(
                  new Date(item.originalStart!).getTime()
                )
                if (start <= time && end >= time) {
                  frame = Math.floor((time - start + originalStart) * frameRate)
                }
              })
          }
          frameRef.current.innerHTML = Math.floor(
            frame - frameOffset
          ).toString()
          setCurrentFrame(parseInt(frameRef.current.innerHTML))
        }
        if (time === mediaSyncContext.totalDuration && playRef.current) {
          playRef.current?.setPlaying(false)
          mediaSyncContext.isPlaying = false
        }

        if (!mediaSyncContext.isPlaying) {
          playRef.current?.setPlaying(false)
        } else {
          playRef.current?.setPlaying(true)
        }

        setDurationVal(time)
      })

      return () => {
        samplerEvent?.terminate()
      }
    }
  }, [driveTrials])

  const onPlayClick = useCallback(
    (isPlaying: boolean) => {
      if (!mediaSyncContext?.time || !mediaSyncContext?.totalDuration) {
        return
      }

      const hasEnded =
        mediaSyncContext.time.query().position >= mediaSyncContext.totalDuration
      if (isPlaying && hasEnded) {
        console.info('usao u kraj i vrati na nulu: ')
        mediaSyncContext.time.update({ position: 0 })
      }

      const anyStillLoading = synchronizer?.anyLoadingViewports() ?? false
      mediaSyncContext.time.update({
        velocity:
          isPlaying && !anyStillLoading ? mediaSyncContext.playbackSpeed : 0,
      })
      mediaSyncContext.isPlaying = isPlaying
    },
    [mediaSyncContext, synchronizer]
  )

  const onChangeTime = (percent: number) => {
    console.info(
      'usao u onChangeTime: ',
      mediaSyncContext.totalDuration,
      percent
    )
    const newPosition = (mediaSyncContext.totalDuration * percent) / 100
    playRef.current?.setPlaying(false)
    mediaSyncContext.time?.update({
      position: newPosition,
    })

    setDurationVal(newPosition)
  }

  const onChangePlaybackRate = (velocity: number) => {
    mediaSyncContext.time?.update({
      velocity,
    })
    playbackRate.current = velocity
    mediaSyncContext.playbackSpeed = velocity
    mediaSyncContext.isPlaying = true
  }

  const onJogMovement = (velocity: number) => {
    if (velocity !== 0) {
      mediaSyncContext.time?.update({
        velocity,
      })
      mediaSyncContext.playbackSpeed = velocity
      mediaSyncContext.isPlaying = true
      playRef.current?.setPlaying(true)
      return
    }

    mediaSyncContext.time?.update({
      velocity: 0,
    })
    mediaSyncContext.playbackSpeed = playbackRate.current
    mediaSyncContext.isPlaying = false
    playRef.current?.setPlaying(false)
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      setUpDownArrowPressed(false)

      const target = event.target as HTMLInputElement
      const newFrameValue = target.value.trim()
      const increment = event.key === 'ArrowUp' ? 1 : -1
      const newFrame = parseInt(newFrameValue, 10) + increment
      if (newFrame > mediaSyncContext.totalDuration * frameRate || newFrame < 0)
        return

      setCurrentFrame(newFrame)
    }
    if (event.key === 'Enter' && frameRef.current) {
      frameRef.current.innerHTML = currentFrame ? currentFrame.toString() : '0'

      const newPosition = (currentFrame ?? 0) / frameRate
      playRef.current?.setPlaying(false)
      throttleChangeTimingPosition(newPosition)
      inputRef.current!.blur()
    }

    if (event.key === ' ') inputRef.current!.blur()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown')
      setUpDownArrowPressed(true)
  }

  const handleFrameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault()
    if (upDownArrowPressed) return
    const newFrameValue = event.target.value.trim()
    if (newFrameValue === '' && frameRef.current) {
      setCurrentFrame(null)
      frameRef.current.innerHTML = '0'
      return
    }

    const newFrame = parseInt(newFrameValue, 10)
    if (!isNaN(newFrame) && frameRef.current) {
      setCurrentFrame(newFrame)
      frameRef.current.innerHTML = newFrame.toString()
    }
  }

  const handleFrameInputFocus = () => {
    onPlayClick(false)
    setFrameInputOnFocus(true)
  }

  const handleFrameInputBlur = () => {
    setFrameInputOnFocus(false)
    frameRef.current!.innerHTML = currentFrame ? currentFrame.toString() : '0'
    const newPosition = (currentFrame ?? 0) / frameRate
    playRef.current?.setPlaying(false)
    throttleChangeTimingPosition(newPosition)
  }

  return (
    <div className='controls-container'>
      <PlayControls
        onPlayPause={onPlayClick}
        onChangePlaybackRate={onChangePlaybackRate}
        ref={playRef}
        frameInputOnFocus={frameInputOnFocus}
      />
      <TimelineMarkControls />
      {clientID && (
        <div
          style={{
            display: 'flex',
            gap: '4px',
            color: 'yellow',
            width: '230px',
          }}
        >
          <span>Frame: </span>
          <span className='frame-ref-span' ref={frameRef}></span>
          <input
            className='frame-input'
            type='number'
            ref={inputRef}
            onFocus={handleFrameInputFocus}
            onBlur={handleFrameInputBlur}
            value={currentFrame ?? ''}
            onChange={handleFrameInputChange}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          />
        </div>
      )}
      <DisplayElapsedTime
        duration={mediaSyncContext.totalDuration}
        ref={durationRef}
      />
      <JogWheel min={-8} max={8} onJog={onJogMovement} />
      {mediaSyncContext.totalDuration && (
        <Slider
          min={0}
          max={100}
          onChange={onChangeTime}
          onRender={prepareTimingData}
        />
      )}
      <Shortcuts />
    </div>
  )
}
