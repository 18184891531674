import {
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade, IconButton, Paper, Popper } from '@mui/material'
import Draggable, { DraggableBounds } from 'react-draggable'
import ResizableBox from '../../components/ResizableBox/ResizableBox'

export type OverlayPosition = 'left' | 'right'

interface iProps {
  closeHandler: () => void
  closeButtonStyle?: object
  isDraggable?: boolean
  hasBoxShadow?: boolean
  btnSpawner?: RefObject<SVGSVGElement | null> | null
  popupPosition?: string
  popupName?: string
  paperStyle?: object
  isResizable?: boolean
  children: ReactNode
}

const PopupOverlay = forwardRef(function PopupOverlay(props: iProps, ref) {
  const {
    closeHandler,
    closeButtonStyle,
    isDraggable = false,
    hasBoxShadow = true,
    children,
    btnSpawner,
    popupName,
    paperStyle,
    isResizable,
  } = props
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const [anchor, setAnchor] = useState<HTMLElement | undefined>(undefined)
  const [bounds, setBounds] = useState<DraggableBounds | false>(false)
  const isOpen = !!anchor

  useImperativeHandle(ref, () => ({
    spawn: (target: HTMLElement) => {
      setAnchor(target)
    },
  }))

  const renderChildren = () => {
    if (!isDraggable) return children
    return <div className={isResizable ? '' : 'handle'}>{children}</div>
  }

  const updateBounds = useCallback(() => {
    if (!isDraggable || !isOpen) return

    const rect = btnSpawner?.current?.getBoundingClientRect()
    if (!rect) return

    const element = overlayRef.current
    if (!element) return

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const isRight = windowWidth / 2 < rect.x

    const updateDimensions = () => {
      const { width, height } = element.getBoundingClientRect()

      setBounds({
        top: -rect.y + 56,
        left: isRight ? -rect.x + width : -rect.x - 40,
        right: isRight ? 48 : windowWidth - rect.x - width - 32,
        bottom: windowHeight - rect.y - height - 45,
      })
    }

    updateDimensions()

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions()
    })

    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [isDraggable, isOpen, btnSpawner, overlayRef])

  useEffect(() => {
    updateBounds()
  }, [updateBounds])

  const setOverlayRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        overlayRef.current = node
        updateBounds()
      }
    },
    [updateBounds]
  )

  return (
    <>
      <Popper
        open={isOpen}
        anchorEl={anchor}
        transition
        placement={'right-start'}
        sx={{
          pointerEvents: 'none',
          position: 'relative',
        }}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'viewport',
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Draggable handle='.handle' bounds={bounds} nodeRef={overlayRef}>
            <Fade
              {...TransitionProps}
              timeout={350}
              style={{
                backgroundColor: 'transparent',
                pointerEvents: 'all',
              }}
            >
              <Paper
                id={popupName}
                ref={setOverlayRef}
                style={{
                  boxShadow: !hasBoxShadow ? 'none' : '',
                  ...paperStyle,
                }}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 2,
                    color: 'white',
                    zIndex: 4,
                    ...closeButtonStyle,
                  }}
                  onClick={closeHandler}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
                {isResizable ? (
                  <ResizableBox
                    initHeight={200}
                    initWidth={300}
                    minHeight={100}
                    minWidth={200}
                  >
                    {renderChildren()}
                  </ResizableBox>
                ) : (
                  renderChildren()
                )}
              </Paper>
            </Fade>
          </Draggable>
        )}
      </Popper>
    </>
  )
})

export default PopupOverlay
