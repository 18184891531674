import { Anchor } from 'mapbox-gl'
import { Marker } from 'react-map-gl'
import './style.scss'

interface PositionMarkerProps {
  DTID: number
  latitude: number
  longitude: number
  color: string
  title: string
  anchor: Anchor
  offsetY: number
  activeBorder: string
}

function PositionMarker({
  DTID,
  latitude,
  longitude,
  color,
  title,
  anchor,
  offsetY,
  activeBorder,
}: PositionMarkerProps) {
  return (
    <>
      <Marker
        longitude={longitude}
        latitude={latitude}
        color={color}
        anchor={anchor}
        offset={[0, 5]}
        scale={0.8}
      ></Marker>
      <Marker
        longitude={longitude}
        latitude={latitude}
        anchor='bottom'
        offset={[0, offsetY]}
      >
        <div style={{ paddingBottom: '10px' }}>
          <span
            className='start-end-marker-text'
            style={{ border: activeBorder }}
          >
            {title} {DTID}
          </span>
        </div>
      </Marker>
    </>
  )
}

export default PositionMarker
