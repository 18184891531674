import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3F4C6B',
    },
    secondary: {
      main: '#7A86A1', // '#516188' | #6779A5
    },
    info: {
      main: '#606c88',
    },
    background: {
      paper: 'linear-gradient(#3F4C6B, #606c88)',
    },
  },

  components: {
    MuiAccordion: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0,0,0,0.7)',
        },
        arrow: {
          color: 'rgba(0,0,0,0.7)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        nativeInput: {
          color: 'white',
        },
        icon: {
          color: 'white',
        },
        iconOpen: {
          color: 'white',
        },
        select: {
          borderColor: 'white',
          color: 'white',
          '&::before': {
            border: 'white',
          },
        },
        outlined: {
          borderColor: 'white',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#3F4C6B',
          color: '#fff',
          borderRadius: '4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.Mui-focused': {
            color: 'white',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderColor: 'white',
          },
          '&::after': {
            borderColor: 'cyan',
          },
          '&:hover': {
            '&::before': {
              borderBottom: '1px solid #98E8FF !important',
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderColor: 'transparent',
          },
          '&::after': {
            borderColor: 'cyan',
          },
          '&:hover': {
            '&::before': {
              borderBottom: '2px solid #606c88 !important',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.06)',
          '&:hover': {
            fieldset: {
              borderColor: 'transparent !important',
              backgroundColor: 'rgba(0,0,0,0.09)',
              borderBottom: '2px solid #606c88 !important',
            },
          },
          '&:active': {
            fieldset: {
              borderColor: 'transparent !important',
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
          },
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#3F4C6B',
          color: '#fff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: 'white',
          borderColor: '#3F4C6B !important',
          '&:hover': {
            fieldset: {
              borderColor: '#3F4C6B !important',
            },
            borderColor: '#3F4C6B !important',
          },
          '&:active': {
            fieldset: {
              borderColor: '#FFF !important',
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFF !important',
          },
        },
      },
    },
  },
})

export default theme
