import _debounce from 'lodash/debounce'
import { initializeViewportSync } from './utils'
import { MediaSync } from '../../details/types'

export interface SyncData {
  [viewportKey: number]: boolean | undefined
}

export interface CanvasData {
  [canvasKey: string]: boolean
}
export interface CanvasSyncData {
  [viewportKey: number]: boolean | CanvasData | undefined
}

export interface DTIDCallbacks {
  [dtid: number]: ((time: number) => void)[]
}

export interface ViewportCallbacks {
  [viewportKey: number]: DTIDCallbacks | undefined
}

export interface ISynchronizer {
  updateStatus: (
    viewportId: number,
    value: boolean | undefined,
    callback?: (() => void) | undefined
  ) => void
  updateCanvasStatus: (
    viewportId: number,
    value: boolean,
    canvasName: string,
    callback?: () => void
  ) => void
  anyLoadingViewports: () => boolean
  isViewportLoading: (viewportId: number) => boolean
  clearUnusedCanvases: (viewportId: number, selectedCanvases: string[]) => void
  addDrawCallback: (
    callback: (time: number) => void,
    viewportId: number,
    videoId: number
  ) => void
  triggerDraw: (time: number, viewportId: number, videoId: number) => void
}

export class Synchronizer implements ISynchronizer {
  private viewportStatuses: SyncData = {}
  private canvasStatuses: CanvasSyncData = {}
  private mediaSync: MediaSync | undefined = undefined
  private viewportWorkers: ViewportCallbacks = {}

  constructor(
    layoutValue: number[],
    mediaSync: MediaSync,
    fullscreen: number | undefined
  ) {
    this.viewportStatuses = fullscreen
      ? { [fullscreen]: false }
      : initializeViewportSync(layoutValue)
    this.canvasStatuses = fullscreen
      ? { [fullscreen]: false }
      : initializeViewportSync(layoutValue, true)
    this.mediaSync = mediaSync
  }

  public updateMediaSync = () => {
    if (!this.mediaSync) return
    const playbackSpeed = this.mediaSync.playbackSpeed
    const anyNonReady = this.anyLoadingViewports()

    if (anyNonReady && this.mediaSync.time?.query().velocity !== 0) {
      this.mediaSync.time?.update({
        velocity: 0,
      })
    } else if (
      !anyNonReady &&
      this.mediaSync.time?.query().velocity === 0 &&
      this.mediaSync.isPlaying
    ) {
      this.mediaSync?.time?.update({
        velocity: playbackSpeed,
      })
    }
  }

  private debaunceUpdate = _debounce(this.updateMediaSync, 500)
  private debaunceCanvasUpdate = _debounce(this.updateMediaSync, 300)

  public updateStatus = (
    viewportId: number,
    value: boolean | undefined,
    callback?: () => void,
    forceChange: boolean = false
  ) => {
    if (this.viewportStatuses[viewportId] === value) return

    this.viewportStatuses = { ...this.viewportStatuses, [viewportId]: value }
    callback?.()

    if (!this.mediaSync) return
    forceChange ? this.updateMediaSync() : this.debaunceUpdate()
  }

  public updateCanvasStatus = (
    viewportId: number,
    value: boolean,
    canvasName: string,
    callback?: () => void
  ) => {
    const canvas = this.canvasStatuses[viewportId]
    if (typeof canvas === 'object' && canvas[canvasName] === value) return

    this.canvasStatuses = {
      ...this.canvasStatuses,
      [viewportId]: {
        ...(this.canvasStatuses[viewportId] as CanvasData),
        [canvasName]: value,
      },
    }

    callback?.()
    if (!this.mediaSync) return

    this.debaunceCanvasUpdate()
  }

  public clearUnusedCanvases = (viewportId: number, canvases: string[]) => {
    const viewportCanvases = this.canvasStatuses[viewportId]
    if (!viewportCanvases || typeof viewportCanvases !== 'object') return

    const filtered = Object.keys(viewportCanvases)
      .filter((key) => canvases.includes(key))
      .reduce((obj, key) => {
        obj[key] = viewportCanvases[key]
        return obj
      }, {} as CanvasData)
    this.canvasStatuses[viewportId] = filtered
  }

  public addDrawCallback = (
    callback: (time: number) => void,
    viewportId: number,
    videoId: number
  ) => {
    const viewport =
      this.viewportWorkers[viewportId] ||
      (this.viewportWorkers[viewportId] = {})
    const dtidCallbacks = viewport[videoId] || (viewport[videoId] = [])
    dtidCallbacks.push(callback)
  }

  public triggerDraw = (time: number, viewportId: number, videoId: number) => {
    const viewport = this.viewportWorkers[viewportId]
    if (!viewport) {
      return
    }
    viewport[videoId]?.forEach((callback) => callback(time))
  }

  public anyCanvasesLoading(canvasData?: CanvasData | boolean) {
    if (typeof canvasData !== 'object') return false
    return Object.values(canvasData).some((value) => value === false)
  }

  public isViewportLoading = (viewportId: number) => {
    const canvasData = this.canvasStatuses[viewportId]
    return (
      this.viewportStatuses[viewportId] === false ||
      this.anyCanvasesLoading(canvasData)
    )
  }

  public anyLoadingViewports = () =>
    Object.values(this.viewportStatuses).some((x) => x === false) ||
    Object.values(this.canvasStatuses).some((x) => this.anyCanvasesLoading(x))
}
