import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { GeoJSONObject } from '../components/TopViewMap/types'

export const useTopViewMapQuery = (activeVideo: number) =>
  useQuery({
    queryKey: ['topViewMapJSON', activeVideo],
    staleTime: Infinity,
    queryFn: (): Promise<GeoJSONObject> =>
      axios
        .get(
          `${urls.topViewS3.replace(
            'url',
            btoa(`Out/otto-version=350h/${activeVideo}/lanes_gps.json`)
          )}`
        )
        .then((resp) =>
          fetch(resp.data.url)
            .then((response) => response.json())
            .then((data) => data)
        ),
  })
