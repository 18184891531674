import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { camelCase } from 'lodash'
import KpiFiltersBlock from './KpiFiltersBlock/KpiFiltersBlock'
import { KpiFilterData, KpiSummaryState } from './types'
import { ProjectName } from '../../models/project'
import { FilterObject } from '../../models/table'
import { INIT_KPI_SUMMARY } from '../../pages/Report/initialStates'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { ReportType } from '../../pages/Report/types'
import { CustomAccordion } from '../../ui_toolkit/Accordion/Accordion'
import {
  StyledApplyBtn,
  StyledBtnContainer,
  StyledResetBtn,
} from '../StyledComponents/buttons'
import { createKpiFilterPayload } from '../Toolbox/utils'

export function KpiFilters({
  project,
  filterData,
  reportType,
}: {
  project: ProjectName
  filterData: KpiFilterData | undefined
  reportType: ReportType
}) {
  const { preserveState, setPreserveState, setPage } = useReportContext()
  const [expanded, setExpanded] = useState('')
  const [filterState, setFilterState] = useState<KpiSummaryState>(
    preserveState[reportType].filterKpiState!
  )

  useEffect(() => {
    setFilterState(preserveState[reportType].filterKpiState!)
    //If kpi filters are open, we need to refresh the data inside the state when the reportType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  const handleExpand = (panel: string) => {
    setExpanded(panel)
  }

  const anyFiltersSelected =
    Object.keys(filterState.filters).length > 0 ||
    filterState.dateRange.some((x) => x !== null)

  const handleFilterChange = (
    fieldName: string,
    value: string | number | string[]
  ) =>
    setFilterState({
      ...filterState,
      filters: {
        ...filterState.filters,
        [fieldName]: value,
      },
    })

  const doFilter = () => {
    if (!filterData) return

    setPage(1)

    const filterPayload = createKpiFilterPayload(
      filterData,
      filterState,
      project,
      `kpi${project}` as ReportType
    )

    const kpiFiltersToCamelCase: FilterObject = {}

    Object.entries(filterPayload.kpiFilters!).forEach(([key, value]) => {
      kpiFiltersToCamelCase[camelCase(key)] = value
    })

    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        filterKpiState: filterState,
        filterKpiToSql: kpiFiltersToCamelCase,
      },
    })
  }

  const resetFilter = () => {
    setPage(1)
    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        filterKpiState: INIT_KPI_SUMMARY,
        filterKpiToSql: {},
      },
    })
    setFilterState(INIT_KPI_SUMMARY)
  }

  if (!filterData) {
    return <div>No filter found</div>
  }
  const { generalFilters, projectFilters } = filterData

  return (
    <Stack spacing={4} direction='column'>
      <div>
        <CustomAccordion
          title='General'
          expanded={expanded}
          onChange={handleExpand}
        >
          <KpiFiltersBlock
            filterData={generalFilters.filters}
            filtersState={filterState}
            onChange={handleFilterChange}
            onDateChange={(value: string[] | null[]) => {
              setFilterState({
                ...filterState,
                dateRange: value,
              })
            }}
            isGeneralFilter={true}
          />
        </CustomAccordion>
        {projectFilters.filters.length > 0 && (
          <CustomAccordion
            title={projectFilters.name}
            expanded={expanded}
            onChange={handleExpand}
          >
            <KpiFiltersBlock
              filterData={projectFilters.filters}
              filtersState={filterState}
              onChange={handleFilterChange}
            />
          </CustomAccordion>
        )}
      </div>
      <StyledBtnContainer spacing={1} direction='column'>
        <StyledApplyBtn
          onClick={doFilter}
          variant='contained'
          fullWidth
          disabled={!anyFiltersSelected}
        >
          Apply
        </StyledApplyBtn>
        <StyledResetBtn onClick={resetFilter} variant='contained' fullWidth>
          Reset
        </StyledResetBtn>
      </StyledBtnContainer>
    </Stack>
  )
}

export default KpiFilters
