import { CellContext } from '@tanstack/react-table'
import { formatNumber } from './CellFormatter'
import { Sparkline } from '../../../ui_toolkit/Sparkline/Sparkline'
import { DisplayModeOptions } from '../types'

interface SparklineFormatterProps {
  cell: CellContext<any, any>
  variant: DisplayModeOptions
}

function SparklineFormatter({ cell, variant }: SparklineFormatterProps) {
  const value = cell.getValue()

  if (value.sparkLinesArray !== '-') {
    const dataArr = value.sparkLinesArray.split(' ').map((x: string) => +x)
    const max = Math.max(...dataArr)

    if (variant === 'value&line') {
      return (
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
        >
          <span style={{ display: 'block', marginRight: 1 }}>
            {value.avgFlag === 1
              ? formatNumber(value.metricFloatAvg)
              : value.compoundLabel}
          </span>
          <Sparkline
            color='#5c6ab8'
            data={dataArr}
            variant='line'
            svgHeight={17}
            svgWidth={150}
            max={max <= 1 ? 1 : undefined}
            min={0}
          />
        </div>
      )
    }

    if (variant === 'value&bars') {
      return (
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
        >
          <span style={{ display: 'block' }}>
            {value.avgFlag === 1
              ? formatNumber(value.metricFloatAvg)
              : value.compoundLabel}
          </span>
          <Sparkline
            color='#5c6ab8'
            data={dataArr}
            variant='bars'
            svgHeight={17}
            svgWidth={150}
            max={max <= 1 ? 1 : undefined}
            min={0}
          />
        </div>
      )
    }

    return (
      <Sparkline
        color='#5c6ab8'
        data={dataArr}
        variant={variant}
        svgHeight={17}
        svgWidth={150}
        max={max <= 1 ? 1 : undefined}
        min={0}
      />
    )
  }

  return <div style={{ textAlign: 'center' }}>Sparkline N/A</div>
}

export default SparklineFormatter
