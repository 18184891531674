import { MouseEvent, useContext, useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { MediaSyncContext } from '../../../details/types'

const playbackRateOptions = [0.25, 0.5, 0.75, 1, 2, 4, 8]

interface IProps {
  onChange: (velocity: number) => void
}

export const PlaybackRateMenu = ({ onChange }: IProps) => {
  const { playbackSpeed } = useContext(MediaSyncContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [value, setValue] = useState(playbackSpeed)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePlaybackRate = (playbackRate: number) => {
    onChange(playbackRate)
    setValue(playbackRate)
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        data-testid='changePlaybackRate'
        variant='text'
        size='large'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: '#f4f4f4',
          textTransform: 'capitalize',
          justifyContent: 'flex-start',
          ':hover': { color: 'white' },
        }}
      >
        {value}x
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableRestoreFocus
      >
        {playbackRateOptions.map((x) => (
          <MenuItem
            data-testid={`handlePlaybackRate-${x}`}
            key={x}
            onClick={() => handlePlaybackRate(x)}
          >
            {x}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
