import { Stack } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ReportTypes } from '../../constants'
import './style.scss'

interface TabsRoutes {
  [key: string]: string
}

const availableTabs: TabsRoutes = {
  'Data Completeness': ReportTypes.COMPLETENESS,
  'Data Quality': ReportTypes.QUALITY,
  Characterizations: ReportTypes.CHARACTERIZATION,
}

const ReportsSelector = () => {
  return (
    <Stack direction='column' alignItems='stretch'>
      {Object.keys(availableTabs).map((t) => {
        return (
          <NavLink
            key={t}
            to={`${availableTabs[t]}`}
            style={{
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            }}
            className={({ isActive }) =>
              isActive ? 'drawer-nav-link active' : 'drawer-nav-link'
            }
          >
            {t}
          </NavLink>
        )
      })}
    </Stack>
  )
}

export default ReportsSelector
