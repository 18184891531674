import { SelectChangeEvent } from '@mui/material'
import { HilVersion } from '../../models/table'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { Select } from '../../ui_toolkit/Inputs/Select/Select'

interface HilPickerProps {
  data: HilVersion
}

export function HilPicker({ data }: HilPickerProps) {
  const { hilVersion, setHilVersion, setPage } = useReportContext()

  const handleHilVersioChange = (event: SelectChangeEvent) => {
    localStorage.setItem('hil', event.target.value)
    setHilVersion(event.target.value)
    setPage(1)
  }

  const allOption = { text: 'All', value: 'all' }
  const versionOptions =
    data?.versions.map((version) => ({ text: version, value: version })) || []
  const options = [allOption, ...versionOptions]

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '16px',
      }}
    >
      <p style={{ marginRight: '16px' }}>HIL:</p>
      <Select
        title=''
        options={options}
        variant='outlined'
        size='small'
        value={hilVersion}
        minWidth={142}
        onChange={handleHilVersioChange}
      />
    </div>
  )
}
