import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'

type UrlResponse = {
  url: string
}

export const useVideoUrlQuery = (videoPath: string) =>
  useQuery({
    queryKey: ['videoUrl', videoPath],
    queryFn: (): Promise<UrlResponse> =>
      axios
        .get<UrlResponse>(urls.files.replace('url', btoa(videoPath)))
        .then(({ data }) => data),
  })
