import { ChangeEvent, useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { isAxiosError } from 'axios'
import { DateType } from 'otto-vis-timeline'
// import MultipleSelectChip from './Chips'
import {
  formControlSx,
  formHelperTextSx,
  textFieldInputPropsSx,
} from './styles.sx'
import { useEditTagMutation } from '../../api'
import {
  EditJiraRequest,
  useCreateJiraMutation,
  useEditJiraMutation,
  useRemoveJiraMutation,
} from '../../api/timeline/jira'
import { useTimelineContext } from '../../details'
import './style.scss'
import { jiraStatusUpdate } from '../VideoTimeline/utils'

interface IProps {
  anchorEl?: HTMLDivElement
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>
  activeTag?: number
  setActiveTag: React.Dispatch<React.SetStateAction<number | undefined>>
  onEdit: (activeTag: number, text: string, jiraID?: string | undefined) => void
  onRemove: (activeTag: number) => void
}

export const TagPopup = ({
  anchorEl,
  setAnchorEl,
  activeTag,
  setActiveTag,
  onEdit,
  onRemove,
}: IProps) => {
  const timelineContext = useTimelineContext()
  const [text, setText] = useState('')
  const [edit, setEdit] = useState(false)
  const [disposition, setDisposition] = useState('otto')
  const [jiraIssueID, setJiraIssueID] = useState<string | undefined>(undefined)
  const [start, setStart] = useState<DateType>(0)
  const [end, setEnd] = useState<DateType>(0)
  const open = Boolean(anchorEl)
  const { createJiraMutation } = useCreateJiraMutation()
  const { removeJiraMutation } = useRemoveJiraMutation()
  const { editJiraMutation } = useEditJiraMutation()
  const { editTagMutation } = useEditTagMutation()
  const id = open ? 'tag-popover' : undefined
  //const jiraProjectID = process.env.REACT_APP_JIRA_PROJECT_ID
  const jiraProjectID = 'MM'

  const handleClose = () => {
    if (activeTag) {
      if (disposition === 'jira' && jiraProjectID) {
        if (jiraIssueID === undefined) {
          const taskData = {
            projectKey: jiraProjectID,
            summary: text,
            description: text,
            issueType: 'Task',
          }
          createJiraMutation(taskData).then((jiraResponse) => {
            if (jiraResponse && jiraResponse.status === 201) {
              const jiraID = jiraResponse.data.issue_key
              setJiraIssueID(jiraID)
              jiraStatusUpdate(
                jiraID,
                timelineContext,
                activeTag,
                editTagMutation
              )
              onEdit(activeTag, text, jiraID)
            }
          })
        } else {
          const editJira = {
            issueKey: jiraIssueID,
            summary: text,
            description: text,
          }
          handleJiraItem(jiraIssueID, editJira)
          onEdit(activeTag, text, jiraIssueID)
        }
      } else {
        onEdit(activeTag, text)
      }
    }
    setAnchorEl(undefined)
    setEdit(false)
    setActiveTag(undefined)
  }

  const handleJiraItem = async (
    jiraIssueID: string,
    editObject?: EditJiraRequest
  ) => {
    try {
      if (!editObject) {
        await removeJiraMutation({ issueKey: jiraIssueID })
      } else {
        await editJiraMutation(editObject)
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.error('Axios error removing Jira issue:', error.message)
      } else {
        console.error('Unknown error removing Jira issue:', error)
      }
    }
  }

  const handleDelete = () => {
    if (activeTag) {
      onRemove(activeTag)
      setAnchorEl(undefined)
      setActiveTag(undefined)
      if (disposition === 'jira' && jiraIssueID) {
        handleJiraItem(jiraIssueID)
      }
    }
  }

  const handleChangeDisposition = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisposition((event.target as HTMLInputElement).value)
  }

  useEffect(() => {
    if (open) {
      const foundItem = timelineContext.items.get(`tag-${activeTag}`)

      if (foundItem) {
        setStart(foundItem.start)
        setEnd(foundItem.end!)
        setText(foundItem.title || '')
        setJiraIssueID(foundItem.jiraID)
        setDisposition(foundItem.jiraID === undefined ? 'otto' : 'jira')
      }
    }
    // On change anchorEl, fill popup with corresponding text
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <div className='MuiDialog-root dialog-container MuiModal-root'>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box
          component='div'
          sx={{
            padding: 1,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          {end ? (
            <>
              <FormControl sx={formControlSx}>
                <FormLabel sx={{ fontSize: '0.75rem' }} focused={false}>
                  Disposition:
                </FormLabel>
                <RadioGroup
                  value={disposition}
                  onChange={handleChangeDisposition}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value='otto'
                    disabled={disposition === 'jira'}
                    sx={{ color: disposition === 'otto' ? 'white' : '#8a8a8a' }}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          color: '#8a8a8a',
                          '&.MuiRadio-root.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label='Otto'
                  />
                  <FormControlLabel
                    value='jira'
                    sx={{ color: disposition === 'jira' ? 'white' : '#8a8a8a' }}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          color: '#8a8a8a',
                          '&.MuiRadio-root.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label='Jira'
                  />
                </RadioGroup>
                <span style={{ color: 'whitesmoke' }}>{jiraIssueID}</span>
              </FormControl>
              {/* <FormControl sx={formControlSx}>
                <FormLabel sx={{ fontSize: '0.75rem' }} focused={false}>
                  Tags:
                </FormLabel>
                <MultipleSelectChip />
              </FormControl> */}
              <div className='mark-container'>
                <Typography fontSize='0.75rem' color='whitesmoke'>
                  Mark in:{' '}
                  <span className='mark-in-mark-out'>{start.toString()}</span>
                </Typography>
                <Typography fontSize='0.75rem' color='whitesmoke'>
                  Mark out:{' '}
                  <span className='mark-in-mark-out'>{end.toString()}</span>
                </Typography>
              </div>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }}
              />
            </>
          ) : null}
          <div style={{ display: 'flex' }}>
            <div className='buttons-group'>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => setEdit((prev) => !prev)}
              >
                <EditIcon
                  fontSize='small'
                  sx={{
                    color: edit
                      ? 'white'
                      : (theme) => theme.palette.secondary.main,
                  }}
                />
              </IconButton>
              <IconButton style={{ padding: 0 }} onClick={handleDelete}>
                <DeleteIcon fontSize='small' color='error' />
              </IconButton>
            </div>
            <div>
              <TextField
                placeholder='Comment...'
                multiline
                maxRows={4}
                inputProps={{ maxLength: 256 }}
                InputProps={{
                  readOnly: !edit,
                  sx: textFieldInputPropsSx,
                }}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setText(e.target.value)
                }}
                value={text}
                label=''
                variant='outlined'
                focused={edit}
              />
              <FormHelperText sx={formHelperTextSx}>
                Characters left: {Math.abs(text.length - 256)}
              </FormHelperText>
            </div>
          </div>
        </Box>
      </Popover>
    </div>
  )
}
