import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Changelog } from '../../api/changelog/changelog'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import NoData from '../NoData/NoData'

interface ChangelogProps {
  changelogs: Changelog[] | undefined
  isFetching: boolean
  isLoading: boolean
  isError: boolean
}

export function ChangeLog({
  changelogs,
  isError,
  isFetching,
  isLoading,
}: ChangelogProps) {
  if (isFetching || isLoading) {
    return <Loader text='Changelog' />
  }

  if (isError) {
    return <NoData languageCode='enUS' />
  }

  return (
    <Timeline>
      {changelogs!.map((log) => (
        <TimelineItem key={log.logData}>
          <TimelineOppositeContent color='text.secondary'>
            <Chip
              sx={{
                height: 'auto',
                color: 'white',
                backgroundColor: '#102D92',
              }}
              label={log.version}
              variant='filled'
            />
            <br />
            <Typography
              sx={{ color: 'whitesmoke' }}
              fontSize='12px'
              variant='caption'
            >
              {dayjs(+log.releaseDate * 1000).format('D MMM YYYY')}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ flex: 5, color: 'whitesmoke' }}>
            {log.logData}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
