import { useContext, useEffect, useRef, useState } from 'react'
import VideoViewportLoader from './VideoViewportLoader'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import {
  LoadingProvider,
  VideoProvider,
  useDriveTrialContext,
} from '../../details'
import { MediaSyncContext, ObjectColor } from '../../details/types'
import { RowKey } from '../../models/table'
import { selectViewportLayoutName } from '../../store/details/viewport/selectors'
import { useAppSelector } from '../../store/hooks'
import { notEmpty } from '../../tslib/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { CanvasLayers } from '../CanvasLayers/CanvasLayers'
import NoData from '../NoData/NoData'
import VideoPlayerReact from '../VideoPlayerReact/VideoPlayerReact'

interface VideoWithCanvasProps {
  videoId: number
  playbackRate: number
  playerId: string
  videoKey?: RowKey
  hilKey?: RowKey
  colors: ObjectColor[]
  viewportId: number
  url: string
  isFullscreen: boolean
  title: string
  synchronizer?: ISynchronizer
}

export function VideoWithCanvas({
  videoId,
  playerId,
  videoKey,
  hilKey,
  playbackRate,
  colors,
  viewportId,
  url,
  isFullscreen,
  title,
  synchronizer,
}: VideoWithCanvasProps) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const viewports = useAppSelector(selectViewportLayoutName())
  const { getDriveTrialById } = useDriveTrialContext()
  const drive = getDriveTrialById(videoId)
  const playerRef = useRef<HTMLVideoElement | null>(null)

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  })

  const onResize = () => {
    if (playerRef.current) {
      setCanvasSize({
        width: playerRef.current?.clientWidth,
        height: playerRef.current?.clientHeight,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    onResize()
  }, [viewports])

  const isLoading =
    Object.values(mediaSyncContext).filter(notEmpty).length === 0

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {!videoKey?.DTID ? (
        <NoData languageCode='enUS' />
      ) : (
        <>
          <LoadingProvider title={title}>
            <div
              style={{
                position: 'absolute',
                zIndex: 10,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                whiteSpace: 'nowrap',
              }}
            >
              <VideoViewportLoader />
            </div>
            <VideoProvider videoPath={url} key={videoKey?.DTID}>
              <VideoPlayerReact
                videoId={drive!.id}
                synchronizer={synchronizer}
                playbackRate={playbackRate}
                isFullscreen={isFullscreen}
                onMediaLoaded={(player) => {
                  if (playerRef.current === null && player) {
                    setCanvasSize({
                      width: player.clientWidth,
                      height: player.clientHeight,
                    })
                  }

                  playerRef.current = player
                }}
                playerId={playerId}
                viewportId={viewportId}
              />
            </VideoProvider>

            <CanvasLayers
              canvasSize={canvasSize}
              colors={colors}
              videoKey={videoKey}
              hilKey={hilKey!}
              viewportId={viewportId}
              synchronizer={synchronizer}
              playerId={playerId}
              videoId={drive!.id}
            />
          </LoadingProvider>
        </>
      )}
    </>
  )
}
