import { PropsWithChildren, useState } from 'react'
import { LoadingContext } from './types'
import { CanvasData } from '../../dataStructure/synchronizer/synchronizer'

interface LoadingProviderProps {
  title: string
}

export const LoadingProvider = ({
  children,
  title,
}: PropsWithChildren<LoadingProviderProps>) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false)
  const [isBuffering, setIsBuffering] = useState<boolean>(false)
  const [areCanvasesLoading, setAreCanvasesLoading] = useState<CanvasData>({})

  const areAnyCanvasesLoading = Object.values(areCanvasesLoading).some(
    (x) => x === true
  )

  const clearUnusedCanvases = (canvases: string[]) => {
    const filtered = Object.keys(areCanvasesLoading)
      .filter((key) => canvases.includes(key))
      .reduce((obj, key) => {
        obj[key] = areCanvasesLoading[key]
        return obj
      }, {} as CanvasData)
    setAreCanvasesLoading(filtered)
  }

  const getTextMessage = () => {
    if (!isVideoLoaded) return `Loading ${title}`
    if (isBuffering) return `Buffering ${title}`
    if (areAnyCanvasesLoading) return `Loading ${title} objects`
    return ''
  }

  const handleCanvasStatusChange = (objectType: string, value: boolean) => {
    setAreCanvasesLoading((prevState) => {
      return {
        ...prevState,
        [objectType]: value,
      }
    })
  }

  return (
    <LoadingContext.Provider
      value={{
        text: getTextMessage(),
        clearUnusedCanvases,
        isVideoLoaded,
        setIsVideoLoaded,
        setIsBuffering,
        setAreCanvasesLoading: handleCanvasStatusChange,
        showLoadingSpinner:
          !isVideoLoaded || isBuffering || areAnyCanvasesLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}
