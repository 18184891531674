import React, { useContext, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box/Box'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import { enqueueSnackbar } from 'notistack'
import Map, { MapLayerMouseEvent } from 'react-map-gl'
import GpsSource from './GpsSource'
import MarkerSource from './MarkerSource'
import PositionMarker from './PositionMarker'
import {
  DriveCoords,
  GpsMap,
  GpsMapData,
  GpsPoint,
  MapViewProps,
  StartEndMarker,
} from './types'
import {
  getNearestPoint,
  getNearestPointForDragging,
  generateHighlightCords,
  calculateBounds,
  centerMarker,
  drawStartEndMarkers,
} from './utils'
import { useMapQuery } from '../../api'
import { enUS } from '../../constants'
import { SamplerReference, useDriveTrialContext } from '../../details'
import { MediaSyncContext } from '../../details/types'
import { getViewportLocalStorageState } from '../../store/details/viewportStateUtils'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { filterIndexes, toSeconds } from '../../utils'
import NoData from '../NoData/NoData'
import './style.scss'

function MapView({
  mapRef,
  markerRef,
  synchronizer,
  viewportId,
  selectedDTID,
}: MapViewProps) {
  const coordinatesRef = useRef<HTMLSpanElement>(null)
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, getDriveTrialById, highlightMode, modeKey } =
    useDriveTrialContext()
  const [gpsCoordinates, setGpsCoordinates] = useState<GpsPoint[]>([])
  const [gpsHighCoordinates, setGpsHighCoordinates] = useState<GpsPoint[][]>([])
  const [gpsHighMapData, setGpsHighMapData] = useState<GpsMapData>({})
  const [gpsHighMap, setGpsHighMap] = useState<GpsMap>({})
  const [driveGps, setDriveGps] = useState<DriveCoords[]>([])
  const { data, isLoading, isError } = useMapQuery()
  const isDev = process.env.REACT_APP_ENV === 'development' || 'local'
  const navigationArrow = process.env.PUBLIC_URL + '/nav-marker.svg'
  const [startEndMarkers, setStartEndMarkers] = useState<StartEndMarker[][]>([])
  const [mouseDown, setMouseDown] = useState(false)
  const [dragMap, setDragMap] = useState(true)
  const [mapBounds, setMapBounds] = useState<LngLatBounds>(
    new mapboxgl.LngLatBounds([0, 0], [0, 0])
  )
  const [isPlaying, setIsPlaying] = useState(false)
  const [rotationAngle, setRotationAngle] = useState(0)
  const currentMapViewportState = getViewportLocalStorageState()

  const startMarkerPositionColor = 'rgba(0, 255, 0, 0.7)'
  const endMarkerPositionColor = 'rgba(255, 0, 0, 0.7)'
  const startEndMarkerDriveTrialBorder = '1px solid blue'
  const gpsPathActiveColor = {
    'line-color': '#4285F4',
    'line-width': 4,
  }
  const gpsPathColor = {
    'line-color': '#874EFE',
    'line-width': 2,
    'line-opacity': 0.9,
  }

  const gpsPathHighColor = {
    'line-color': '#FF0000',
    'line-width': 4,
  }

  useEffect(() => {
    if (isPlaying) {
      centerMarker({ mapRef, markerRef })
    }
  }, [isPlaying])

  useEffect(() => {
    const map = mapRef.current
    const img = new Image(20, 20)

    map?.on('styleimagemissing', (e) => {
      if (e.id === 'navigationArrow') {
        map.addImage('navigationArrow', img)
      }
    })

    if (!map || map.hasImage('navigationArrow')) return

    img.onload = () => map.addImage('navigationArrow', img)
    img.src = navigationArrow

    if (!data?.map) return

    const cords = data.map.map(
      ({ longitude, latitude, speed, yaw }) =>
        [longitude, latitude, speed, yaw] as GpsPoint
    )
    if (gpsCoordinates.length > 0) {
      const gp = markerRef.current?.getPosition()
      gp && mapRef.current?.setCenter([gp[0], gp[1]])
      const bounds = calculateBounds(cords)
      setMapBounds(bounds)
      mapRef?.current?.getMap().fitBounds(bounds, { padding: 20 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsCoordinates])

  useEffect(() => {
    if (data?.map) {
      const cords = data.map.map(
        ({ longitude, latitude, speed, yaw }) =>
          [longitude, latitude, speed, yaw] as GpsPoint
      )

      if (highlightMode.id !== -1) {
        const highCords = highlightMode.items.map(
          ({ originalStart, originalEnd, start, dtid }) => ({
            originalStart: Math.floor(originalStart! / 1000),
            originalEnd: Math.floor(originalEnd! / 1000),
            start: Math.floor(toSeconds(start as number)),
            dtid: dtid!,
          })
        )
        const highCordsResult = generateHighlightCords(cords, highCords)
        setGpsHighCoordinates(highCordsResult.highlightCords)
        setGpsHighMap(highCordsResult.highMap)
        setGpsHighMapData(highCordsResult.highMapData)
      } else {
        setGpsHighCoordinates([])
        setGpsHighMap({})
      }

      const coordsPerDrive = data.map.reduce((acc, item) => {
        const existingDriveData = acc.find((x) => x.key === item.dtid)
        if (existingDriveData) {
          existingDriveData.coordinates?.push([
            item.longitude,
            item.latitude,
            item.speed,
            item.yaw,
          ])
        } else {
          acc.push({
            key: item.dtid,
            coordinates: [
              [item.longitude, item.latitude, item.speed, item.yaw],
            ],
            speeds: [item.speed],
            yaws: [item.yaw],
          })
        }
        return acc
      }, [] as DriveCoords[])

      setGpsCoordinates(cords)
      setDriveGps(coordsPerDrive)
      setStartEndMarkers(drawStartEndMarkers(coordsPerDrive))
    }
    // run on viewport change and on initial fetch, synchronizer is also
    // needed to prevent bloking video playing on `viewportId` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.map, viewportId, synchronizer, highlightMode])

  useEffect(() => {
    // if there is no GPS data or if we got error from request
    // we shouldn't be blocking video player and other options

    // commented out && !gpsCoordinates.length because it has data so does not pass this
    // condition, and playing is stuck because line 95 in DetailViewport.tsx
    // fires after map is set to true, returnes it to false indefinetlly

    if (isError && !isLoading /*  && !gpsCoordinates.length */) {
      synchronizer?.updateStatus(viewportId, true)
      return
    }

    synchronizer?.updateStatus(viewportId, true)

    let samplerEvent: SamplerReference | null = null

    if (mediaSyncContext.sampler !== undefined) {
      // @ts-expect-error Missing correct INTERFACE
      samplerEvent = mediaSyncContext.sampler.on('change', (time: number) => {
        if (time !== undefined) {
          setIsPlaying(mediaSyncContext.isPlaying)
          renderDevCoordinates(time)
          handlePosition(time)
          setRotationAngle(calculateRotationAngle())
        }
      })
    }

    return () => {
      samplerEvent?.terminate()
    }
  }, [
    mediaSyncContext,
    driveGps,
    isError,
    isLoading,
    synchronizer,
    viewportId,
    isDev,
    getDriveTrialById,
  ])

  const renderDevCoordinates = (time: number) => {
    if (!coordinatesRef.current || !isDev) return

    if (highlightMode.id !== -1) {
      const cords = gpsHighMap[Math.floor(time)]
      if (cords && markerRef.current && mapRef.current) {
        coordinatesRef.current.innerHTML = `${cords[1]}, ${cords[0]}`
      }
      return
    }

    const activeVideoDTID = mediaSyncContext.activeVideo
    const activeGps = driveGps.find((x) => x.key === activeVideoDTID)
    const activeVideoID = mediaSyncContext.activeVideoId
    const activeVideo = getDriveTrialById(activeVideoID!)

    if (activeGps && activeVideo) {
      const offset = activeVideo.previousDuration
      const seconds = Math.floor(time) - offset
      const [long, lat] = activeGps.coordinates[seconds]
      coordinatesRef.current.innerHTML = `${lat}, ${long}`
    }
  }

  const getCurrentCoordinate = () => {
    if (!mediaSyncContext) {
      return [0, 0, 0, 0] as GpsPoint
    }

    const t = Math.floor(mediaSyncContext.time!.query().position as number)

    if (highlightMode.id !== -1) {
      const cords = gpsHighMap[Math.floor(t)]
      if (cords && markerRef.current && mapRef.current) {
        return [cords[0], cords[1], cords[2], cords[3]] as GpsPoint
      }
      return [0, 0, 0, 0] as GpsPoint
    }

    const lastElement =
      gpsCoordinates.length > 0
        ? gpsCoordinates[gpsCoordinates.length - 1]
        : [0, 0, 0, 0]

    const currentGPSCoordinate = gpsCoordinates[t]
    const curLongitude = currentGPSCoordinate
      ? currentGPSCoordinate[0]
      : lastElement[0]
    const curLatitude = currentGPSCoordinate
      ? currentGPSCoordinate[1]
      : lastElement[1]
    const curSpeed = currentGPSCoordinate
      ? currentGPSCoordinate[2]
      : lastElement[2]
    const curYaw = currentGPSCoordinate
      ? currentGPSCoordinate[3]
      : lastElement[3]

    return [curLongitude, curLatitude, curSpeed, curYaw] as GpsPoint
  }

  const onClick = (e: MapLayerMouseEvent) => {
    const [nearestLongitude, nearestLatitude] = getNearestPoint(
      e,
      gpsCoordinates,
      Object.values(gpsHighMap)
    )
    if (nearestLatitude === null && nearestLongitude === null) return

    let gpsTime = 0
    if (highlightMode.id === -1) {
      const indexes = filterIndexes(
        gpsCoordinates,
        (point) => point[0] === nearestLongitude && point[1] === nearestLatitude
      )
      if (indexes.length > 0) {
        gpsTime = getPosition(indexes)
      }
    } else {
      const indexes = filterIndexes(
        Object.entries(gpsHighMap),
        (x) => x[1][0] === nearestLongitude && x[1][1] === nearestLatitude
      )
      if (indexes.length > 0) {
        gpsTime = getHighPosition(indexes)
      }
    }

    mediaSyncContext.time?.update({
      position: +gpsTime,
      velocity: 0,
    })
  }

  const getPosition = (indexes: number[]) => {
    if (indexes.length === 1) return indexes[0]
    const gpsData = indexes.map((x) => ({ data: data?.map[x], index: x }))
    const selectedDT = gpsData.find((x) => x.data?.dtid === selectedDTID)
    return !selectedDT ? indexes[0] : selectedDT?.index
  }

  const getHighPosition = (indexes: number[]) => {
    if (indexes.length === 1) return indexes[0]
    const gpsData = indexes.map((x) => ({ data: gpsHighMapData[x], index: x }))
    const selectedDT = gpsData.find((x) => x.data?.dtid === selectedDTID)
    return !selectedDT ? indexes[0] : selectedDT?.index
  }

  const handlePosition = (time: number) => {
    if (highlightMode.id !== -1) {
      const cords = gpsHighMap[Math.floor(time)]
      if (cords && markerRef.current && mapRef.current) {
        markerRef.current.setPosition(cords)

        if (!mapRef.current.getBounds().contains([cords[0], cords[1]])) {
          mapRef.current.setCenter([cords[0], cords[1]])
        }
      }
      return
    }

    if (!gpsCoordinates.length) return

    const roundPosition = Math.floor(time)
    const [longitude, latitude, speed, yaw] = gpsCoordinates[roundPosition]

    if (!mapRef.current || !longitude || !latitude) return

    const activeDrive = getCurrentDriveTrial(time)
    const positionOffset = Math.floor(roundPosition - activeDrive!.startTime)

    const driveHasData = driveGps.find((x) => x.key === activeDrive?.key?.DTID)
      ?.coordinates[positionOffset]

    if (driveHasData && markerRef.current) {
      markerRef.current.setPosition([longitude, latitude, speed, yaw])
      if (!mapRef.current.getBounds().contains([longitude, latitude])) {
        mapRef.current.setCenter([longitude, latitude])
      }
    }
  }

  if (isLoading) {
    return <Loader text='Loading map' center />
  }

  if (isError) {
    return <NoData languageCode='enUS' />
  }

  const handleMouseOver = (e: MapLayerMouseEvent) => {
    const threshold = 10
    const markerPosition = markerRef?.current?.getPosition()
    const map = mapRef?.current?.getMap()

    if (!markerPosition || !map) return

    const markerPixel = map.project([markerPosition[0], markerPosition[1]])
    const mousePixel = [e.point.x, e.point.y]

    const distance = Math.sqrt(
      Math.pow(markerPixel.x - mousePixel[0], 2) +
        Math.pow(markerPixel.y - mousePixel[1], 2)
    )

    return distance < threshold
  }

  const handleMouseOverRoute = (e: MapLayerMouseEvent) => {
    if (!gpsCoordinates.length) return
    const [lat, lng] = getNearestPoint(
      e,
      gpsCoordinates,
      Object.values(gpsHighMap)
    )
    const elementHtml = document.querySelector(
      '.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-touch-drag-pan.mapboxgl-touch-zoom-rotate'
    ) as HTMLElement

    if (lat === null && lng === null && elementHtml) {
      elementHtml.style.cursor = 'grab'
      return
    }
    if (elementHtml) elementHtml.style.cursor = 'all-scroll'
  }

  const handleMouseDown = (e: MapLayerMouseEvent) => {
    const isMouseOver = handleMouseOver(e) as boolean
    setDragMap(!isMouseOver)
    setMouseDown(isMouseOver)
  }

  const handleMouseUp = () => {
    setDragMap(true)
    setMouseDown(false)
  }

  const handleDrag = (e: MapLayerMouseEvent) => {
    if (dragMap || !mouseDown) return
    const [nearestLongitude, nearestLatitude] = getNearestPointForDragging(
      e,
      gpsCoordinates,
      Object.values(gpsHighMap)
    )
    if (nearestLatitude === null && nearestLongitude === null) return

    let gpsTime = 0
    if (highlightMode.id === -1) {
      gpsTime = gpsCoordinates.findIndex(
        (point) => point[0] === nearestLongitude && point[1] === nearestLatitude
      )
    } else {
      const key = Object.entries(gpsHighMap).find(
        (x) => x[1][0] === nearestLongitude && x[1][1] === nearestLatitude
      )

      if (key) gpsTime = key[0] as unknown as number
    }

    mediaSyncContext.time?.update({
      position: +gpsTime,
      velocity: 0,
    })
  }

  function calculateRotationAngle() {
    const currentPosition = getCurrentCoordinate()
    const radians = currentPosition[3]
    return radians * (180 / Math.PI)
  }

  const handleMapInteractions = (event: {
    originalEvent: any
    type: string
  }) => {
    if (!event || !event.originalEvent || !isPlaying) return

    const originalEvent = event.originalEvent

    if (
      (event.type === 'zoomstart' && originalEvent instanceof WheelEvent) ||
      (event.type === 'dragstart' && originalEvent instanceof MouseEvent) ||
      (event.type === 'rotatestart' && originalEvent instanceof MouseEvent)
    ) {
      setIsPlaying(false)
      mediaSyncContext.isPlaying = false
      mediaSyncContext.time?.update({
        velocity: 0,
      })
    }
  }

  const copyCoordinatesToClipboard = () => {
    if (coordinatesRef.current?.innerText) {
      navigator.clipboard
        .writeText(coordinatesRef.current?.innerText)
        .then(() => {
          coordinatesRef.current!.classList.add('copied')
          setTimeout(() => {
            if (coordinatesRef.current) {
              coordinatesRef.current.classList.remove('copied')
            }
          }, 1000)
          enqueueSnackbar({
            message: enUS.COORDINATES_COPIED_TO_CLIPBOARD,
            variant: 'info',
          })
        })
    }
  }

  return (
    <Box
      component='div'
      sx={{
        '& .mapboxgl-control-container': {
          display: 'none',
        },
        '& div[mapboxgl-children]': {
          display: 'none',
        },
        '& .mapboxgl-ctrl-attrib-button': {
          display: 'none',
        },
        position: 'relative',
        width: '100%',
      }}
    >
      {isDev && (
        <span
          className='lat-lng-text'
          ref={coordinatesRef}
          onClick={copyCoordinatesToClipboard}
        />
      )}
      <Map
        key={modeKey}
        ref={mapRef}
        dragPan={dragMap}
        pitchWithRotate={false}
        onDragStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onRotateStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onZoomStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onClick={onClick}
        initialViewState={{
          longitude: (mapBounds._ne.lng + mapBounds._sw.lng) / 2,
          latitude: (mapBounds._ne.lat + mapBounds._sw.lat) / 2,
          pitch: 0,
        }}
        style={{
          position: 'absolute',
          width: '100%',
        }}
        keyboard={false}
        mapStyle={
          currentMapViewportState &&
          currentMapViewportState[viewportId].mapStyle === 'street'
            ? 'mapbox://styles/mapbox/streets-v11'
            : 'mapbox://styles/mapbox/satellite-v9'
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onMouseMove={(e) => {
          handleMouseOver(e)
          handleMouseOverRoute(e)
          if (!dragMap && mouseDown) {
            handleDrag(e)
          }
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {driveGps.map((drive) => (
          <React.Fragment key={drive.key}>
            <GpsSource
              key={drive.key}
              driveKey={drive.key}
              paint={
                drive.key !== mediaSyncContext.activeVideo
                  ? gpsPathColor
                  : gpsPathActiveColor
              }
              route={drive.coordinates}
            />
          </React.Fragment>
        ))}
        {gpsHighCoordinates.map((g, index) => (
          <GpsSource
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            driveKey={index}
            paint={gpsPathHighColor}
            route={g}
          />
        ))}
        {startEndMarkers.map((markers) =>
          markers.map((marker, index) => (
            <PositionMarker
              key={marker.key}
              longitude={marker.lng}
              latitude={marker.lat}
              color={
                marker.position.split('-')[1] === 'end'
                  ? endMarkerPositionColor
                  : startMarkerPositionColor
              }
              anchor='bottom'
              title={
                marker.position.split('-')[1] === 'end'
                  ? enUS.END_DRIVE_TRAIL_ID
                  : enUS.START_DRIVE_TRAIL_ID
              }
              DTID={marker.key}
              offsetY={-25 * (index + 1)}
              activeBorder={
                mediaSyncContext.activeVideo === marker.key
                  ? startEndMarkerDriveTrialBorder
                  : ''
              }
            />
          ))
        )}
        <MarkerSource
          icon={'navigationArrow'}
          rotateAngle={rotationAngle}
          initPosition={getCurrentCoordinate()}
          ref={markerRef}
        />
      </Map>
    </Box>
  )
}

export default MapView
