import BarChartIcon from '@mui/icons-material/BarChart'
import NumbersIcon from '@mui/icons-material/Numbers'
import PushPinIcon from '@mui/icons-material/PushPin'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MuiMenu,
} from '@mui/material'
import { Header } from '@tanstack/react-table'
import { NestedMenuItem } from 'mui-nested-menu'
import { useParams } from 'react-router-dom'
import { TableRouteParams } from '../../../constants'
import { createHeaderId } from '../utils'

interface MenuProps {
  anchorEl: HTMLElement | null
  open: boolean
  handleClose: () => void
  handleDisplayMode: (
    type: 'bars' | 'line' | 'value' | 'value&bars' | 'value&line'
  ) => void
  header: Header<any, unknown>
}

export function Menu({
  anchorEl,
  open,
  handleClose,
  header,
  handleDisplayMode,
}: MenuProps) {
  const { reportType } = useParams<TableRouteParams>()
  const headerId = createHeaderId(header)
  const isSparkline =
    !['lanes', 'signs', 'lights', 'objects'].includes(reportType!) &&
    header.depth === 2 &&
    header.column.parent?.columnDef.header !== 'Drive Data' &&
    header.column.parent?.columnDef.header !== 'Tags' &&
    header.column.parent?.columnDef.header !== 'Signal' &&
    header.column.columnDef.header !== 'Country'

  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {!header.isPlaceholder &&
        header.column.getCanPin() && [
          header.column.getIsPinned() === 'left' ? (
            <MenuItem
              data-testid={`unpin-${headerId}`}
              key={3}
              onClick={() => {
                header.column.pin(false)
                handleClose()
              }}
            >
              {' '}
              <ListItemIcon>
                <PushPinIcon sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText>Unpin</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              data-testid={`pinLeft-${headerId}`}
              key={1}
              onClick={() => {
                header.column.pin('left')
                handleClose()
              }}
            >
              <ListItemIcon>
                <PushPinIcon
                  sx={{ transform: 'rotate(90deg)', color: '#fff' }}
                />
              </ListItemIcon>
              <ListItemText>Pin to left</ListItemText>
            </MenuItem>
          ),
          header.column.getIsPinned() === 'right' ? (
            <MenuItem
              data-testid={`unpin-${headerId}`}
              key={3}
              onClick={() => {
                header.column.pin(false)
                handleClose()
              }}
            >
              {' '}
              <ListItemIcon>
                <PushPinIcon sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText>Unpin</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              data-testid={`pinRight-${headerId}`}
              key={2}
              onClick={() => {
                header.column.pin('right')
                handleClose()
              }}
              disabled={header.column.getIsPinned() === 'right'}
            >
              {' '}
              <ListItemIcon>
                <PushPinIcon
                  sx={{ transform: 'rotate(270deg)', color: '#fff' }}
                />
              </ListItemIcon>
              <ListItemText>Pin to right</ListItemText>
            </MenuItem>
          ),
        ]}
      {isSparkline && [
        <Divider key={1} />,
        <MenuItem
          data-testid={`value-${headerId}`}
          key={2}
          onClick={() => handleDisplayMode('value')}
        >
          {' '}
          <ListItemIcon>
            <NumbersIcon sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText>Value</ListItemText>
        </MenuItem>,
        <NestedMenuItem
          key={3}
          leftIcon={<ShowChartIcon />}
          label='Sparkline'
          parentMenuOpen={open}
          sx={{ paddingLeft: '16px', '& p': { paddingLeft: '12px' } }}
        >
          <MenuItem
            data-testid={`lines-${headerId}`}
            onClick={() => handleDisplayMode('line')}
          >
            {' '}
            <ListItemIcon>
              <ShowChartIcon sx={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText>Lines</ListItemText>
          </MenuItem>
          <MenuItem
            data-testid={`bars-${headerId}`}
            onClick={() => handleDisplayMode('bars')}
          >
            {' '}
            <ListItemIcon>
              <BarChartIcon sx={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText>Bars</ListItemText>
          </MenuItem>
        </NestedMenuItem>,
        <NestedMenuItem
          key={4}
          leftIcon={<ShowChartIcon />}
          label='Value | Sparkline'
          parentMenuOpen={open}
          sx={{ paddingLeft: '16px', '& p': { paddingLeft: '12px' } }}
        >
          <MenuItem
            data-testid={`lines-value-${headerId}`}
            onClick={() => handleDisplayMode('value&line')}
          >
            {' '}
            <ListItemIcon>
              <ShowChartIcon sx={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText>Lines</ListItemText>
          </MenuItem>
          <MenuItem
            data-testid={`bars-value-${headerId}`}
            onClick={() => handleDisplayMode('value&bars')}
          >
            {' '}
            <ListItemIcon>
              <BarChartIcon sx={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText>Bars</ListItemText>
          </MenuItem>
        </NestedMenuItem>,
      ]}
    </MuiMenu>
  )
}
