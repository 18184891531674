import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import './styles.scss'
import { useDashboardsQuery } from '../../api/dashboards/dashboards'
import { setDashboards } from '../../storage/dashboardStorage'

const DRIVE_TRIALS_MENU_ITEMS = ['completeness', 'quality', 'characterization']
const KPIS_MENU_ITEMS = ['lanes', 'signs', 'lights']

function ReportNavigation() {
  const { data } = useDashboardsQuery()
  const dashboardMenuItems = data?.map((x) => x.name)

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [driveAnchorEl, setDriveAnchorEl] = useState<HTMLElement>()
  const [kpiAnchorEl, setKpiAnchorEl] = useState<HTMLElement>()
  const [dashboardAnchorEl, setDashboardAnchorEl] = useState<HTMLElement>()

  const menus = [
    {
      name: 'Drive Trials',
      menuItems: DRIVE_TRIALS_MENU_ITEMS,
      routeBase: '/reporting/drive-trials/',
      anchorEl: driveAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setDriveAnchorEl(event.currentTarget),
      close: () => setDriveAnchorEl(undefined),
    },
    {
      name: 'KPIs',
      menuItems: KPIS_MENU_ITEMS,
      routeBase: '/reporting/kpis/',
      anchorEl: kpiAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setKpiAnchorEl(event.currentTarget),
      close: () => setKpiAnchorEl(undefined),
    },
    {
      name: 'Dashboards',
      menuItems: dashboardMenuItems,
      routeBase: '/reporting/dashboards/',
      anchorEl: dashboardAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setDashboardAnchorEl(event.currentTarget),
      close: () => setDashboardAnchorEl(undefined),
    },
  ]

  return (
    <div style={{ display: 'flex', gap: '1.2em', height: '100%' }}>
      {menus.map((menu) => (
        <div key={menu.name} style={{ position: 'relative' }}>
          <Button
            data-testid={
              menu.name + (pathname.includes(menu.routeBase) ? '-active' : '')
            }
            sx={{
              minWidth: 0,
              textTransform: 'capitalize',
              p: 0,
              color: menu.anchorEl
                ? '#fff'
                : pathname.includes(menu.routeBase)
                ? '#e5e5e5'
                : '#bbbbbb',
              '&:hover': {
                color: '#fff',
              },
            }}
            onClick={menu.handleClick}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            {menu.name}
            <ExpandMoreIcon fontSize='small' />
          </Button>
          <Menu
            anchorEl={menu.anchorEl}
            open={Boolean(menu.anchorEl)}
            onClose={menu.close}
          >
            {menu.menuItems?.map((item) => (
              <MenuItem
                data-testid={`${menu.name}-${item}`}
                sx={{ textTransform: 'capitalize' }}
                key={item}
                onClick={() => {
                  menu.close()
                  navigate(`${menu.routeBase}${item}`)

                  if (menu.name === 'Dashboards') {
                    const chosenDashboard = data?.find((x) => x.name === item)
                    setDashboards(chosenDashboard!)
                  }
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Menu>
          {pathname.includes(menu.routeBase) && (
            <div className='highlight-active-route' />
          )}
        </div>
      ))}
    </div>
  )
}

export default ReportNavigation
