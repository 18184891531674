import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { SignDataResponse } from '../components/TopDown3D_V1_Copy/types'

const PLY_JSON_FILE = 'tags_100_signs.json'

export const usePlyJsonQuery = () =>
  useQuery({
    queryKey: ['plyJson'],
    staleTime: Infinity,
    queryFn: (): Promise<SignDataResponse> =>
      axios
        .get(urls.files.replace('url', btoa(PLY_JSON_FILE)), {
          headers: { 'Return-Type': 'application/json' },
        })
        .then(({ data }) => data),
  })
