import { useContext, useEffect, useRef, useState } from 'react'
import { Point } from 'ol/geom'
import { TOP_VIEW_MAP } from './initMap'
import { handleMarkerPosition, showJSON } from './utils'
import { useTopViewMapQuery } from '../../api/topViewMap'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { SamplerReference, useDriveTrialContext } from '../../details'
import { MediaSyncContext } from '../../details/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import NoData from '../NoData/NoData'

interface ViewerMapProps {
  synchronizer?: ISynchronizer
  viewportId: number
}

export const TopViewMap = ({ viewportId, synchronizer }: ViewerMapProps) => {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, highlightMode } = useDriveTrialContext()
  const { activeVideo, sampler } = mediaSyncContext
  const [currentDTID, setCurrentDTID] = useState(activeVideo)
  const { data, isLoading, isFetching, isError } = useTopViewMapQuery(
    currentDTID!
  )
  const mapElement = useRef<HTMLDivElement>(null)
  const [coordinates20Hz, setCoordinates20Hz] = useState<number[][]>([])

  useEffect(() => {
    let samplerEvent: SamplerReference | null = null

    if (sampler !== undefined) {
      // @ts-expect-error Missing correct INTERFACE
      samplerEvent = sampler.on('change', (time: number) => {
        const activeDTID = getCurrentDriveTrial(time)

        if (coordinates20Hz.length === 0 || !activeDTID) {
          return
        }

        synchronizer?.updateStatus(viewportId, true)

        if (mediaSyncContext.activeVideo) {
          setCurrentDTID(mediaSyncContext.activeVideo)
        }

        // percent is current time of DTID between 0 and 1
        const percent =
          (time - activeDTID.previousDuration) / activeDTID.duration
        const index = Math.round(coordinates20Hz.length * percent)
        const pointThing = new Point(coordinates20Hz[index])

        handleMarkerPosition(pointThing)
      })
    }

    return () => {
      samplerEvent?.terminate()
    }
  }, [mediaSyncContext, coordinates20Hz])

  useEffect(() => {
    if (data) {
      const coords20Hz = showJSON(data)
      setCoordinates20Hz(coords20Hz!)

      const initialMap = TOP_VIEW_MAP
      initialMap.setTarget(mapElement.current || '')
    }
  }, [data, synchronizer, highlightMode.id])

  if (isLoading || isFetching) {
    return <Loader text='Loading top view map' center />
  }

  if (isError || highlightMode.id !== -1) {
    synchronizer?.updateStatus(viewportId, true)

    return <NoData languageCode='enUS' />
  }

  return (
    <div
      ref={mapElement}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    />
  )
}
