import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  RefObject,
  useRef,
} from 'react'
import { Column, ColumnPinningState } from '@tanstack/react-table'
import { useHeadersQuery } from '../../../api/headers'
import {
  HeaderTooltips,
  useHeaderTooltips,
} from '../../../api/reportHeaderTooltips'
import { SendToDetailsData } from '../../../details/types'
import { SparklineVariant } from '../../../ui_toolkit/Sparkline/Sparkline'
import { INIT_BREADCRUMBS, INIT_PERSERVE_STATE } from '../initialStates'
import {
  BreadcrumbItem,
  ReportDepthRules,
  PerserveState,
  HandleQueryBuilder,
  TableHeaders,
  SelectedRow,
} from '../types'

export const ReportDepths: ReportDepthRules = {
  completeness: 0,
  quality: 2,
  characterization: 2,
  lanes: 0,
  signs: 0,
  lights: 0,
}

export interface ReportContextProps {
  sendToDetailsData: SendToDetailsData | undefined
  queryBuilderRef: RefObject<HandleQueryBuilder | undefined>
  preserveState: PerserveState
  allColumns: Column<any, any>[]
  columnPinning: ColumnPinningState
  searchColumns: string[]
  selectedRowIds: Set<SelectedRow>
  page: number
  limit: number
  applyKpiFilters: boolean
  displayModeColumns: {
    [key: string]: SparklineVariant
  }
  breadcrumbs: BreadcrumbItem[]
  isLoadingHeaders: boolean
  headers: TableHeaders | undefined
  setBreadcrumbs: Dispatch<SetStateAction<BreadcrumbItem[]>>
  handleBreadcrumbClick: (index: number) => void
  setDisplayModeColumns: Dispatch<
    SetStateAction<{
      [key: string]: SparklineVariant
    }>
  >
  hilVersion: string
  setPreserveState: Dispatch<SetStateAction<PerserveState>>
  setAllColumns: Dispatch<SetStateAction<Column<any, any>[]>>
  setColumnPinning: Dispatch<SetStateAction<ColumnPinningState>>
  setSearchColumns: Dispatch<SetStateAction<string[]>>
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  setApplyKpiFilters: Dispatch<SetStateAction<boolean>>
  setSelectedRowIds: Dispatch<SetStateAction<Set<SelectedRow>>>
  setHilVersion: Dispatch<SetStateAction<string>>
  headerTooltips: HeaderTooltips | undefined
  setSendToDetailsData: Dispatch<SetStateAction<SendToDetailsData | undefined>>
}

const ReportContext = createContext<ReportContextProps>(
  {} as ReportContextProps
)

export const useReportContext = () => useContext(ReportContext)

export function TableContextProvider({ children }: PropsWithChildren) {
  const [sendToDetailsData, setSendToDetailsData] =
    useState<SendToDetailsData>()
  const [preserveState, setPreserveState] =
    useState<PerserveState>(INIT_PERSERVE_STATE)
  const [allColumns, setAllColumns] = useState<Column<any, any>[]>([])
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['select', '100000', '100001', '100002', '100003'],
    right: [],
  })
  const [selectedRowIds, setSelectedRowIds] = useState<Set<SelectedRow>>(
    new Set()
  )
  const [searchColumns, setSearchColumns] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [applyKpiFilters, setApplyKpiFilters] = useState(false)
  const [displayModeColumns, setDisplayModeColumns] = useState<{
    [key: string]: SparklineVariant
  }>({})
  const [breadcrumbs, setBreadcrumbs] =
    useState<BreadcrumbItem[]>(INIT_BREADCRUMBS)
  const queryBuilderRef = useRef<HandleQueryBuilder>()
  const [hilVersion, setHilVersion] = useState(
    localStorage.getItem('hil') || 'all'
  )

  const { data: headers, isLoading: isLoadingHeaders } = useHeadersQuery()
  const { data: headerTooltips } = useHeaderTooltips()

  const handleBreadcrumbClick = (index: number) => {
    const crumbs = breadcrumbs.slice(0, index + 1)
    setBreadcrumbs(crumbs)
    setPage(1)
  }

  return (
    <ReportContext.Provider
      value={{
        sendToDetailsData,
        selectedRowIds,
        queryBuilderRef,
        preserveState,
        allColumns,
        columnPinning,
        searchColumns,
        page,
        limit,
        applyKpiFilters,
        displayModeColumns,
        breadcrumbs,
        headers,
        isLoadingHeaders,
        handleBreadcrumbClick,
        setBreadcrumbs,
        setDisplayModeColumns,
        setPreserveState,
        setAllColumns,
        setColumnPinning,
        setSearchColumns,
        setPage,
        setLimit,
        setApplyKpiFilters,
        setSelectedRowIds,
        hilVersion,
        setHilVersion,
        headerTooltips,
        setSendToDetailsData,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}
