import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from '../urls'

export interface Dashboard {
  id: number
  name: string
  url: string
}

export const useDashboardsQuery = () => {
  return useQuery({
    queryKey: [],
    staleTime: Infinity,
    queryFn: (): Promise<Dashboard[]> =>
      axios.get<Dashboard[]>(urls.dashboards).then(({ data }) => data),
  })
}
