import {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Replay10Icon from '@mui/icons-material/Replay10'
import { Button, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import Platform from 'platform'
import { useParams } from 'react-router-dom'
import { PlaybackRateMenu } from '../../../components/Menus/PlaybackRateMenu/PlaybackRateMenu'
import { JUMP_FRAME_TIME_OFFSET } from '../../../components/TransportControls/utils'
import { timelineTagId } from '../../../components/VideoTimeline/TimelineTags/common'
import { TimelineItemData } from '../../../components/VideoTimeline/types'
import { animation } from '../../../components/VideoTimeline/utils'
import { TableRouteParams } from '../../../constants'
import { useDriveTrialContext, useTimelineContext } from '../../../details'
import { getFrameRate } from '../../../details/config'
import {
  MediaSyncContext,
  PlayControls as PlayControlsRef,
} from '../../../details/types'
import { getProjectType } from '../../../storage/projectIdStorage'
import { notEmpty } from '../../../tslib/types'
import { toMilliseconds, toSeconds, useDebounce } from '../../../utils'
import { StyledTooltip } from '../../StyledTooltip/StyledTooltip'

interface IProps {
  onPlayPause: (playing: boolean) => void
  onChangePlaybackRate: (velocity: number) => void
  frameInputOnFocus: boolean
}

const iconSX = {
  color: '#f4f4f4',
}

const buttonSX = {
  minWidth: '48px',
}

export const PlayControls = forwardRef(function PlayControls(
  { onPlayPause, onChangePlaybackRate, frameInputOnFocus }: IProps,
  ref: Ref<PlayControlsRef>
) {
  const timelineCtx = useTimelineContext()
  const mediaSyncCtx = useContext(MediaSyncContext)
  const { highlightMode } = useDriveTrialContext()
  const [isPlaying, setIsPlaying] = useState(false)
  const [tagSeekMode, setTagSeekMode] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { project } = useParams<TableRouteParams>()
  const projectType = getProjectType()
  const frameRate = getFrameRate(projectType)

  const debounceChangeTimingPosition = useDebounce((num: number) => {
    const vals = mediaSyncCtx.time?.query()
    if (vals) {
      mediaSyncCtx.time?.update({
        position:
          vals.position +
          num +
          (vals.position === 0 ? JUMP_FRAME_TIME_OFFSET : 0),
        velocity: 0,
      })
    }
  }, 200)

  useImperativeHandle(ref, () => ({ setPlaying: setIsPlaying, isPlaying }), [
    isPlaying,
  ])

  const seekToTag = useCallback(
    (greaterThanCurrentTime: boolean) => {
      if (!mediaSyncCtx.time) {
        return
      }

      const currentTime = mediaSyncCtx.time.query().position
      let tags = timelineCtx.items
        .map((x) => {
          if ((x.id as string).includes(timelineTagId)) {
            return x
          }

          return undefined
        })
        .filter(notEmpty)
        .sort((a, b) => +a.start - +b.start)

      let tagToSeek: TimelineItemData | undefined = undefined
      const compareTime = Math.round(toMilliseconds(currentTime))
      if (greaterThanCurrentTime) {
        tagToSeek = tags.find((x) => +x.start > compareTime)
      } else {
        tags = tags.reverse()
        tagToSeek = tags.find((x) => +x.start < compareTime)
      }

      if (tagToSeek) {
        const position = toSeconds(+tagToSeek.start)
        mediaSyncCtx.time.update({
          position,
          velocity: 0,
        })
        setTimeout(() => {
          if (tagToSeek) {
            timelineCtx.timeline?.moveTo(
              dayjs(+tagToSeek.start).toDate(),
              animation
            )
          }
        }, 0)
      } else {
        enqueueSnackbar({
          message: 'No next tag to seek to',
          variant: 'info',
        })
      }
    },
    [
      enqueueSnackbar,
      mediaSyncCtx.time,
      timelineCtx.items,
      timelineCtx.timeline,
    ]
  )

  useEffect(() => {
    const onShiftKey = (e: KeyboardEvent) => {
      if (e.key !== 'Shift' || highlightMode.id !== -1) {
        return
      }

      setTagSeekMode(!tagSeekMode)
    }

    const keyPressHandlers = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        const modalClass = 'MuiDialog-root dialog-container MuiModal-root'
        if (document.getElementsByClassName(modalClass).length === 0) {
          e.preventDefault()
          setIsPlaying(!mediaSyncCtx.isPlaying)
          onPlayPause(!mediaSyncCtx.isPlaying)
        }
      }

      if (tagSeekMode) {
        if (e.key === 'ArrowLeft' && !frameInputOnFocus) {
          seekToTag(false)
        } else if (e.key === 'ArrowRight' && !frameInputOnFocus) {
          seekToTag(true)
        }
      } else {
        if (e.key === 'ArrowLeft' && !frameInputOnFocus) {
          e.preventDefault()
          if (e.ctrlKey) debounceChangeTimingPosition(-10)
          else debounceChangeTimingPosition(-1 / frameRate)
        } else if (e.key === 'ArrowRight' && !frameInputOnFocus) {
          e.preventDefault()
          debounceChangeTimingPosition(1 / frameRate)
        }
      }
    }

    const combineKeyDown = (e: KeyboardEvent) => {
      onShiftKey(e)
      keyPressHandlers(e)
    }

    window.addEventListener('keyup', combineKeyDown)

    return () => {
      window.removeEventListener('keyup', combineKeyDown)
    }
    // Jumping by tag or by frame
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    seekToTag,
    project,
    tagSeekMode,
    enqueueSnackbar,
    mediaSyncCtx.time,
    timelineCtx.items,
    debounceChangeTimingPosition,
  ])

  const leftArrow = (
    <StyledTooltip
      title={tagSeekMode ? 'Previous tag' : '-1 frame (left arrow)'}
      placement='top'
    >
      <Button
        data-testid={tagSeekMode ? 'previousTag' : 'previousFrame'}
        sx={buttonSX}
        onClick={() =>
          tagSeekMode
            ? seekToTag(false)
            : debounceChangeTimingPosition(-1 / frameRate)
        }
      >
        <FiberManualRecordIcon
          fontSize='small'
          style={{
            color: tagSeekMode ? 'white' : 'transparent',
            width: '10px',
            marginRight: '-8px',
          }}
        />
        <ChevronLeftIcon sx={iconSX} fontSize='large' />
      </Button>
    </StyledTooltip>
  )

  const rightArrow = (
    <StyledTooltip
      title={tagSeekMode ? 'Next tag' : '+1 frame (right arrow)'}
      placement='top'
    >
      <Button
        data-testid={tagSeekMode ? 'nextTag' : 'nextFrame'}
        sx={buttonSX}
        onClick={() =>
          tagSeekMode
            ? seekToTag(true)
            : debounceChangeTimingPosition(1 / frameRate)
        }
      >
        <ChevronRightIcon sx={iconSX} fontSize='large' />
        <FiberManualRecordIcon
          fontSize='small'
          style={{
            color: tagSeekMode ? 'white' : 'transparent',
            width: '10px',
            marginLeft: '-8px',
          }}
        />
      </Button>
    </StyledTooltip>
  )

  return (
    <Stack direction='row'>
      <StyledTooltip
        title={
          Platform.os?.family === 'OS X'
            ? '-10s (command+<left arrow>)'
            : '-10s (ctrl+<left arrow>)'
        }
        placement='top'
      >
        <Button
          data-testid='10sBack'
          sx={buttonSX}
          onClick={() => debounceChangeTimingPosition(-10)}
        >
          <Replay10Icon sx={iconSX} fontSize='large' />
        </Button>
      </StyledTooltip>

      {leftArrow}

      <StyledTooltip
        title={`${isPlaying ? 'Pause' : 'Play'} (space)`}
        placement='top'
      >
        <Button
          data-testid={isPlaying ? 'pauseVideo' : 'playVideo'}
          sx={buttonSX}
          onClick={() => {
            onPlayPause(!mediaSyncCtx.isPlaying)
            setIsPlaying(!mediaSyncCtx.isPlaying)
          }}
        >
          {isPlaying ? (
            <PauseIcon sx={iconSX} fontSize='large' />
          ) : (
            <PlayArrowIcon sx={iconSX} fontSize='large' />
          )}
        </Button>
      </StyledTooltip>

      {rightArrow}

      <PlaybackRateMenu onChange={onChangePlaybackRate} />
    </Stack>
  )
})
