import { useContext, useEffect, useRef, useState } from 'react'
import { IdType } from 'otto-vis-timeline'
import { createHighlightGainStyle } from './colors'
import { addNotationToGroup, addTagToGroup } from './TimelineTags/handlers'
import { TimelineItemData, VideoTimelineFetcherProps } from './types'
import {
  addInitialGroup,
  calculateStartEndTime,
  createBackground,
  createExclusionBackground,
  createTimelineError,
  useUpdateLoadedStatus,
} from './utils'
import {
  INITIAL_GROUPS,
  getNameFromTagType,
  useEditTagMutation,
  useHighlightsQueries,
  useTagsQueries,
} from '../../api'
import { useExclusionsQuery } from '../../api/exclusions'
import { useDriveTrialContext, useTimelineContext } from '../../details'
import { getFrameRate } from '../../details/config'
import { MediaSyncContext } from '../../details/types'
import { getProjectType } from '../../storage/projectIdStorage'
import { notEmpty } from '../../tslib/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { TimedData } from '../Canvas/CanvasBuffer'

function VideoTimelineDataFetcher({
  synchronizer,
  viewportId,
  signsData,
  onDataReceived,
}: VideoTimelineFetcherProps) {
  const { totalDuration } = useContext(MediaSyncContext)
  const {
    getDriveTrialByKey,
    getDriveTrialByHilKey,
    driveTrials,
    highlightMode,
    setHighlightMode,
  } = useDriveTrialContext()
  const timelineContext = useTimelineContext()
  const { editTagMutation } = useEditTagMutation()
  const updateLoadedStatus = useUpdateLoadedStatus(viewportId, synchronizer)
  const groupItemsFetched = useRef<Record<string, boolean>>({})
  const projectType = getProjectType()
  const frameRate = getFrameRate(projectType)
  const highlights = useHighlightsQueries()
  const { data: exclusions } = useExclusionsQuery()
  const tags = useTagsQueries()
  const [dataReceived, setDataReceived] = useState<boolean>(false)

  useEffect(() => {
    if (highlights && exclusions && tags) {
      setDataReceived(true)
      onDataReceived()
    }
  }, [highlights, exclusions, tags])

  useEffect(() => {
    // this will make logic backward compatible in dev environment
    // because of the strict mode which will fire this effect twice
    if (!timelineContext.groups.length) {
      INITIAL_GROUPS.forEach(({ kpi, items }, index) => {
        items.forEach((item) => {
          addInitialGroup(index, item, timelineContext)
        })

        if (kpi) {
          addInitialGroup(index, kpi, timelineContext, {
            nestedGroups: items.map((_val, index) => index),
          })
        }

        // here we add one fake timeline item in each group
        // to prevent nested groups from losing height if they have no items.
        const timelinePlaceholders: TimelineItemData[] = []

        driveTrials?.length &&
          createBackground(timelinePlaceholders, driveTrials)

        timelineContext.groups.forEach((g) => {
          timelinePlaceholders.push({
            isItem: false,
            start: 0,
            end: 100,
            content: '',
            id: g.id + 'placeholder',
            selectable: false,
            group: g.id,
            style: 'height: 24px; visibility: hidden; pointer-events: none;',
            type: 'range',
          })

          timelineContext.items.update(timelinePlaceholders)
          timelineContext.initialItems.update(timelinePlaceholders)
        })
      })
    }
    // on first render set initial `timelineContext` state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timelineItems: TimelineItemData[] = []

    highlights.forEach(
      ({ data, isFetched, isError, highlightType, DTID, statusCode }) => {
        const loaderKey = `${highlightType}-loader`

        if (
          !groupItemsFetched.current[`${DTID}-${highlightType}`] &&
          isFetched &&
          data?.length
        ) {
          data
            .sort(
              (a, b) =>
                b.timelineEndTime -
                b.timelineStartTime -
                (a.timelineEndTime - a.timelineStartTime)
            )
            ?.forEach((highlight, index) => {
              const timeData = calculateStartEndTime(
                driveTrials,
                projectType === 'episodic' ? highlight.dsid : DTID,
                highlight
              )

              const groupId = timelineContext.groups
                .map((x) =>
                  x.value.toLowerCase().includes(highlightType.toLowerCase())
                    ? x.id
                    : undefined
                )
                .filter(notEmpty)[0]

              const title = `(${highlight.timelineStartTime} - ${highlight.timelineEndTime}): ${highlight.comment}`

              const startInSeconds = new Date(timeData.start).getTime() / 1000
              const endInSeconds = new Date(timeData.end).getTime() / 1000

              timelineItems.push({
                ...timeData,
                isItem: true,
                content: '',
                id: `${highlight.hlid}_${DTID}`,
                selectable: true,
                group: groupId,
                title: title,
                type: 'range',
                style:
                  createHighlightGainStyle(highlight.gain) +
                  `z-index: ${index}`,
                gain: highlight.gain,
                dtid: DTID,
                className: highlightType.includes('SPR')
                  ? 'vertical-line'
                  : `duration-${Math.floor(endInSeconds - startInSeconds)}`,
              })
            })

          groupItemsFetched.current[`${DTID}-${highlightType}`] = true
          timelineContext.items.update(timelineItems)
          timelineContext.initialItems.update(timelineItems)
        }

        if (isFetched && !isError && data?.length === 0) {
          groupItemsFetched.current[`${DTID}-${highlightType}`] = true
        }

        if (isFetched && isError && statusCode !== 404) {
          const errorItem = createTimelineError(
            loaderKey,
            totalDuration,
            timelineContext.items.get(loaderKey)?.group
          )
          timelineContext.items.update([errorItem])
        }
      }
    )

    updateLoadedStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlights])

  // add tags to the group
  useEffect(() => {
    const tagsData: Record<IdType, TimedData<boolean>> = {}
    tags.forEach(({ tags, dtid }) =>
      tags?.forEach((tag) => {
        const groupID = timelineContext.groups
          .map((group) => group)
          .find((grp) => grp.content === tag.description)!.id

        if (!tag.endTimestamp) {
          addTagToGroup(
            tag.startTimestamp,
            groupID,
            timelineContext,
            highlightMode,
            setHighlightMode,
            tag,
            dtid,
            getDriveTrialByHilKey(dtid),
            signsData
          )
        } else {
          addNotationToGroup(
            tag.startTimestamp,
            tag.endTimestamp,
            groupID,
            timelineContext,
            highlightMode,
            tag,
            dtid,
            getDriveTrialByKey(dtid),
            editTagMutation
          )
        }
        const id = getNameFromTagType(groupID)

        const timelineRowId = timelineContext.groups
          .get({ returnType: 'Array' })
          .findIndex((x) => (x.content as string).includes(id!))

        if (!tagsData[timelineRowId]) tagsData[timelineRowId] = {}
        tagsData[timelineRowId][tag.startTimestamp] = true
      })
    )

    timelineContext.setTags(tagsData)
    // tags should be added to context only on fetching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, signsData])

  useEffect(() => {
    if (!exclusions) return
    const timelinePlaceholders: TimelineItemData[] = []
    const groups = ['Adjacent Right', 'Adjacent Left', 'Ego Right', 'Ego Left']
    const exclusionGroups = timelineContext.groups
      .get({ filter: (x) => groups.includes(x.content as string) })
      .map((x) => x.id as number)

    driveTrials?.length &&
      createExclusionBackground(
        timelinePlaceholders,
        driveTrials,
        exclusions,
        frameRate,
        exclusionGroups
      )

    timelineContext.items.update(timelinePlaceholders)
    timelineContext.initialItems.update(timelinePlaceholders)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusions, driveTrials])

  return !dataReceived ? (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loader text='Receiving data...' scale={1.5} />
    </div>
  ) : null
}

export default VideoTimelineDataFetcher
