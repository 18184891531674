import { useEffect, useState } from 'react'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import FilterListIcon from '@mui/icons-material/FilterList'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import { Button, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { useKpiFiltersQuery } from '../../../api'
import {
  enUS_SIDEBAR_TOOLBOX,
  ReportTypes,
  TableRouteParams,
} from '../../../constants'
import { ProjectName } from '../../../models/project'
import {
  INIT_KPI_LANES_COLUMN_VISIBILITY,
  INIT_KPI_LIGHTS_COLUMN_VISIBILITY,
  INIT_KPI_SIGNS_COLUMN_VISIBILITY,
} from '../../../pages/Report/components/initColumnVisibility'
import { useReportContext } from '../../../pages/Report/providers/ReportContextProvider'
import { DrawerMenu } from '../../../ui_toolkit/DrawerMenu/DrawerMenu'
import Toolbar from '../../../ui_toolkit/Toolbar/Toolbar'
import HideShowPicker from '../../HideShowPicker/HideShowPicker'
import KpiFilters from '../../KpiFilters/KpiFilters'
import QueryBuilderSelector from '../../QueryBuilderSelector/QueryBuilderSelector'
import ReportsSelector from '../../ReportsSelector/ReportsSelector'
import { SideFilters } from '../../SideFilters/SideFilters'
import {
  ToolboxProps,
  IconDefinition,
  TabsRoutes,
  iconStyles,
  ToolbarItem,
  iconStylesDisabled,
} from '../types'
import { prepareKpiFilters } from '../utils'
import '../style.scss'

const isDevelopment =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'local'

const availableTabs: TabsRoutes = {
  'Data Completeness': ReportTypes.COMPLETENESS,
  'Data Quality': ReportTypes.QUALITY,
  Characterization: ReportTypes.CHARACTERIZATION,
}

const tabRouteMap: TabsRoutes = {
  [ReportTypes.COMPLETENESS]: 'Data Completeness',
  [ReportTypes.QUALITY]: 'Data Quality',
  [ReportTypes.CHARACTERIZATION]: 'Characterization',
}

if (isDevelopment) {
  availableTabs.Characterization = ReportTypes.CHARACTERIZATION
  availableTabs['Pipeline Status'] = ReportTypes.PIPELINE_STATUS
  tabRouteMap[ReportTypes.CHARACTERIZATION] = 'Characterizations'
}

export const SidebarToolbox = ({
  anchor = 'left',
  variant = 'temporary',
  onDrawerToggle,
}: ToolboxProps) => {
  const { pathname } = useLocation()
  const { reportType } = useParams<TableRouteParams>()
  const { preserveState, setPreserveState } = useReportContext()
  const [openedTool, setOpenedTool] = useState<ToolbarItem>('')
  const isKPI = pathname.includes('kpis')
  const isDashboard = pathname.includes('dashboards')
  const { data = [] } = useKpiFiltersQuery(isKPI)

  const initKpiState = match(reportType)
    .with('lanes', () => INIT_KPI_LANES_COLUMN_VISIBILITY)
    .with('signs', () => INIT_KPI_SIGNS_COLUMN_VISIBILITY)
    .with('lights', () => INIT_KPI_LIGHTS_COLUMN_VISIBILITY)
    .otherwise(() => {})

  useEffect(() => {
    const kpis = ['lanes', 'signs', 'lights']
    if (openedTool === 'Advanced Search' && kpis.includes(reportType!)) {
      setOpenedTool('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  const toggleDrawer = (tool: ToolbarItem) => {
    setOpenedTool(openedTool === tool ? '' : tool)
  }

  const clearAll = () => {
    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        columnVisibility: isKPI ? initKpiState : {},
      },
    })
  }

  useEffect(() => {
    onDrawerToggle(Boolean(openedTool))
  }, [openedTool, onDrawerToggle])

  const toolbarContent: IconDefinition = [
    {
      icon: (
        <AccountTreeIcon
          fontSize='medium'
          sx={isDashboard ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Columns'),
      opened: openedTool === 'Columns',
      title: enUS_SIDEBAR_TOOLBOX.columns,
      disabled: isDashboard,
    },
    {
      icon: (
        <FilterListIcon
          fontSize='medium'
          sx={isDashboard ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Search'),
      opened: openedTool === 'Search',
      title: enUS_SIDEBAR_TOOLBOX.search,
      disabled: isDashboard,
    },
    {
      icon: (
        <FlashOnIcon
          fontSize='medium'
          sx={isKPI || isDashboard ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Advanced Search'),
      opened: openedTool === 'Advanced Search',
      title: enUS_SIDEBAR_TOOLBOX.advancedSearch,
      disabled: isKPI || isDashboard,
    },
  ]

  return (
    <>
      <Toolbar side={anchor} content={toolbarContent} />
      <DrawerMenu anchor={anchor} variant={variant} open={Boolean(openedTool)}>
        <div className='drawer-content'>
          <Typography
            variant='h6'
            display='flex'
            alignItems='center'
            justifyContent={'space-between'}
            sx={{
              color: '#e5e5e5',
              padding: '24px 0 24px 8px',
              userSelect: 'none',
            }}
          >
            {openedTool.toUpperCase()}
            {openedTool === 'Columns' && (
              <Button variant='contained' onClick={clearAll}>
                Clear
              </Button>
            )}
          </Typography>
          {match(openedTool)
            .with('Reports', () => <ReportsSelector />)
            .with('Columns', () => <HideShowPicker reportType={reportType!} />)
            .with('Search', () =>
              isKPI ? (
                <KpiFilters
                  project={capitalize(reportType) as ProjectName}
                  filterData={prepareKpiFilters(data, reportType || '')}
                  reportType={reportType!}
                />
              ) : (
                <SideFilters reportType={reportType!} />
              )
            )
            .with('Advanced Search', () => (
              <QueryBuilderSelector reportType={reportType!} />
            ))
            .otherwise(() => null)}
        </div>
      </DrawerMenu>
    </>
  )
}
