import dayjs from 'dayjs'
import { ResponseError } from '../details/types'
import { FilterObject } from '../models/table'

export const dateToYYYYMMDD = (date: dayjs.Dayjs | undefined | null) => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DD')
}

export const stringToDayJs = (val: string | null) => {
  if (!val) return val
  return dayjs(val, 'YYYY-MM-DD')
}

export const toSeconds = (time: number) => time / 1000
export const toMilliseconds = (time: number) => time * 1000
export const toTimeString = (time: number) =>
  dayjs(toMilliseconds(time)).utc().format('HH:mm:ss')
export const toDateTimeString = (time: number) =>
  dayjs(toMilliseconds(time)).utc().format('YYYY-MM-DD HH:mm:ss')

export const truncateNumber = (number: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.floor(number * factor) / factor
}

export const toTitleCase = (str: string) =>
  str?.length > 0 ? `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}` : str

export const removeTrailingSlash = (str: string) =>
  str.endsWith('/') ? str.slice(0, -1) : str

export const isValidUrl = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export const toggleClasses = (
  element: HTMLElement,
  toRemove: string[],
  toAdd: string[]
) => {
  toRemove.forEach((className) => element.classList.remove(className))
  toAdd.forEach((className) => element.classList.add(className))
}

export const transformFilter = (filter: FilterObject) => {
  const newObject: FilterObject = {}

  const toCamelCase = (str: string) =>
    str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase())

  Object.keys(filter).forEach((key) => {
    if (['KPI', 'DTID'].includes(key.toLocaleUpperCase())) {
      newObject[key.toLowerCase()] = filter[key]
    } else if (key.split(' ').length === 1) {
      newObject[key.charAt(0).toLowerCase() + key.slice(1)] = filter[key]
    } else {
      newObject[toCamelCase(key)] = filter[key]
    }
  })

  return newObject
}

export const getResponseStatusCode = (error: Error | null) => {
  if (error) {
    const responseError = error as ResponseError
    return responseError?.response?.status || 200
  }
  return 200
}

export const unixTimestampToDate = (unixTimestamp: number): Date => {
  return dayjs.unix(unixTimestamp).toDate()
}

export const formatCustomTime = (date: Date): string =>
  `Time: ${dayjs.utc(date).format('dddd, MMMM D, YYYY [at] HH:mm:ss')}`

export function filterIndexes<T>(
  array: T[],
  conditionFn: (element: T, index: number, array: T[]) => boolean
): number[] {
  const indexes: number[] = []

  array.forEach((element, index) => {
    if (conditionFn(element, index, array)) {
      indexes.push(index)
    }
  })

  return indexes
}
export const applyStyle = () => {
  const items = document.querySelectorAll('[class*="highlight"]')

  for (let i = 0; i < items.length; i++) {
    const classNames = items[i].className.split(/\s+/)
    let widthValue = '0%'
    classNames.forEach((className) => {
      const match = className.match(/(?:^|\s)highlight-(\d+(?:\.\d+)?)(?=\s|$)/)
      if (match) {
        widthValue = `${match[1]}%`
      }
    })

    const targetDiv = Array.from(
      items[i].querySelectorAll('div.vis-item-content')
    ).find((innerDiv) => innerDiv)

    if (targetDiv && targetDiv instanceof HTMLElement) {
      targetDiv.style.display = 'flex'
      targetDiv.style.justifyContent = 'center'
      const childDivs = targetDiv.querySelectorAll('div')
      childDivs.forEach(function (div) {
        div.style.width = widthValue
        div.style.height = '100%'
        if (widthValue !== '100%') {
          div.style.boxShadow = '0px 0px 1px 3000px rgba(0,0,0,0.3)'
        }
      })
    }
  }
}
