import { getProjectType } from '../storage/projectIdStorage'

export const urls = {
  files: '/api/s3/files/<url>',
  reportsSummaryClause: '/api/reports_summary',
  reportsSummaryFiltered: '/api/reports_summary_filtered',
  reportsClause: '/api/reports_clause',
  reportsFiltered: '/api/reports',
  driveTrialHighlights: '/api/drive_trials/:id/highlights',
  kpiFilters: '/api/kpi_filters',
  kpiSummaryLanes: '/api/kpi_summary_lanes',
  kpiSummaryLights: '/api/kpi_summary_lights',
  kpiSummarySigns: '/api/kpi_summary_signs',
  kpiSummaryLightsHighligts: '/api/kpi_summary_lights_highlights',
  kpiSummaryLanesHighlights: '/api/kpi_summary_lanes_highlights',
  kpiSummarySignsHighlights: '/api/kpi_summary_signs_highlights',
  roadLanes: '/api/road_lanes',
  trafficSigns: '/api/traffic_signs',
  objects: '/api/objects_stream',
  trafficLights: '/api/layers/traffic_lights',
  userAudit: '/api/user_audit',
  mapRoutes: '/api/drive_trial_path',
  driveTrials: '/api/drive_trial',
  driveTrialEgo: '/api/drive_trials/:id/drive_trial_ego',
  driveSegment: '/api/drive_segment',
  driveTrialSegmentIds: '/api/drive_segment_ids',
  driveTrialSegments: '/api/drive_segment_load',
  reportLog: '/api/report_log',
  reportLogExit: '/api/report_log_exit',
  reportLogFilter: '/api/report_log_filter',
  signIn: '/sign_in',
  refreshToken: '/refresh_tokens',
  getTimelineTags: '/api/drive_trials/:id/tags',
  deleteTimelineTags: '/api/drive_trials/tags/:id',
  clientViewportMenuOptions: '/api/viewports',
  dashboards: '/api/dashboards',
  redirectionData: '/api/redirection_data',
  reportHeaders: '/api/report_headers',
  hilVersions: '/drive_trials/versions',
  changelog: '/api/changelog',
  exclusions: '/api/exclusions',
  reportHeaderTooltips: '/api/report_headers_tooltips',
  topViewS3: '/api/s3/top_view_map/<url>',
  jira: '/api/jira',
  jiraSubscribe: '/api/subscribe/:issueID',
}

export const createVideoPath = (dtid: number, videoName: string) => {
  const projectType = getProjectType()
  const prefix = projectType === 'episodic' ? 'valeo/' : ''
  return `${prefix}${dtid}/${videoName}`
}
