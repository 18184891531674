import { useState } from 'react'
import { Box, Divider, SelectChangeEvent } from '@mui/material'
import Popover, { PopoverOrigin } from '@mui/material/Popover'
import { Column, Row } from '@tanstack/react-table'
import { CellInput } from 'jspdf-autotable'
import { useParams } from 'react-router-dom'
import { pdfCreator } from './pdfCreator'
import { PaperFormat, PrintLayout, paperFormatValues } from './types'
import { getPaperFormat } from './utils'
import { TableRouteParams, enUS } from '../../constants'
import { Select } from '../../ui_toolkit/Inputs/Select/Select'
import { StyledApplyBtn } from '../StyledComponents/buttons'

interface PDFPopoverInterface {
  id: string | undefined
  anchorEl: HTMLButtonElement | null
  open: boolean
  onClose: () => void
  anchorOrigin: PopoverOrigin
  columnNumber: CellInput
  visibleColumns: Column<any, any>[]
  bodyRows: Row<any>[]
}

const printLayouts = [
  {
    value: 'portrait',
    text: 'Portrait',
  },
  {
    value: 'landscape',
    text: 'Landscape',
  },
]

const PdfPopover = ({
  id,
  anchorEl,
  open,
  onClose,
  anchorOrigin,
  columnNumber,
  bodyRows,
  visibleColumns,
}: PDFPopoverInterface) => {
  const { reportType } = useParams<TableRouteParams>()
  let suggestedFormat = 'a0'
  if (columnNumber) {
    suggestedFormat = getPaperFormat(+columnNumber) || 'a0'
  }
  const label = `${enUS.SELECT_FORMAT}`
  const [format, setFormat] = useState<PaperFormat>(
    suggestedFormat as PaperFormat
  )
  const [landscape, setLandscape] = useState<PrintLayout>('portrait')
  const paperFormats = paperFormatValues.map((format) => ({
    value: format,
    text: format === suggestedFormat ? `${format} *default` : format,
  }))

  return (
    <div className='MuiDialog-root dialog-container MuiModal-root'>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
      >
        <Box
          component='form'
          sx={{
            padding: 1,
            display: 'flex',
            backgroundColor: (theme) => theme.palette.primary.main,
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete='off'
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <Select
              options={paperFormats}
              title={label}
              onChange={(e: SelectChangeEvent) => {
                setFormat(e.target.value as PaperFormat)
              }}
              value={format}
              variant='filled'
            />
          </div>
        </Box>
        <Divider sx={{ backgroundColor: 'grey' }} />
        <Box
          component='form'
          sx={{
            padding: 1,
            display: 'flex',
            backgroundColor: (theme) => theme.palette.primary.main,
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete='off'
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <Select
              options={printLayouts}
              title={enUS.SELECT_LAYOUT}
              onChange={(e: SelectChangeEvent) => {
                setLandscape(e.target.value as PrintLayout)
              }}
              value={landscape}
              variant='filled'
            />
          </div>
        </Box>

        <Divider sx={{ backgroundColor: 'grey' }} />
        <Box
          component='div'
          sx={{
            padding: 1,
            display: 'flex',
            backgroundColor: (theme) => theme.palette.primary.main,
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            width: '100%',
            alignItems: 'stretch',
          }}
        >
          <StyledApplyBtn
            onClick={() =>
              pdfCreator(
                visibleColumns,
                bodyRows,
                format,
                landscape,
                reportType!
              )
            }
            variant='contained'
            fullWidth
          >
            {enUS.SAVE_PDF}
          </StyledApplyBtn>
        </Box>
      </Popover>
    </div>
  )
}

export default PdfPopover
