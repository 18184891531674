import { InputLabel, SelectChangeEvent, Stack } from '@mui/material'
import { Dayjs } from 'dayjs'
import { date, datePickerFormat } from '../../../constants'
import MultiSelect from '../../../ui_toolkit/Inputs/MultiSelect/MultiSelect'
import { ReactDateRange } from '../../../ui_toolkit/Inputs/ReactDateRange/ReactDateRange'
import { Select } from '../../../ui_toolkit/Inputs/Select/Select'
import { DateRangeData } from '../../../ui_toolkit/Inputs/types'
import { dateToYYYYMMDD, stringToDayJs } from '../../../utils'
import { KpiFilter, KpiSummaryState } from '../types'

interface IProps {
  filterData: KpiFilter[]
  filtersState: KpiSummaryState
  onChange: (fieldName: string, value: string | number | string[]) => void
  onDateChange?: (value: null[] | string[]) => void
  isGeneralFilter?: boolean
}

const KpiFiltersBlock = ({
  filterData,
  onChange,
  onDateChange,
  isGeneralFilter: showDateSelector = false,
  filtersState,
}: IProps) => {
  const { filters, dateRange } = filtersState

  const handleFilterChange = (
    fieldName: string,
    value: string | number | string[]
  ) => {
    onChange(fieldName, value)
  }

  const formatDates = () =>
    dateRange?.map((x) => stringToDayJs(x) ?? null) as DateRangeData<Dayjs>

  const handleChangeDateRange = (val: DateRangeData<Dayjs>) => {
    const newVal = val.map((x) =>
      dateToYYYYMMDD(x) === 'Invalid Date' ? null : dateToYYYYMMDD(x)
    ) as null[] | string[]

    onDateChange && onDateChange(newVal)
  }

  const renderFilter = (filter: KpiFilter) => {
    if (filter.multiSelect) {
      return (
        <MultiSelect
          key={`filter-${filter.name}`}
          title={filter.name}
          options={filter.values}
          handleSelectionChange={(selected: string[]) =>
            handleFilterChange(filter.name, selected)
          }
          value={filters[filter.name] as string[]}
        />
      )
    }
    return (
      <Select
        key={`filter-${filter.name}`}
        variant='filled'
        title={filter.name}
        label={filter.name}
        styledFirstChild={true}
        value={filters[filter.name] as string | number}
        onChange={(e: SelectChangeEvent) =>
          handleFilterChange(filter.name, e.target.value)
        }
        options={[{ value: '', text: 'Reset' }, ...filter.values]}
      />
    )
  }

  return (
    <Stack direction='column' spacing={4}>
      <Stack direction='column' spacing={2}>
        {filterData.map((filter) => renderFilter(filter))}

        {!showDateSelector ? null : (
          <div style={{ position: 'relative' }}>
            <InputLabel
              sx={{
                marginBottom: 1,
              }}
            >
              Date
            </InputLabel>
            <ReactDateRange
              dateFormat={datePickerFormat}
              placeholder={date}
              onChange={(val) => handleChangeDateRange(val)}
              dateRangeValues={formatDates()}
            />
          </div>
        )}
      </Stack>
    </Stack>
  )
}

export default KpiFiltersBlock
