export const textFieldInputPropsSx = {
  color: 'white',
  fontSize: '12px',
  lineHeight: '20px',
  userSelect: 'none',
  minWidth: 360,
}

export const formHelperTextSx = {
  color: 'whitesmoke',
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
}

export const formControlSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  paddingLeft: '24px',
}
